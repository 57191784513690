import React from 'react'
import { GridItem, Flex, IconButton, Box } from '@chakra-ui/react'
import { colors } from '../../../hocs/withTheme'

import { MdOutlineColorLens as ColorLens } from 'react-icons/md'

interface Props {
  cssVal: string
  tileHeader: string
  botThemeFormElements: any
  renderPicker?: any
  renderInput?: any
}

const Tile = ({
  cssVal,
  tileHeader,
  botThemeFormElements,
  renderPicker,
  renderInput,
}: Props) => {
  return (
    <GridItem>
      <Flex my={5} alignItems="flex-end">
        <IconButton
          onClick={() => renderPicker(cssVal, botThemeFormElements.css[cssVal])}
          aria-label="color"
          bg={botThemeFormElements.css[cssVal]}
          minH="88px"
          minW="88px"
          border={`1px solid ${colors.lightGreyScale1200}`}
          borderRadius={8}
          mr={4}
          _hover={{
            bg: botThemeFormElements.css[
              cssVal as keyof typeof botThemeFormElements.css
            ],
          }}
        >
          <Box p={2} bg={colors.lightGreyScale100} borderRadius="50%">
            <ColorLens size="20px" color={colors.lightGreyScale1200} />
          </Box>
        </IconButton>

        {renderInput(
          botThemeFormElements.css[
            cssVal as keyof typeof botThemeFormElements.css
          ],
          cssVal,
          tileHeader,
        )}
      </Flex>
    </GridItem>
  )
}

export default Tile
