import {
  BotModelType,
  DetachBotMenuInputTypeName,
  useQuery,
} from '@botcopy/utils-shared'

import { useCallback } from 'react'
import { useStore } from 'src/providers'

export const useDetachBotMenu = () => {
  const store = useStore()

  const {
    data: detachBotMenuData,
    setQuery: detachBotMenuSetQuery,
    query: detachBotMenuQuery,
    loading: detachBotMenuLoading,
    error: detachBotMenuError,
  } = useQuery<{
    detachBotMenu: BotModelType
  }>()

  const detachBotMenuSendData = useCallback(
    ({
      organizationId,
      botId,
      typename,
    }: {
      organizationId: string
      botId: string
      typename: DetachBotMenuInputTypeName
    }) => {
      detachBotMenuSetQuery(() =>
        store.api.mutateDetachBotMenu(
          {
            input: {
              organizationId,
              botId,
              typename,
            },
          },
          (botSelect) =>
            botSelect._id.name.botMenu(
              (botMenuSelect) => botMenuSelect._id.name,
            ),
        ),
      )
    },
    [detachBotMenuSetQuery, store.api],
  )

  return {
    detachBotMenuSendData,
    detachBotMenuData,
    detachBotMenuQuery,
    detachBotMenuSetQuery,
    detachBotMenuLoading,
    detachBotMenuError,
  }
}
