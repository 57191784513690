import { useCallback } from 'react'
import {
  BotMenuModelType,
  CreateBotMenuInput,
  CreateBotMenuInputTypeName,
  useQuery,
} from '@botcopy/utils-shared'
import { useStore } from 'src/providers'

export const useCreateBotMenu = () => {
  const store = useStore()

  const {
    data: createBotMenuData,
    setQuery: createBotMenuSetQuery,
    query: createBotMenuQuery,
    loading: createBotMenuLoading,
    error: createBotMenuError,
  } = useQuery<{
    createBotMenu: BotMenuModelType
  }>()

  const createBotMenuSendData = useCallback(
    (input: Omit<CreateBotMenuInput, 'typename'>) => {
      createBotMenuSetQuery(() =>
        store.api.mutateCreateBotMenu(
          {
            input: {
              ...input,
              typename: CreateBotMenuInputTypeName.CreateBotMenuInput,
            },
          },
          (botMenuSelect) =>
            botMenuSelect._id.name.headers((headerSelect) => headerSelect),
        ),
      )
    },
    [createBotMenuSetQuery, store.api],
  )

  return {
    createBotMenuData: createBotMenuData?.createBotMenu,
    createBotMenuQuery,
    createBotMenuSendData,
    createBotMenuLoading,
    createBotMenuError,
  }
}
