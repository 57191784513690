// hex is used for MongoDB support
const generateObjectId = (length: number, hex: boolean = false): string => {
  let result = ''
  const characters = hex
    ? '0123456789abcdef'
    : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export default generateObjectId
