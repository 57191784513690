/**
 * Validates an email address format
 * @param email - The email address to validate
 * @returns True if the email is valid or empty, false otherwise
 */
export const validateEmail = (email: string): boolean => {
  if (!email) return true
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailRegex.test(email)
}
