import { getRoot, Instance, IStateTreeNode } from 'mobx-state-tree'

import { RootStoreType } from '@botcopy/utils-shared'

import { RootStore as GlobalStore } from '../root-store/root.store'

export const withApiStore = (self: IStateTreeNode) => ({
  views: {
    get apiStore(): RootStoreType {
      return getRoot<GlobalStore>(self).api as Instance<RootStoreType>
    },
  },
})
