import * as React from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Switch,
  Text,
} from '@chakra-ui/react'
import { ConnectFormElements, tooltipText } from '.'
import { MdOutlineClear } from 'react-icons/md'
import { map } from 'lodash-es'
import { customTheme } from '@botcopy/ui-shared'
import { observer } from 'mobx-react'

export const installedOnDomainRegexp = new RegExp(
  /^(https:\/\/\S+\.\S+|http:\/\/localhost)(?::\d+)?(?!\/)$/,
)
export const installedOnExceptionURLRegexp = new RegExp(
  /^(https:\/\/\S+\.\S+|http:\/\/localhost)(?::\d+)?(\/\S*)?$/,
)
interface DomainAllowlistToggleProps {
  isDisabled: boolean
  isChecked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

type DomainAllowlistFormElements = Pick<
  ConnectFormElements,
  | 'newInstalledOn'
  | 'newInstalledOnException'
  | 'installedOn'
  | 'installedOnExceptions'
>
interface DomainAllowlistProps {
  formElements: DomainAllowlistFormElements
  setInstalledOnDomainToDeleteDialog: (value: boolean) => void
  setInstalledOnDomainToDelete: (value: string) => void
  setInstalledOnExceptionToDeleteDialog: (value: boolean) => void
  setInstalledOnExceptionToDelete: (value: string) => void
  onFormElementsChange: (
    updatedFormElements: Partial<DomainAllowlistFormElements>,
  ) => void
}

export const DomainAllowlistToggle = ({
  isDisabled,
  isChecked,
  onChange,
}: DomainAllowlistToggleProps) => {
  return (
    <HStack direction="row">
      <Text textStyle="h5" fontSize="21px" whiteSpace="nowrap">
        Domain Allowlist
      </Text>
      <FormControl>
        <HStack>
          <Switch
            isDisabled={isDisabled}
            isChecked={isChecked}
            onChange={onChange}
            ml={2}
          />
          <FormLabel>Enable Allowlist</FormLabel>
        </HStack>
      </FormControl>
    </HStack>
  )
}

export const DomainAllowlist = ({
  setInstalledOnDomainToDeleteDialog,
  setInstalledOnDomainToDelete,
  setInstalledOnExceptionToDeleteDialog,
  setInstalledOnExceptionToDelete,
  formElements,
  onFormElementsChange,
}: DomainAllowlistProps) => {
  const [installedOnError, setInstalledOnError] = React.useState(false)
  const [installedOnExceptionError, setInstalledOnExceptionError] =
    React.useState(false)

  const handleChange =
    (fieldName: 'newInstalledOn' | 'newInstalledOnException') =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value

      if (fieldName === 'newInstalledOn') {
        const isValid = installedOnDomainRegexp.test(e.target.value)
        setInstalledOnError(!isValid)
      }
      if (fieldName === 'newInstalledOnException') {
        const isValid = installedOnExceptionURLRegexp.test(e.target.value)
        setInstalledOnExceptionError(!isValid)
      }
      onFormElementsChange({ [fieldName]: value })
    }

  return (
    <Grid dir="column">
      {/* DOMAIN ALLOWLIST FIELD */}
      <GridItem>
        <Text textStyle="subtitle2" my={4}>
          Specify the trusted domains you allow your bot to appear on
        </Text>
        <FormControl mb={6}>
          <FormLabel as={Text} textStyle="overline" casing="uppercase">
            Domain
          </FormLabel>
          <Input
            value={formElements.newInstalledOn}
            onChange={handleChange('newInstalledOn')}
            placeholder="https://website.com"
            isInvalid={installedOnError}
            variant="outline"
            w="inherit"
            border="1px solid"
            color={customTheme.colors.lightGreyScale1200}
            borderColor={customTheme.colors.lightGreyScale800}
          />
          <FormHelperText mt={2}>
            {tooltipText.installedOnRestricted}
          </FormHelperText>
        </FormControl>
      </GridItem>

      {/*ALLOWED DOMAINS*/}
      <GridItem>
        <Text textStyle="overline">Allowed Domains:</Text>
        <Flex className="allowed-domain-bubble-grid">
          {map(formElements.installedOn, (installedOn: string, i: number) => {
            return (
              <Button
                key={i}
                textStyle="caption"
                className="allowed-domain-bubble"
                onClick={async () => {
                  setInstalledOnDomainToDeleteDialog(true)
                  setInstalledOnDomainToDelete(installedOn)
                }}
              >
                {installedOn}
                <MdOutlineClear />
              </Button>
            )
          })}
        </Flex>
      </GridItem>

      {/* EXCEPTIONS TO ALLOWLIST */}
      <GridItem>
        <Text textStyle="subtitle2" my={4}>
          Exceptions - Block access to specific URLs
        </Text>
        <FormControl mb={6}>
          <FormLabel as={Text} textStyle="overline" casing="uppercase">
            URL
          </FormLabel>
          <Input
            value={formElements.newInstalledOnException}
            onChange={handleChange('newInstalledOnException')}
            placeholder="https://website.com/path"
            isInvalid={installedOnExceptionError}
            variant="outline"
            w="inherit"
            border="1px solid"
            color={customTheme.colors.lightGreyScale1200}
            borderColor={customTheme.colors.lightGreyScale800}
          />
          <FormHelperText mt={2}>
            {tooltipText.installedOnExceptions}
          </FormHelperText>
        </FormControl>
      </GridItem>

      {/*URL BLOCKLIST */}
      <GridItem>
        <Text textStyle="overline">Blocked URLs:</Text>
        <Flex className="allowed-domain-bubble-grid">
          {map(
            formElements.installedOnExceptions,
            (installedOnException: string, i: number) => {
              return (
                <Button
                  key={i}
                  textStyle="caption"
                  className="allowed-domain-bubble"
                  onClick={async () => {
                    setInstalledOnExceptionToDeleteDialog(true)
                    setInstalledOnExceptionToDelete(installedOnException)
                  }}
                >
                  {installedOnException}
                  <MdOutlineClear />
                </Button>
              )
            },
          )}
        </Flex>
      </GridItem>
    </Grid>
  )
}

export default observer(DomainAllowlist)
