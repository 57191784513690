import React from 'react'
import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Flex,
  useTheme,
} from '@chakra-ui/react'

interface BotMenuNameProps {
  name?: string
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const BotMenuName: React.FC<BotMenuNameProps> = ({
  name,
  onNameChange,
}) => {
  const theme = useTheme()
  return (
    <FormControl>
      <Flex>
        <FormLabel mt={theme.space[2]}>Bot Menu Name</FormLabel>
        <FormHelperText mt={0} pt={theme.space[1]}>
          For internal use only
        </FormHelperText>
      </Flex>
      <Input placeholder="Bot Menu Name" value={name} onChange={onNameChange} />
    </FormControl>
  )
}
