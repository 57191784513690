import './step1.css'

import { get } from 'lodash-es'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { isMobile } from 'react-device-detect'
import { Link as ReactRouterLink, Navigate } from 'react-router-dom'

import { Button, Link } from '@chakra-ui/react'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Info from '@material-ui/icons/Info'

import { AppConfig } from '../../config'
import { colors } from '../../hocs/withTheme'
import { IOnboardingProps } from './'
import { withAuth0 } from '@auth0/auth0-react'

const widgetBaseURL = AppConfig.get('/WidgetHostname')

const styles = {
  errorMessage: {
    fontSize: '2.5em',
    color: '#0A2740',
    maxWidth: '250px',
    marginRight: 25,
    fontWeight: 600,
  },
  errorMessageDetails: {
    fontSize: '1.75em',
    color: '#0A2740',
  },
  expansionPanel: {
    minWidth: 300,
    width: '100%',
    marginBottom: '20px',
    borderRadius: 5,
    boxShadow: '0px 5px #5C5DA6',
  },
  expansionPanelSummary: {
    backgroundColor: colors.darkGreyBlue,
    color: colors.offWhite,
    borderRadius: 5,
  },
  subtitle: {
    fontSize: '1.25em',
    fontWeight: 400,
    cursor: 'default',
    color: colors.lightGreyBlue,
    paddingLeft: '5px',
    fontFamily: 'Roboto',
  },
  stepHeader: {
    fontWeight: 650,
    fontSize: '1.5em',
    fontType: 'Open Sans, sans-serif',
  },
  stepDetails: {
    fontSize: '1.25em',
    fontType: 'Open Sans, sans-serif',
  },
}

interface IOnboardingStep1State {
  failure: boolean
  loading: boolean
  noAgents: boolean
  maxKeys: boolean
  maxKeysDialog: boolean
  checkPermissions: boolean
  checkPermissionsDialog: boolean
  errorAgent: string
  showPlans: string
}

@inject('store')
@observer
class OnboardingStep1 extends React.Component<
  IOnboardingProps,
  IOnboardingStep1State
> {
  private static _renderChatWidget(botId: string) {
    return (
      <div
        id="botcopy-embedder-d7lcfheammjct"
        className="botcopy-embedder-d7lcfheammjct"
        data-rootcontainerid="botcopy-embedder-d7lcfheammjct"
        data-botid={botId}
        data-ignorecui="true"
      />
    )
  }
  constructor(props: IOnboardingProps) {
    super(props)
    this._onChange = this._onChange.bind(this)

    const search = get(window, 'location.search', '')
    const params = new URLSearchParams(search)
    const showPlansParam: any = params.get('showPlans')
    const showPlans = showPlansParam ? showPlansParam : ''

    this.state = {
      loading: false,
      failure: false,
      noAgents: false,
      maxKeys: false,
      maxKeysDialog: false,
      checkPermissions: false,
      checkPermissionsDialog: false,
      errorAgent: '',
      showPlans,
    }
  }

  public async componentDidUpdate(prevProps: IOnboardingProps) {
    this.props.store?.session?.setNavDrawerOpen(false)

    // add chat-widget for No Agents found to the page
    const s = document.createElement('script')
    s.className = 'bot-script'
    s.type = 'text/javascript'
    s.async = true
    s.src = `${widgetBaseURL}/js/injection.js`
    if (document.getElementById('botcopy-embedder-d7lcfheammjct')) {
      // @ts-ignore
      document.getElementById('botcopy-embedder-d7lcfheammjct').appendChild(s)
    }
  }

  public componentWillUnmount() {
    if (document.getElementById('botcopy-embedder-d7lcfheammjct')) {
      // @ts-ignore
      document.getElementById('botcopy-embedder-d7lcfheammjct').remove()
    }
    if (document.getElementById('botcopy-d7lcfheammjct')) {
      // @ts-ignore
      document.getElementById('botcopy-d7lcfheammjct').remove()
    }
  }

  public render() {
    const { loading, failure } = this.state

    const { store } = this.props

    const orgId = store?.organizations.current?._id

    if (orgId) {
      return <Navigate to="/" />
    }
    if (failure) {
      return this._renderFailure()
    } else if (loading) {
      return this._renderLoading()
    }
    return (
      <Grid
        container={true}
        className="bannerGrid"
        justify="center"
        direction="column"
        alignItems="center"
        wrap="nowrap"
      >
        {isMobile ? (
          <Paper className="banner">
            <span className="banner-title">
              We’re excited to get you started!
            </span>
            <span className="banner-subtitle">
              Desktop devices are currently required for signup. Sorry for any
              inconvenience.
            </span>
            <span className="banner-text">
              Since you're here, why not get the ball rolling? Book your
              Enterprise meeting with us to explore custom pricing.
            </span>
            <a href="https://calendly.com/botcopy/enterprise-plan-priority-meeting">
              <Button size="xl">Schedule a Meeting</Button>
            </a>
          </Paper>
        ) : (
          <>
            {/* Sign in Banner */}
            <Paper className="banner" elevation={3} square={true}>
              {/* Banner Grid Items */}
              <Grid
                className="bannerItems"
                container={true}
                justify="center"
                alignContent="center"
                direction="column"
              >
                <div className="banner-title">Create Your Free Account</div>
                <div className="banner-subtitle">
                  Set up your 7-Day Free Trial. Unlock all features.
                  <br />
                  Connect Dialogflow to a rich web chat UI in minutes!
                </div>

                <Grid container={true} justify="center">
                  <Button onClick={() => this.props.auth0.loginWithRedirect()}>
                    Sign up
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container={true}
                justify="center"
                alignItems="center"
                className="account-already"
              >
                <div>Have an account already?</div>
                <Link
                  as={ReactRouterLink}
                  to="/login"
                  type="camelCase"
                  color={colors.link}
                >
                  Login
                </Link>
              </Grid>
            </Paper>
            <div className="trusted-by-text">
              Trusted by the world's leading brands.
            </div>
            <Grid container={true} justify="center">
              <img src="/images/icons/companyLogos.png" alt="company logos" />
            </Grid>
          </>
        )}
      </Grid>
    )
  }

  private _onChange(event: any) {
    const text = event.target.value as string
    this.props.store?.session?.onboarding.setBusinessEmail(text)
  }

  private _renderLoading() {
    return (
      <div
        style={{
          alignContent: 'center',
          alignItems: 'center',
          flex: 1,
          flexWrap: 'wrap',
          justifyContent: 'center',
          margin: '80px auto',
          maxWidth: '500px',
        }}
      >
        <Grid
          container={true}
          spacing={24}
          style={{
            alignItems: 'center',
            alignContent: 'center',
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="secondary" />
          <Grid
            item={true}
            xs={12}
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: 30,
              color: '#0A2740',
            }}
          >
            Setting up your Botcopy account...
          </Grid>
        </Grid>
      </div>
    )
  }

  private _renderFailure() {
    return (
      <div>
        <Grid
          container={true}
          justify="center"
          style={{
            margin: '80px auto',
            marginBottom: 0,
            maxWidth: '950px',
          }}
        >
          <Grid
            container={true}
            direction="column"
            justify="center"
            alignItems="center"
            style={{
              padding: '20px',
            }}
          >
            <Grid container={true} justify="center" alignItems="center">
              {this.state.noAgents ? (
                <div />
              ) : this.state.maxKeys && this.state.errorAgent ? (
                <div style={styles.errorMessage}>
                  Uh oh. <br />
                  Too many keys!
                </div>
              ) : this.state.checkPermissions ? (
                <div style={styles.errorMessage}>
                  Oh no. <br />
                  No access!
                </div>
              ) : null}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '25px',
                }}
              >
                <img
                  src="/images/icons/errorbotcopy.png"
                  width={200}
                  alt="error icon"
                />
              </div>
            </Grid>

            {this.state.noAgents ? (
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '1.75em',
                  color: colors.darkGreyBlue,
                }}
              >
                No Dialogflow agents detected.
                <div>
                  Please create an agent and then resign up with Botcopy
                </div>
              </div>
            ) : this.state.maxKeys && this.state.errorAgent ? (
              <div style={{ textAlign: 'center' }}>
                <div style={styles.errorMessageDetails}>
                  Please delete some keys on the service account for{' '}
                  {this.state.errorAgent}.
                </div>
              </div>
            ) : this.state.checkPermissions ? (
              <Grid style={{ textAlign: 'center' }}>
                <div style={styles.errorMessageDetails}>
                  Please check that you have owner or developer permissions for
                  a Dialogflow agent.
                  <br />
                  If the problem persists, create a fresh agent and sign up
                  again.
                  <Info
                    style={{
                      cursor: 'pointer',
                      color: colors.lightestGreyBlue,
                      margin: '0 0 10px 5px',
                    }}
                    onClick={(e) =>
                      this.setState({ checkPermissionsDialog: true })
                    }
                  />
                </div>
              </Grid>
            ) : null}
            <br />
            {this.state.noAgents ? (
              <Button mb="20px">
                <a
                  style={{ color: colors.pureWhite }}
                  href="https://dialogflow.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Create Agent
                </a>
              </Button>
            ) : null}
          </Grid>

          {this.state.maxKeys && this.state.errorAgent ? (
            <ExpansionPanel
              style={styles.expansionPanel}
              defaultExpanded={true}
            >
              <ExpansionPanelSummary
                style={styles.expansionPanelSummary}
                expandIcon={<ExpandMoreIcon style={{ color: '#F0F3F6' }} />}
              >
                <Typography
                  style={styles.subtitle}
                  onClick={(e) => this.setState({ maxKeysDialog: true })}
                >
                  How do I fix it?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid
                  item={true}
                  xs={12}
                  style={{
                    textAlign: 'left',
                    fontWeight: 500,
                    fontSize: '1em',
                    color: colors.darkGreyBlue,
                    display: 'flex',

                    flexDirection: 'column',

                    padding: '10px',
                  }}
                >
                  <div style={{ padding: '25px' }}>
                    <div style={styles.stepHeader}> Step 1:</div>
                    <div style={styles.stepDetails}>
                      Go to your settings section within the desired agent.
                      Click the service account link to your Google Cloud.
                    </div>
                  </div>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/errorSignup/step1.png`}
                      width="100%"
                      alt="error icon"
                    />
                  </div>
                  <div style={{ padding: '25px' }}>
                    <div style={styles.stepHeader}> Step 2:</div>
                    <div style={styles.stepDetails}>
                      Click 'edit' in the stacked dots icon to the right of the
                      desired service account.
                    </div>
                  </div>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/errorSignup/step2.png`}
                      width="100%"
                      alt="error icon"
                    />
                  </div>
                  <div style={{ padding: '25px' }}>
                    <div style={styles.stepHeader}> Step 3:</div>
                    <div style={styles.stepDetails}>
                      Delete a key. (An unused key was likely generated from
                      Botcopy from your first signup)
                    </div>
                  </div>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/errorSignup/step3.png`}
                      width="100%"
                      alt="error icon"
                    />
                  </div>
                  <div style={{ padding: '25px' }}>
                    <div style={styles.stepHeader}> Step 4:</div>
                    <div style={styles.stepDetails}>
                      Come on back and sign up again.
                    </div>
                  </div>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : this.state.checkPermissions ? (
            <ExpansionPanel
              style={styles.expansionPanel}
              defaultExpanded={true}
            >
              <ExpansionPanelSummary
                style={styles.expansionPanelSummary}
                expandIcon={<ExpandMoreIcon style={{ color: '#F0F3F6' }} />}
              >
                <Typography style={styles.subtitle}>
                  What does this mean?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid
                  item={true}
                  xs={12}
                  style={{
                    textAlign: 'left',
                    fontWeight: 500,
                    fontSize: '1em',
                    color: colors.darkGreyBlue,
                    display: 'flex',

                    flexDirection: 'column',

                    padding: '10px',
                  }}
                >
                  <div style={{ padding: '20px' }}>
                    <div style={styles.stepHeader}>Add Developer Role:</div>
                    <div style={styles.stepDetails}>
                      Have the admin of your existing agents add you as a
                      developer in the share settings, or create a fresh agent
                      on your own.
                    </div>
                  </div>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/errorSignup/DeveloperAccess.png`}
                      width="100%"
                      alt="error icon"
                    />
                  </div>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : null}
          {/* </Paper> */}
        </Grid>

        {/* DIALOGS */}
        <Dialog
          open={this.state.checkPermissionsDialog}
          onClose={(e) => this._closeDialog()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <DialogTitle style={{ fontWeight: 650 }} id="alert-dialog-title">
              Dialogflow Permissions
            </DialogTitle>
          </Grid>
          <DialogContent>
            <DialogContentText
              style={{ fontWeight: 600 }}
              id="alert-dialog-description"
            >
              In order to display and link your Dialogflow agents, we use
              Google's oAuth2Client system to give us permissions to view your
              projects. We then try to create a Service Account on a project
              which you have write permissions on.
              <br />
              <br />
              This signup error most commonly occurs when the user has
              insufficient permissions on a Dialogflow agent in their console -
              for example, if the agent was shared with you and your role is a
              Reviewer.
              <br />
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => this._closeDialog()}>Ok</Button>
          </DialogActions>
        </Dialog>
        {OnboardingStep1._renderChatWidget('5d27e54740a0d3eb2db9edcd')}
      </div>
    )
  }

  private _closeDialog = () => {
    if (this.state.checkPermissionsDialog) {
      this.setState((prevState) => ({
        ...prevState,
        checkPermissionsDialog: false,
      }))
    }
  }
}

export default withAuth0(OnboardingStep1)
