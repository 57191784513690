import './connect.css'

import { each, isUndefined, map } from 'lodash-es'
import { observer } from 'mobx-react'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import {
  MdCheckCircleOutline as CheckCircle,
  MdContentCopy,
  MdDeleteOutline as DeleteIcon,
  MdInfoOutline as InfoIcon,
  MdOutlineClear as ClearIcon,
  MdOutlineEject as EjectOutlineIcon,
  MdOutlineLanguage as LanguageIcon,
  MdCopyAll as CopyAllIcon,
} from 'react-icons/md'
import BotcopyMonospace from 'src/components/BotcopyMonospace'
import trackUserEvent from 'src/components/trackEvents'
import { Roles } from 'src/models/users'
import {
  cxDataRegions,
  esDataRegions,
  IDataRegion,
} from 'src/utils/dataRegions'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid as ChakraGrid,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  SimpleGrid,
  Switch,
  Text,
  Textarea,
  Tooltip,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Snackbar,
  SnackbarContent,
  StyleRulesCallback,
  TextField,
  withStyles,
} from '@material-ui/core'

import {
  InputWithResults,
  SaveDetectorConfirmation,
  useSaveDetector,
} from '@botcopy/ui-shared'
import {
  BotMenuModelType,
  DetachBotMenuInputTypeName,
  UpdateBotInputTypeName,
} from '@botcopy/utils-shared'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useUpdateBot } from 'src/hooks/useUpdateBot'
import { useBotMenus } from 'src/hooks/useBotMenus'
import { useDetachBotMenu } from 'src/hooks/useDetachBotMenu'
import { useStore } from 'src/providers'
import { RouteProps, withRouter } from 'src/utils/withRouter'
import { fetchCXAgents, getActiveProjects, getAgents } from '../../api'
import { AppConfig } from '../../config'
import { colors } from '../../hocs/withTheme'
import {
  BotPlatform,
  IBot,
  IFetchAgentsResult,
  ILanguageObject,
} from '../../models/bots'
import { RootStore } from '../../models/root-store/root.store'
import { EventAction, EventName, Events } from '../../utils/gtm'
import { dialogflowLanguages } from '../../utils/languages'
import Logger from '../../utils/logger'
import CreditCardCapture from '../onboarding/CreditCardCapture'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { DomainAllowlist, DomainAllowlistToggle } from './DomainAllowlist'
import { BotDetails } from './BotDetails'
import { toJS } from 'mobx'

const widgetBaseURL = AppConfig.get('/WidgetHostname')

const { log, logError } = Logger('Connect')

export const FALLBACK_BOT_PROJECT_ID = 'user-name-e88b2'

const installedOnDomainRegexp = new RegExp(
  /^(https:\/\/\S+\.\S+|http:\/\/localhost)(?::\d+)?(?!\/)$/,
)
const installedOnExceptionURLRegexp = new RegExp(
  /^(https:\/\/\S+\.\S+|http:\/\/localhost)(?::\d+)?(\/\S*)?$/,
)

const divStyle = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '2em',
    color: colors.darkGreyBlue,
    padding: 20,
  },
  loading: {
    margin: '1em 0',
    color: colors.darkGreyBlue,
    textAlign: 'center' as 'center',
  },
  subtitle: {
    margin: '0.5em',
    marginBottom: '2em',
    fontWeight: 550,
    fontSize: '1.25em',
    color: colors.darkGreyBlue,
  },
  input: {
    font: 'Open Sans, sans-serif',
    width: '100%',
    color: colors.offWhite,
  },
  tooltip: {
    color: colors.darkGreyBlue,
    alignText: 'center',
    fontSize: '1em',
    fontWeight: 600,
  },
  // Snackbar
  snackbarGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  snackbar: {
    backgroundColor: colors.green,
    color: colors.offWhite,
  },
  errorSnackbar: {
    backgroundColor: colors.errorRed,
    color: colors.offWhite,
  },
}

const styles: StyleRulesCallback = (theme) => ({
  root: {
    color: colors.offWhite,
    flexGrow: 1,
    margin: '80px auto',
    padding: '50px',
  },
  card: {
    backgroundColor: colors.purple,
    display: 'inline-block',
    margin: 20,
    flex: 1,
    width: 200,
  },
  paper: {
    padding: '32px',
    backgroundColor: colors.lightGreyScale200,
    color: colors.lightGreyScale1200,
    flex: 0.5,
  },
  defaultAgentPaper: {
    padding: theme.spacing.unit * 3.5,
    color: colors.darkGreyBlue,
    margin: '40px 18px 18px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  accordion: {
    color: colors.darkGreyBlue,
    backgroundColor: colors.lightGreyScale200,
    width: '100%',
    margin: '0 18px',
  },
  accordionDetails: {
    flexDirection: 'column',
    padding: '0 30px 24px',
    borderBottom: `1px solid ${colors.lightGreyBlue}`,
  },
  title: {
    color: 'white',
  },
  stat: {
    color: 'white',
  },
  input: {
    font: 'Open Sans, sans-serif',
    width: '95%',
    color: colors.darkGreyBlue,
  },
  tabWrapper: {
    width: '100%',
  },
  // To style outlined mui text field
  focusVisible: {},
  cssFocused: {},
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: colors.darkGreyBlue,
    },
  },
  notchedOutline: {
    borderColor: colors.darkGreyBlue,
  },
})

export const tooltipText = {
  botName: `Naming your bot will change the header label in the chat window.`,
  embedSnippet: `Copy the script below. Then, paste it in the <body> of the page you want the bot to render on.`,
  dashboardLabel: `Add a label to categorize your bot on the dashboard`,
  installedOnExceptions: `Accepted format: https://domain.com/, https://subdomain.domain.com/, https://domain.com/path`,
  installedOnRestricted: `Accepted format: https://domain.com, https://subdomain.domain.com, https://legacy-api.botcopy.org:3030`,
  dialogflowESEnvironment: `Enter a custom Dialogflow ES environment name. Remove it to use the default environment.`,
  dialogflowCXEnvironment: `Enter a Dialogflow CX environment ID. Once an environment is created, copy the name to clipboard and copy/paste the ID found at the end of the url. Example: projects/.../environments/1234-567-abcd. Enter 1234-567-abcd.  Remove it to use the default environment.`,
  gtmSnippet: `Copy the script below. Then, paste it in
    Google Tag Manager.`,
  noAgentAccess: `Generally users see this error when they have deleted the Agent the bot is connected to in Dialogflow. Otherwise, you might not have been shared this Agent on Dialogflow.`,
}

interface IConnectProps extends RouteProps {
  classes: any
  store?: RootStore
}

export type ConnectFormElements = {
  botName: string
  dashboardLabel: string
  dialogflowEnvironment: string
  installedOnRestricted: boolean
  installedOn: string[]
  installedOnExceptions: string[]
  newInstalledOn: string
  newInstalledOnException: string
}

const getHasDefaultOrNoProjectId = (bot: IBot) => {
  return !bot.projectId || bot.projectId === FALLBACK_BOT_PROJECT_ID
}

export const Connect = (props: IConnectProps) => {
  const store = useStore()
  const params = useParams()

  const currentBot = store.bots.currentBot
  const initialValue: ConnectFormElements = {
    botName: currentBot?.name ?? '',
    dashboardLabel: currentBot?.dashboardLabel ?? '',
    dialogflowEnvironment: currentBot?.dialogflow.environment ?? '',
    installedOnRestricted: currentBot?.installedOnRestricted ?? false,
    installedOn: toJS(currentBot?.installedOn || []),
    installedOnExceptions: toJS(currentBot?.installedOnExceptions || []),
    newInstalledOn: '',
    newInstalledOnException: '',
  }
  const [formElements, setFormElements] = useState(initialValue)
  const hasUnsavedChanges = useSaveDetector(initialValue, formElements)

  const onFormElementsChange = (
    updatedFormElements: Partial<ConnectFormElements>,
  ) => {
    setFormElements((prev) => ({
      ...prev,
      ...updatedFormElements,
    }))
  }

  const handleSaveFormElements = async () => {
    try {
      const editedFormElementsInstalledOn = editFormElementsInstalledOn(
        formElements.newInstalledOn,
      )
      const editedFormElementsInstalledOnExceptions =
        editFormElementsInstalledOnExceptions(
          formElements.newInstalledOnException,
        )

      const updates = {
        name:
          currentBot.name !== formElements.botName
            ? formElements.botName
            : undefined,
        dashboardLabel:
          currentBot.dashboardLabel !== formElements.dashboardLabel
            ? formElements.dashboardLabel
            : undefined,
        dialogflow:
          currentBot.dialogflow.environment !==
          formElements.dialogflowEnvironment
            ? {
                environment: formElements.dialogflowEnvironment,
                location: currentBot.dialogflow.location,
              }
            : undefined,
        installedOn: editedFormElementsInstalledOn,
        installedOnExceptions: editedFormElementsInstalledOnExceptions,
        installedOnRestricted:
          currentBot.installedOnRestricted !==
          formElements.installedOnRestricted
            ? formElements.installedOnRestricted
            : undefined,
      }

      await currentBot.patchBot(updates)
      _handleSnackbar('success', 'Changes saved')
    } catch (error: any) {
      logError('Error saving form elements:', error)
      _handleSnackbar('error', `Error saving form elements ${error.message}`)
    }
  }

  const currentBotSync = store?.bots.getBotByIdSync(params.botId)
  const currentBotSyncString = JSON.stringify(currentBotSync)
  useEffect(() => {
    if (currentBotSync) {
      setFormElements({
        botName: currentBotSync?.name ?? '',
        dashboardLabel: currentBotSync?.dashboardLabel ?? '',
        dialogflowEnvironment: currentBotSync?.dialogflow.environment ?? '',
        installedOnRestricted: currentBotSync?.installedOnRestricted ?? false,
        installedOn: toJS(currentBotSync?.installedOn || []),
        installedOnExceptions: toJS(
          currentBotSync?.installedOnExceptions || [],
        ),
        newInstalledOn: '',
        newInstalledOnException: '',
      })
    }
  }, [currentBotSync, currentBotSyncString])

  const [activeProjects, setActiveProjects] = useState<any[]>([])
  const [agentAccess, setAgentAccess] = useState(false)
  const [agents, setAgents] = useState<IFetchAgentsResult[] | undefined>(
    undefined,
  )
  const [checkPermissionsDialog, setCheckPermissionsDialog] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [cloneBotDialog, setCloneBotDialog] = useState(false)
  const [cxAgents, setCxAgents] = useState([])
  const [installedOnDomainToDelete, setInstalledOnDomainToDelete] = useState('')
  const [installedOnDomainToDeleteDialog, setInstalledOnDomainToDeleteDialog] =
    useState(false)
  const [installedOnExceptionToDelete, setInstalledOnExceptionToDelete] =
    useState('')
  const [
    installedOnExceptionToDeleteDialog,
    setInstalledOnExceptionToDeleteDialog,
  ] = useState(false)
  const [deletedDFAgentDialog, setDeletedDFAgentDialog] = useState(false)
  const [disconnectBotDialog, setDisconnectBotDialog] = useState(false)
  const [installedOnRestrictedToSet, setInstalledOnRestrictedToSet] =
    useState(false)
  const [installedOnRestrictedDialog, setInstalledOnRestrictedDialog] =
    useState(false)
  const [embedSnippedTabActive, setEmbedSnippedTabActive] = useState({
    general: true,
    gtm: false,
  })
  const [errorAgent, setErrorAgent] = useState('')
  const [errorSnackbar, setErrorSnackbar] = useState(false)
  const [filteredEsAgents, setFilteredEsAgents] = useState<any[]>([])
  const [filteredCxAgents, setFilteredCxAgents] = useState<any[]>([])
  const [filteredCxProjects, setFilteredCxProjects] = useState<any[]>([])
  const [languageSelectionDialog, setLanguageSelectionDialog] = useState(false)
  const [loadingAgents, setLoadingAgents] = useState(false)
  const [loadingCXProjects, setLoadingCXProjects] = useState(false)
  const [maxKeysDialog, setMaxKeysDialog] = useState(false)
  const [noAgentsDialog, setNoAgentsDialog] = useState(false)
  const [savingAgent, setSavingAgent] = useState(false)
  const [searchValue, setSearchValue] = useState({
    searchValue: '',
    initialSearchValueSet: false,
  })
  const [botMenuSearchValue, setBotMenuSearchValue] = useState({
    searchValue: '',
    initialSearchValueSet: false,
  })
  const [filteredBotMenus, setFilteredBotMenus] = useState<any[]>([])
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(0)
  const [selectCXAgentDialog, setSelectCXAgentDialog] = useState(false)
  const [selectCXProjectDialog, setSelectCXProjectDialog] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarLink, setSnackbarLink] = useState('')
  const [snackbarLinkLabel, setSnackbarLinkLabel] = useState('')
  const [successSnackbar, setSuccessSnackbar] = useState(false)
  const currentBotId = store.bots.currentBotId
  useEffect(() => () => store.bots.setCurrentBotId(undefined), [store.bots])
  const { botMenusData, botMenusFetchData } = useBotMenus()
  const { updateBotSendData, updateBotQuery } = useUpdateBot()
  const { detachBotMenuSendData, detachBotMenuQuery, detachBotMenuLoading } =
    useDetachBotMenu()
  const toast = useToast()

  const [botMenusToShow, setBotMenusToShow] = useState<any[]>([])

  useEffect(() => {
    if (!store.users.me?.organization) return
    botMenusFetchData(store.users.me.organization)
  }, [botMenusFetchData, store.users.me?.organization])

  useEffect(() => {
    if (!store.users.me?.organization) return
    if (botMenusData && botMenusData.length > 0) {
      const botMenus: BotMenuModelType[] = botMenuSearchValue.searchValue
        ? filteredBotMenus
        : botMenusData
      const botMenuResults = botMenus.map((botMenu) => {
        return {
          heading: botMenu.name,
          onClick: () => {
            setBotMenuSearchValue((prev) => ({
              ...prev,
              searchValue: '',
            }))
            updateBotSendData({
              botId: currentBotId,
              botMenu: botMenu._id,
              organizationId: store.users.me?.organization!,
              typename: UpdateBotInputTypeName.UpdateBotInput,
            })
          },
        }
      })
      setBotMenusToShow(botMenuResults)
    }
  }, [
    botMenusData,
    botMenuSearchValue,
    filteredBotMenus,
    updateBotSendData,
    currentBotId,
    store.users.me?.organization,
  ])

  const handleDetachBotMenu = async () => {
    if (!currentBot) return

    detachBotMenuSendData({
      botId: currentBotId,
      organizationId: store.users.me?.organization ?? '',
      typename: DetachBotMenuInputTypeName.DetachBotMenuInput,
    })
  }

  useEffect(() => {
    if (detachBotMenuQuery) {
      detachBotMenuQuery.promise.then(
        (response) => {
          toast({
            title: 'Bot Menu Detached',
            description: 'The bot menu has been successfully detached.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
          store.bots.pushUnique(response.detachBotMenu)
        },
        () => {
          toast({
            title: 'Error Detaching Bot Menu',
            description: 'Something went wrong.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        },
      )
    }
  }, [detachBotMenuQuery, store, toast])
  useEffect(() => {
    if (updateBotQuery) {
      updateBotQuery.promise.then(
        (response) => {
          toast({
            title: 'Bot Updated',
            description: 'The bot has been successfully updated.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
          store.bots.pushUnique(response.updateBot)
        },
        () => {
          toast({
            title: 'Error Updating the Bot',
            description: 'Something went wrong.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        },
      )
    }
  }, [updateBotQuery, toast, store])

  const _fetchAgents = useCallback(async () => {
    // don't fetch agents for DialogflowCX bots - the dialog handles it
    if (currentBot.platform === BotPlatform.DIALOGFLOW_CX) {
      return
    }
    setLoadingAgents(true)
    const me = store?.users.me
    const projectId = currentBot.projectId || ''
    const isMarketer = me!.roles.includes(Roles.MARKETER)
    const fetchAgentsResults: IFetchAgentsResult[] = await getAgents(
      1,
      200,
      '_id:-1',
      currentBot.dialogflow.location,
    )
    log('_fetchAgents AGENTS >>>>>', fetchAgentsResults)
    if (!fetchAgentsResults.length) {
      setLoadingAgents(false)
      setNoAgentsDialog(true)
      setAgents(fetchAgentsResults)
    }

    // sort by access boolean
    fetchAgentsResults
      .sort((a, b) => Number(a.access) - Number(b.access))
      .reverse()

    let selectedResultIndex: any
    each(fetchAgentsResults, (result, index) => {
      log(
        'each agent',
        result.agent.parent.toLowerCase().replace('projects/', ''),
        projectId,
      )
      // if name of agent is equal to project id and not a marketer
      if (
        result.agent.parent
          .toLowerCase()
          .replace('projects/', '')
          .split('/')[0] === projectId &&
        !isMarketer
      ) {
        selectedResultIndex = index
        // if the project id is matched with an agent in the agent list, then give agent access
        setAgentAccess(true)
        return true
      }
      return true
    })
    log('_fetchAgents selectedIndex', selectedResultIndex)
    log('projectid>>>', projectId)

    setAgents(fetchAgentsResults)
    setSelectedIndex(selectedResultIndex)
    setLoadingAgents(false)
  }, [currentBot, store])

  useEffect(() => {
    log('useEffect', {
      agents,
      loadingAgents,
      currentBot,
    })
    if (!agents && !loadingAgents && currentBot) {
      _fetchAgents()
    }

    // Ensure the state is updated after fetching agents
    setSearchValue((prev) => {
      if (currentBot && !prev.initialSearchValueSet && agentAccess) {
        const hasDefaultOrNoProjectId = getHasDefaultOrNoProjectId(currentBot)

        const initialSearchValue = hasDefaultOrNoProjectId
          ? 'Please select an agent...'
          : currentBot.platform === BotPlatform.DIALOGFLOW_ES
            ? currentBot.agent.displayName
            : currentBot.cxAgent?.displayName

        if (prev.searchValue !== initialSearchValue) {
          return {
            searchValue: initialSearchValue,
            initialSearchValueSet: true,
          }
        }
      }
      return prev
    })
  }, [_fetchAgents, agentAccess, agents, loadingAgents, currentBot])

  if (currentBotId !== params.botId) {
    store.bots.setCurrentBotId(params.botId)
    return null
  }

  const _renderDFEnvironmentInput = (bot: IBot) => {
    // const { classes } = props
    const { platform } = bot

    return (
      <FormControl my={3}>
        <FormLabel as={Text} textStyle="overline" casing="uppercase">
          Dialogflow Environment (Optional)
        </FormLabel>
        <InputGroup>
          <InputLeftElement color={colors.lightGreyScale1100} m="4px 0 0 5px">
            <InputAdornment position="start">
              <Tooltip
                label={
                  platform === BotPlatform.DIALOGFLOW_ES
                    ? tooltipText.dialogflowESEnvironment
                    : tooltipText.dialogflowCXEnvironment
                }
                placement={'top'}
                padding={5}
              >
                <a
                  rel="noreferrer"
                  href="https://docs.botcopy.com/#/basics/connect?id=environments"
                  target="_blank"
                >
                  <LanguageIcon size="24px" />
                </a>
              </Tooltip>
            </InputAdornment>
          </InputLeftElement>
          <Input
            placeholder="default"
            value={formElements.dialogflowEnvironment}
            variant="outline"
            onChange={(e) =>
              onFormElementsChange({ dialogflowEnvironment: e?.target.value })
            }
            size="lg"
            w="100%"
            color={colors.lightGreyScale1200}
            border="1px solid"
            borderColor={colors.lightGreyScale800}
            // mb={6}
          />
        </InputGroup>
      </FormControl>
    )
  }

  const _renderSelectAgentFormControl = (bot: IBot) => {
    const me = store?.users.me
    const org = store?.organizations.current
    if (!org || !me) return
    const hasDefaultOrNoProjectId = getHasDefaultOrNoProjectId(bot)
    const { platform } = bot

    if (platform === BotPlatform.DIALOGFLOW_ES) {
      const projects =
        (searchValue.searchValue ? filteredEsAgents : agents) ?? []
      const resultsListItems = projects.map((result: any, index: number) => {
        const event = {
          target: {
            value: index,
            name: 'projectId',
          },
        }
        return {
          heading: result.agent.displayName,
          subHeading: result.agent?.parent.split('/')[1],
          onClick: () => _selectProject(event),
        }
      })

      return (
        <Box w="100%">
          <FormControl my={3}>
            {hasDefaultOrNoProjectId || isUndefined(selectedIndex) ? (
              <FormLabel textStyle="overline" htmlFor="projectId-required">
                Select Dialogflow ProjectId
              </FormLabel>
            ) : (
              <FormLabel textStyle="overline" htmlFor="projectId-required">
                Current Agent
              </FormLabel>
            )}
            <InputWithResults
              variant="legacy"
              searchValue={searchValue.searchValue}
              onChange={handleESAgentSearch} // Calls searchESAgents
              resultsListItems={resultsListItems} // Use filtered results
              resetToOriginalValue={() => {
                setSearchValue((prevState) => {
                  if (currentBot) {
                    const hasDefaultOrNoProjectIdFlag =
                      getHasDefaultOrNoProjectId(currentBot)

                    const initialSearchValue = hasDefaultOrNoProjectIdFlag
                      ? ''
                      : currentBot.platform === BotPlatform.DIALOGFLOW_ES
                        ? currentBot.agent.displayName
                        : currentBot.cxAgent?.displayName

                    return {
                      ...prevState,
                      searchValue: initialSearchValue,
                    }
                  }

                  return prevState
                })
              }}
            />
            {_renderSelectAgentFormHelper(bot)}
          </FormControl>
          {hasDefaultOrNoProjectId ? null : _renderDFEnvironmentInput(bot)}
        </Box>
      )
    }

    if (platform === BotPlatform.DIALOGFLOW_CX) {
      return (
        <Grid container={true} justify="flex-end">
          {hasDefaultOrNoProjectId ? null : (
            <Grid container={true}>
              <TextField
                variant="filled"
                defaultValue={bot.cxAgent ? bot.cxAgent.name.split('/')[1] : ''}
                label="Project ID"
                fullWidth={true}
                placeholder="Select an agent"
                disabled={true}
                margin="dense"
                InputLabelProps={{
                  style: {
                    color: colors.darkGreyBlue,
                    fontSize: '1.1rem',
                  },
                  shrink: true,
                }}
              />
              <TextField
                variant="filled"
                defaultValue={bot.cxAgent ? bot.cxAgent.displayName : ''}
                label="Display Name"
                fullWidth={true}
                placeholder="Select an agent"
                disabled={true}
                margin="dense"
                InputLabelProps={{
                  style: {
                    color: colors.darkGreyBlue,
                    fontSize: '1.1rem',
                  },
                  shrink: true,
                }}
              />
              {_renderDFEnvironmentInput(bot)}
            </Grid>
          )}
          <Button onClick={getActiveCXProjects}>
            {bot.cxAgent ? 'Change CX Agent' : 'Select CX Agent'}
          </Button>
        </Grid>
      )
    }

    return
  }

  const _renderSelectAgentFormHelper = (bot: IBot) => {
    const hasDefaultOrNoProjectId = getHasDefaultOrNoProjectId(bot)
    const noAgentAccess = `You need to have an owner role on ${
      bot.projectId ? `the ${bot.projectId}` : 'this'
    } project in Google Cloud Platform IAM and at least a developer role in Botcopy to change the connected agent`

    if (savingAgent) {
      return (
        <FormHelperText
          style={{
            color: colors.orange,
            height: 18,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CircularProgress color={'inherit'} size={10} />
          &nbsp; Saving...
        </FormHelperText>
      )
    }
    if (hasDefaultOrNoProjectId) {
      return <FormHelperText style={{ color: 'red' }}>*Required</FormHelperText>
    }
    if (!loadingAgents && agentAccess) {
      return isUndefined(selectedIndex) ? (
        <FormHelperText
          style={{
            color: colors.orange,
            height: 18,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Please select a new Agent
        </FormHelperText>
      ) : (
        <Text color={colors.green} textStyle="overline">
          YOUR BOT IS LINKED TO DIALOGFLOW
        </Text>
      )
    }

    if (!loadingAgents && !agentAccess) {
      return (
        <FormHelperText
          style={{
            display: 'flex',
            flexDirection: 'column' as 'column',
            alignItems: 'center',
          }}
        >
          <Text color={colors.green} textStyle="overline" mb="15px">
            THIS BOT IS LINKED TO DIALOGFLOW
          </Text>
          <a href="https://cloud.google.com/" target="_blank" rel="noreferrer">
            <Tooltip label={noAgentAccess} placement={'top'} padding={5}>
              <div
                style={{
                  color: colors.orange,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Why can't I click the dropdown?
              </div>
            </Tooltip>
          </a>
        </FormHelperText>
      )
    }
    return (
      <FormHelperText
        style={{
          color: colors.orange,
          height: 18,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CircularProgress color={'inherit'} size={10} />
        &nbsp; Loading...
      </FormHelperText>
    )
  }

  const snippetTab1Clicked = () => {
    setEmbedSnippedTabActive({
      general: true,
      gtm: false,
    })
  }

  const snippetTab2Clicked = () => {
    setEmbedSnippedTabActive({
      general: false,
      gtm: true,
    })
  }

  const _copySnippet = () => {
    const org = store?.organizations.current

    // if free trial is done don't allow to copy snippet and redirect to account page to show plans
    if (
      org &&
      org.notifications.overageFreeGrace >= 5 &&
      org.balancesEngagementsTotal < org.plan.conversationLimit
    ) {
      _handleSnackbar(
        'error',
        'Please update your billing information. Redirecting to account page...',
      )
      // redirect to account page
      setTimeout(() => {
        props.navigate('/account?showPlans=true')
      }, 2000)
      return
    }

    const copyText: any = document.getElementById('textarea-snippet')
    if (copyText) {
      copyText.select()
    }
    document.execCommand('copy')
    _handleSnackbar('success', 'Copied snippet to clipboard.')
  }

  const _confirmDelete = () => {
    const me = store?.users.me

    if (me && !me.roles.includes(Roles.MARKETER)) {
      setConfirmDelete(true)
    }
  }

  const _confirmClone = () => {
    const me = store?.users.me

    if (me && !me.roles.includes(Roles.MARKETER)) {
      setCloneBotDialog(true)
    }
  }

  const _cloneBot = async () => {
    if (currentBot) {
      try {
        const clonedBot = await store?.bots.cloneBot(currentBot._id)
        setCloneBotDialog(false)

        toast({
          title: 'Bot Cloned',
          description: `A copy of "${currentBot.name}" has been created.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

        await store?.bots.fetchBots()

        if (clonedBot && clonedBot._id) {
          props.navigate('/')
        }
      } catch (error) {
        logError('Error cloning bot:', error)
        toast({
          title: 'Error',
          description: 'Failed to clone bot. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    }
  }

  const _confirmDisconnect = () => {
    const me = store?.users.me

    if (me && !me.roles.includes(Roles.MARKETER)) {
      setDisconnectBotDialog(true)
    }
  }

  const _closeDialog = () => {
    setConfirmDelete(false)
    setCheckPermissionsDialog(false)
    setDisconnectBotDialog(false)
    setInstalledOnRestrictedDialog(false)
    setDeletedDFAgentDialog(false)
    setCloneBotDialog(false)
    setInstalledOnDomainToDelete('')
    setInstalledOnExceptionToDelete('')
    setInstalledOnDomainToDeleteDialog(false)
    setInstalledOnExceptionToDeleteDialog(false)
    setMaxKeysDialog(false)
    setNoAgentsDialog(false)
    setSelectCXProjectDialog(false)
    setSelectCXAgentDialog(false)
  }

  const _handleSnackbar = async (type: string, message: string) => {
    await setSnackbarMessage(message)
    switch (type) {
      case 'success':
        setSuccessSnackbar(true)
        break

      case 'error':
        setErrorSnackbar(true)
        break
    }
  }

  const _deleteBot = async () => {
    const bots = store?.bots
    if (currentBot) {
      await bots.deleteBot(currentBot._id)
    }

    setConfirmDelete(false)
    props.navigate('/')
  }

  const _closeSnackbar = () => {
    if (successSnackbar) {
      setSuccessSnackbar(false)
    }
    if (errorSnackbar) {
      setErrorSnackbar(false)
    }
    setSnackbarMessage('')
    setSnackbarLink('')
    setSnackbarLinkLabel('')
  }

  const _selectProject = async (event: any) => {
    const newSelectedIndex = event.target.value
    const org = store?.organizations.current
    if (!org) return

    log('select project', newSelectedIndex, event.target.name)
    setSelectedIndex(newSelectedIndex)
    setSavingAgent(true)
    const selectedAgent = searchValue.searchValue
      ? filteredEsAgents?.[newSelectedIndex]
      : agents?.[newSelectedIndex]
    if (selectedAgent && selectedAgent.access) {
      log('selected agent', selectedAgent.agent)

      if (currentBot) {
        try {
          await currentBot.patchAgent(selectedAgent.agent)
          await currentBot.setOldBot()
          setAgentAccess(true)
          setSearchValue((prev) => ({
            ...prev,
            searchValue: selectedAgent.agent.displayName ?? '',
          }))
          const dataLayer = {
            event: Events.botCreation.connect.setESProject.success.type,
            eventName:
              Events.botCreation.connect.setESProject.success.eventName,
            eventCode:
              Events.botCreation.connect.setESProject.success.eventCode,
          }
          trackUserEvent(EventName.PortalAction, dataLayer)
        } catch (e: any) {
          const dataLayer = {
            event: Events.botCreation.connect.setESProject.failure.type,
            eventName:
              Events.botCreation.connect.setESProject.failure.eventName,
            eventCode:
              Events.botCreation.connect.setESProject.failure.eventCode,
          }
          trackUserEvent(EventName.PortalAction, dataLayer)
          if (!e.response) {
            logError('_selectProject: Unknown error, no response', e)

            setMaxKeysDialog(true)
            setErrorAgent(e.message)
            setSavingAgent(false)
            setSelectedIndex(selectedIndex)
            return
          }
          logError('_selectProject', e.response.data.message)
          // most below are deprecated now that we use our own service account and check permissions
          if (
            !agentAccess ||
            e.response.data.message.includes(
              'No credentials after ensuring service account',
            )
          ) {
            setCheckPermissionsDialog(true)
            setErrorAgent(selectedAgent.agent.parent)
            setSelectedIndex(selectedIndex)
          }
          if (e.response.data.message.includes('Check Permissions')) {
            setCheckPermissionsDialog(true)
            setErrorAgent(e.response.data.message.split(':')[0])
            setSelectedIndex(selectedIndex)
          } else if (e.response.data.message.includes('Max Keys')) {
            setMaxKeysDialog(true)
            setErrorAgent(e.response.data.message.split(':')[0])
            setSelectedIndex(selectedIndex)
          }
        }
      }
    }
    setSavingAgent(false)
  }

  const _handleLanguageSelection = async (
    event: any,
    changedLanguage: ILanguageObject,
  ) => {
    if (!currentBot) return
    const { languageSelection } = currentBot
    const dataLayer = {
      event: Events.botCreation.connect.language.add.success.type,
      eventName: Events.botCreation.connect.language.add.success.eventName,
      eventCode: Events.botCreation.connect.language.add.success.eventCode,
    }
    trackUserEvent(EventName.PortalAction, dataLayer)
    // if selecting a new language, push it and make it default if there are no other languages
    // else remove it
    if (event.target.checked) {
      languageSelection.pushNewLanguage({
        ...changedLanguage,
        default: !languageSelection.languages.length,
      })
    } else {
      languageSelection.removeLanguage(changedLanguage)
    }
  }

  const _handleDefaultLanguage = async (
    event: any,
    changedLanguage: ILanguageObject,
  ) => {
    if (!currentBot) return
    const { languageSelection } = currentBot
    // isDefault = false when deselecting, true when selecting
    const isDefault = event.target.checked
    languageSelection.changeDefault(changedLanguage, isDefault)
  }

  const _submitLanguageSelection = async () => {
    if (!currentBot) return
    const { languageSelection } = currentBot

    // check for at least one language
    if (!languageSelection.languages.length) {
      return _handleSnackbar('error', 'Please select at least one language.')
    }

    // check for a default language
    if (!languageSelection.languages.filter((e: any) => e.default).length) {
      return _handleSnackbar('error', 'Please select a default language.')
    }

    try {
      // toggle languageSelection.active and patch
      languageSelection.toggle(true)
      await currentBot.patchLanguageSelection()
      // Send events to mixpanel and GTM
      const dataLayer = {
        event: Events.botCreation.connect.language.add.success.type,
        eventName: Events.botCreation.connect.language.add.success.eventName,
        eventCode: Events.botCreation.connect.language.add.success.eventCode,
        languages: Events.botCreation.connect.language.add.success.languages,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
    } catch (e) {
      const dataLayer = {
        event: Events.botCreation.connect.language.add.failure.type,
        eventName: Events.botCreation.connect.language.add.failure.eventName,
        eventCode: Events.botCreation.connect.language.add.failure.eventCode,
        languages: Events.botCreation.connect.language.add.failure.languages,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
      return _handleSnackbar(
        'error',
        'There was an error saving your languages. Please try again.',
      )
    }
    setLanguageSelectionDialog(false)
  }

  /**
   * Opens the language selection dialog
   */
  const _enableLanguageSelection = async () => {
    if (!currentBot) return
    setLanguageSelectionDialog(true)
    const dataLayer = {
      event: Events.botCreation.connect.language.add.view.type,
      eventName: Events.botCreation.connect.language.add.view.eventName,
      eventCode: Events.botCreation.connect.language.add.view.eventCode,
    }
    trackUserEvent(EventName.PortalAction, dataLayer)
  }

  const _disableLanguageSelection = async () => {
    if (!currentBot) return
    try {
      // clear languages, turn active = false, and patch
      currentBot.languageSelection.clearLanguages()
      currentBot.languageSelection.toggle(false)
      await currentBot.patchLanguageSelection()

      const dataLayer = {
        event: Events.botCreation.connect.language.remove.success.type,
        eventName: Events.botCreation.connect.language.remove.success.eventName,
        eventCode: Events.botCreation.connect.language.remove.success.eventCode,
        languages: Events.botCreation.connect.language.add.success.languages,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
    } catch (e) {
      _handleSnackbar(
        'error',
        'There was an error disabling language selection. Please try again.',
      )
      const dataLayer = {
        event: Events.botCreation.connect.language.remove.failure.type,
        eventName: Events.botCreation.connect.language.remove.failure.eventName,
        eventCode: Events.botCreation.connect.language.remove.failure.eventCode,
        languages: Events.botCreation.connect.language.add.failure.languages,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
    }
  }

  const editFormElementsInstalledOn = (newInstalledOn: string) => {
    // is the domain empty?
    if (!newInstalledOn || newInstalledOn.trim().length === 0) {
      return formElements.installedOn
    }

    // Check if domain already exists in allow list
    const { hostname, protocol, port } = new URL(newInstalledOn)
    newInstalledOn = `${protocol}//${hostname}${port ? `:${port}` : ''}`
    const newInstalledOnAlreadyExists =
      formElements.installedOn.includes(newInstalledOn)

    const newInstalledOnIsValid = installedOnDomainRegexp.test(newInstalledOn)

    if (!newInstalledOnIsValid) {
      // Show error message if the domain is already in the allow list
      _handleSnackbar(
        'error',
        'The new domain to add to allow list is invalid.',
      )
      return formElements.installedOn
    }
    if (newInstalledOnAlreadyExists) {
      _handleSnackbar(
        'error',
        'The new domain is already a part of the allow list.',
      )
      return formElements.installedOn
    }

    const dataLayer = {
      event: Events.botCreation.connect.installedOn.success.type,
      eventName: Events.botCreation.connect.installedOn.success.eventName,
      eventCode: Events.botCreation.connect.installedOn.success.eventCode,
      action: EventAction.Add,
    }
    trackUserEvent(EventName.PortalAction, dataLayer)

    // Add domain to allow list
    return [...formElements.installedOn, newInstalledOn]
  }

  const editFormElementsInstalledOnExceptions = (
    newInstalledOnException: string,
  ) => {
    if (
      !newInstalledOnException ||
      newInstalledOnException.trim().length === 0
    ) {
      return formElements.installedOnExceptions
    }

    // Check if url already exists in exception list
    const { hostname, protocol, pathname, port } = new URL(
      newInstalledOnException,
    )
    newInstalledOnException = `${protocol}//${hostname}${port ? `:${port}` : ``}${pathname}`
    const newInstalledOnExceptionAlreadyExists =
      formElements.installedOnExceptions.includes(newInstalledOnException)

    const newInstalledOnExceptionDomainyExistsInInstalledOn =
      formElements.installedOn.some((installedOnDomain: string) =>
        newInstalledOnException.includes(installedOnDomain),
      )
    const newInstalledOnExceptionIsValid = installedOnExceptionURLRegexp.test(
      newInstalledOnException,
    )

    if (!newInstalledOnExceptionIsValid) {
      _handleSnackbar(
        'error',
        'The new exception to the allow list is invalid.',
      )
      return formElements.installedOnExceptions
    }

    if (!newInstalledOnExceptionDomainyExistsInInstalledOn) {
      // Show error message if the URL is already in the allow list
      _handleSnackbar(
        'error',
        'You can only make exceptions for domains that are part of your allowlist above.',
      )
      return formElements.installedOnExceptions
    }
    if (newInstalledOnExceptionAlreadyExists) {
      // Show error message if the URL is already in the allow list
      _handleSnackbar(
        'error',
        'This URL is already in your allow list exceptions.',
      )
      return formElements.installedOnExceptions
    }
    // Add URL to exception list
    const dataLayer = {
      event: Events.botCreation.connect.installedOnExceptions.success.type,
      eventName:
        Events.botCreation.connect.installedOnExceptions.success.eventName,
      eventCode:
        Events.botCreation.connect.installedOnExceptions.success.eventCode,
      action: EventAction.Add,
    }
    trackUserEvent(EventName.PortalAction, dataLayer)

    return [...formElements.installedOnExceptions, newInstalledOnException]
  }

  const _toggleActive = async () => {
    const org = store?.organizations.current
    if (!org || !currentBot) {
      return false
    }
    try {
      if (org.plan.onHold) {
        _handleSnackbar(
          'error',
          'Your account is on hold. Please pay any outstanding balances on the account page, or sign up for a paid subscription.',
        )
      } else {
        const isActive = !currentBot.active
        await currentBot.toggleActive(isActive)
        const message = `Your bot is now ${isActive ? 'active' : 'inactive'}.`
        _handleSnackbar('success', message)
      }
    } catch (error) {
      logError(error)
      _handleSnackbar('error', 'Please try again.')
      return false
    }
    return true
  }

  const getActiveCXProjects = async () => {
    setSelectCXProjectDialog(true)
    setLoadingCXProjects(true)
    try {
      const activeCXProjects = []
      let pageToken: string | undefined
      do {
        const { projects, nextPageToken } = await getActiveProjects(pageToken)
        pageToken = nextPageToken

        activeCXProjects.push(...projects)
      } while (pageToken)

      activeCXProjects
        .sort((a: any, b: any) => Number(a.access) - Number(b.access))
        .reverse()
      setActiveProjects(activeCXProjects)
      setLoadingCXProjects(false)
      const dataLayer = {
        event: Events.botCreation.connect.getCXProjects.success.type,
        eventName: Events.botCreation.connect.getCXProjects.success.eventName,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
    } catch (e) {
      logError('error getActiveCXProjects', e)
      setSelectCXProjectDialog(false)
      setLoadingCXProjects(false)
      setErrorSnackbar(true)
      setSnackbarMessage(
        'Sorry, there was an error fetching your CX projects. Please try again',
      )
    }
  }

  const getCXAgents = async (projectId: string, botId: string) => {
    try {
      const result = await fetchCXAgents({ projectId, botId })
      setSelectCXProjectDialog(false)
      setCxAgents(result)
      setSearchValue((prev) => ({ ...prev, searchValue: '' }))
      setSelectCXAgentDialog(true)
      const dataLayer = {
        event: Events.botCreation.connect.getCXAgents.success.type,
        eventName: Events.botCreation.connect.getCXAgents.success.eventName,
        eventCode: Events.botCreation.connect.getCXAgents.success.eventCode,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
    } catch (e: any) {
      setErrorSnackbar(true)
      setSnackbarMessage(
        e?.response?.data?.message || 'Error while setting the Bot Agent',
      )
      const dataLayer = {
        event: Events.botCreation.connect.getCXAgents.failure.type,
        eventName: Events.botCreation.connect.getCXAgents.failure.eventName,
        eventCode: Events.botCreation.connect.getCXAgents.failure.eventCode,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
      logError('error getCXAgents', e)
      setSelectCXProjectDialog(false)
      setSelectCXAgentDialog(false)
      setErrorSnackbar(true)
      setSnackbarMessage(
        'Sorry, there was an error fetching your CX agents. Please try again',
      )
    }
  }

  const setCXAgent = async (cxAgent: any) => {
    const org = store?.organizations.current
    if (currentBot && org) {
      try {
        await currentBot.patchCXAgent(cxAgent)
        await currentBot.setOldBot()
        setAgentAccess(true)
        setSelectCXAgentDialog(false)
        const dataLayer = {
          event: Events.botCreation.connect.setCXAgent.success.type,
          eventName: Events.botCreation.connect.setCXAgent.success.eventName,
          eventCode: Events.botCreation.connect.setCXAgent.success.eventCode,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
      } catch (e: any) {
        setErrorSnackbar(true)
        setSnackbarLinkLabel(
          'Enable Service Account Key Creation on a GCP Organization',
        )
        setSnackbarLink(
          'https://www.loom.com/share/2488a1cfb7984dc88ac1a749cb36ed87?sid=a5ce1b5b-91ce-4326-a835-ad2ea3b8dea1',
        )
        setSnackbarMessage(
          e?.response?.data?.message.includes('Key creation is not allowed')
            ? 'Key creation is not allowed on your GCP Organization make sure that you enable it following this video tutorial'
            : e?.response?.data?.message || 'Error while setting the Bot Agent',
        )
        logError('error setting CX agent', e)
        const dataLayer = {
          event: Events.botCreation.connect.setCXAgent.failure.type,
          eventName: Events.botCreation.connect.setCXAgent.failure.eventName,
          eventCode: Events.botCreation.connect.setCXAgent.failure.eventCode,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
      }
    }
  }

  const searchAvailableBotMenus = (value: string) => {
    setBotMenuSearchValue((prev) => ({ ...prev, searchValue: value }))
    setFilteredBotMenus(
      botMenusData?.filter((item: any) => {
        return item.name.toLowerCase().includes(value.toLowerCase())
      }) || [],
    )
  }

  const handleAvailableBotMenuSearch = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value

    searchAvailableBotMenus(value)
  }

  const searchESAgents = async (value: string) => {
    const filteredAgents =
      agents?.filter((item: any) => {
        return item.agent.displayName
          ?.toLowerCase()
          .includes(value.toLowerCase())
      }) || []

    setSearchValue((prev) => ({ ...prev, searchValue: value }))
    setFilteredEsAgents(filteredAgents)
  }

  const handleESAgentSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    searchESAgents(value)
  }

  const searchCXAgents = async (value: string) => {
    const filteredAgents =
      cxAgents?.filter((item: any) => {
        return item.displayName?.toLowerCase().includes(value.toLowerCase())
      }) || []
    setSearchValue((prev) => ({ ...prev, searchValue: value }))
    setFilteredCxAgents(filteredAgents)
  }

  const handleCxAgentSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    searchCXAgents(value)
  }

  const searchCxProjects = async (value: string) => {
    const filteredProjects =
      activeProjects.filter((item: any) => {
        return item.project.projectId
          ?.toLowerCase()
          .includes(value.toLowerCase())
      }) || []
    setSearchValue((prev) => ({ ...prev, searchValue: value }))
    setFilteredCxProjects(filteredProjects)
  }

  const handleCxProjectSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    searchCxProjects(value)
  }

  const _disconnectBot = async () => {
    if (currentBot) {
      try {
        await currentBot.disconnectBot()
        setDisconnectBotDialog(false)
        setSelectedIndex(undefined)
        const dataLayer = {
          event: Events.botCreation.connect.disconnectBot.success.type,
          eventName: Events.botCreation.connect.disconnectBot.success.eventName,
          eventCode: Events.botCreation.connect.disconnectBot.success.eventCode,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
        window.location.reload()
      } catch (e) {
        const dataLayer = {
          event: Events.botCreation.connect.disconnectBot.failure.type,
          eventName: Events.botCreation.connect.disconnectBot.failure.eventName,
          eventCode: Events.botCreation.connect.disconnectBot.failure.eventCode,
        }
        trackUserEvent(EventName.PortalAction, dataLayer)
        setErrorSnackbar(true)
        setSnackbarMessage(
          'Sorry, there was an error disconnecting your agent. Please try again',
        )
      }
    }
  }

  const _renderDataRegionSelect = (bot: IBot) => {
    return bot.platform === BotPlatform.DIALOGFLOW_ES ? (
      <div>
        <FormControl>
          <FormLabel textStyle="overline" htmlFor="data region">
            Data Region
          </FormLabel>
          <Select
            value={bot.dialogflow.location}
            onChange={(e) => {
              _changeDataRegion(e.target.value, bot)
            }}
            fullWidth={true}
          >
            {map(esDataRegions, (region: IDataRegion, index: number) => {
              return (
                <MenuItem value={region.value} key={index}>
                  {region.title}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    ) : (
      <div>
        <FormControl>
          <FormLabel textStyle="overline" htmlFor="data region">
            Data Region
          </FormLabel>
          <Select
            value={bot.dialogflow.location}
            onChange={(e) => {
              _changeDataRegion(e.target.value, bot)
            }}
            fullWidth={true}
          >
            {map(cxDataRegions, (region: IDataRegion, index: number) => {
              return (
                <MenuItem value={region.value} key={index}>
                  {region.title}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    )
  }

  const _changeDataRegion = async (location: string, bot: IBot) => {
    try {
      bot.dialogflow.changeLocation(location)
      await bot.patchDialogflow()
      _handleSnackbar('success', 'Changed data region')
      if (!loadingAgents && currentBot) {
        await _fetchAgents()
      }
    } catch (e) {
      log(e)
      _handleSnackbar('error', 'Please try again')
    }
  }

  if (currentBot) {
    const { classes } = props
    const me = store?.users.me
    const org = store?.organizations.current
    const bot = currentBot
    const { name } = bot

    const embedSnippet = `<script type="text/javascript"
      id="botcopy-embedder-d7lcfheammjct"
      class="botcopy-embedder-d7lcfheammjct" 
      data-botId="${bot._id}"
  >
      var s = document.createElement('script'); 
      s.type = 'text/javascript'; s.async = true; 
      s.src = '${widgetBaseURL}/js/injection.js'; 
      document.getElementById('botcopy-embedder-d7lcfheammjct').appendChild(s);
  </script>`
    const gtmSnippet = `<div
  id="botcopy-embedder-d7lcfheammjct"
  class="botcopy-embedder-d7lcfheammjct" 
  data-botId="${bot._id}"
  >
    <script type="text/javascript">
      var s = document.createElement('script'); 
      s.type = 'text/javascript'; s.async = true; 
      s.src = '${widgetBaseURL}/js/injection.js'; 
      document.getElementById('botcopy-embedder-d7lcfheammjct').appendChild(s);
    </script>
  </div>`

    const hasDefaultOrNoProjectId =
      !bot.projectId || bot.projectId === FALLBACK_BOT_PROJECT_ID

    if (currentBot && me && org) {
      return (
        <div className="connect-page-outer-grid">
          {org.notifications.overageFreeGrace >= 5 &&
          org.balancesEngagementsTotal < org.plan.conversationLimit ? (
            <div className="onhold-banner">
              <Box display={'flex'} flexDirection="column">
                <h1 className="onhold-banner-title">
                  Your free trial with Botcopy is up!
                </h1>
                <h3 className="onhold-banner-subtitle">
                  Upgrade now to one of our cost saving plans.
                </h3>
              </Box>
              <Box display={'flex'} flexDirection="column">
                <a
                  className="onhold-plan-button"
                  href={`${process.env.PUBLIC_URL}/account?showPlans=true`}
                >
                  Upgrade Plan
                </a>
              </Box>
            </div>
          ) : null}
          <CreditCardCapture classes={{}} store={store} />
          <Grid
            container={true}
            direction="column"
            justify="center"
            alignContent="center"
            style={{
              padding: '20px',
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: '1105px',
              minHeight: '200',
            }}
          >
            <Text textStyle="h5" fontSize="28px" my="18px">
              Connect
            </Text>
            {/* No project selected */}
            {hasDefaultOrNoProjectId &&
              (loadingAgents ? (
                <Card className={classes.defaultAgentPaper}>
                  <div style={divStyle.loading}>
                    <CircularProgress color={'inherit'} />
                    <br />
                    <br />
                    Loading your agents...
                    <br />
                    If you have a lot of agents, this might take a minute.
                  </div>
                </Card>
              ) : (
                <Card bg={colors.lightGreyScale200} p={8}>
                  <div className="connect-paper-title">
                    <Flex justify="space-between" alignItems="center">
                      <Text textStyle="subtitle1">Dialogflow -&gt; Bot</Text>
                      <span title="Delete Bot">
                        <DeleteIcon
                          style={{ cursor: 'pointer' }}
                          size="24px"
                          onClick={_confirmDelete}
                        />
                      </span>
                    </Flex>
                  </div>
                  <Text textStyle="body2" className="paper-subtitle" my={4}>
                    Select a region and an agent to generate your bot's snippet
                    and set up integrations.
                  </Text>
                  {/* Select Agent */}
                  {_renderDataRegionSelect(bot)}
                  <br />
                  {_renderSelectAgentFormControl(bot)}
                </Card>
              ))}

            {/* Step 1 and 2 Container Grid */}

            {hasDefaultOrNoProjectId ? null : (
              <Wrap spacing={6} w="100%">
                <WrapItem flex={1}>
                  <Card
                    className="connect-card"
                    bg={colors.lightGreyScale200}
                    p={8}
                    flex={1}
                    border="1px solid"
                    borderColor={colors.lightGreyScale800}
                  >
                    {!hasDefaultOrNoProjectId && (
                      <Box>
                        <Grid
                          container={true}
                          justify="space-between"
                          alignItems="center"
                        >
                          <Text textStyle="h5" fontSize="21px">
                            Agent Details
                          </Text>
                          <div>
                            <Grid
                              container={true}
                              justify="space-between"
                              alignItems="center"
                            >
                              <span title="Toggle Bot Online or Offline">
                                <FormControl>
                                  <HStack>
                                    <Switch
                                      isChecked={bot.active}
                                      onChange={_toggleActive}
                                    />
                                    <FormLabel textStyle="caption">
                                      {bot.active ? 'Online' : 'Offline'}
                                    </FormLabel>
                                  </HStack>
                                </FormControl>
                              </span>
                              <Tooltip
                                label="Disconnect from Dialogflow Agent"
                                placement="top"
                              >
                                <Flex mx={4}>
                                  <EjectOutlineIcon
                                    size="24px"
                                    cursor="pointer"
                                    onClick={_confirmDisconnect}
                                  />
                                </Flex>
                              </Tooltip>
                              <Tooltip label="Clone Bot" placement="top">
                                <Flex mr={4}>
                                  <CopyAllIcon
                                    size="24px"
                                    cursor="pointer"
                                    onClick={_confirmClone}
                                  />
                                </Flex>
                              </Tooltip>
                              <Tooltip label="Delete Bot" placement="top">
                                <Box as="span">
                                  <DeleteIcon
                                    cursor="pointer"
                                    size="24px"
                                    onClick={_confirmDelete}
                                  />
                                </Box>
                              </Tooltip>
                            </Grid>
                          </div>
                        </Grid>
                        <Box my={4}>
                          <BotcopyMonospace
                            text={`Region: ${bot.dialogflow.location}`}
                          />
                        </Box>
                        <Grid className="bot-details-grid">
                          {/* Select Agent */}
                          {_renderSelectAgentFormControl(bot)}
                        </Grid>
                        <BotDetails
                          formElements={{
                            botName: formElements.botName,
                            dashboardLabel: formElements.dashboardLabel,
                          }}
                          onFormElementsChange={onFormElementsChange}
                        />
                      </Box>
                    )}
                  </Card>
                </WrapItem>
                {hasDefaultOrNoProjectId ? null : (
                  <WrapItem flex={1}>
                    <Card
                      className="connect-card"
                      bg={colors.lightGreyScale200}
                      border="1px solid"
                      borderColor={colors.lightGreyScale800}
                      flex={1}
                      h="100%"
                    >
                      <Grid
                        container={true}
                        style={{
                          marginBottom: '2em',
                        }}
                      >
                        <HStack>
                          <Text textStyle="h5" fontSize="21px">
                            Embed Snippet
                          </Text>
                          <Tooltip
                            label={
                              embedSnippedTabActive.general
                                ? tooltipText.embedSnippet
                                : tooltipText.gtmSnippet
                            }
                            placement={'top'}
                            padding={5}
                          >
                            <Box color={colors.lightGreyScale1100}>
                              <InfoIcon size="20px" color="inherit" />
                            </Box>
                          </Tooltip>
                        </HStack>
                      </Grid>
                      <Grid
                        id="Items Grid"
                        item={true}
                        direction="column"
                        alignItems="center"
                      >
                        <div className={classes.tabWrapper}>
                          <HStack justify="space-between">
                            <div className="tabs">
                              <Button
                                textStyle="caption"
                                fontSize="11px"
                                className={`generalTab ${
                                  !embedSnippedTabActive.general
                                    ? 'inactive'
                                    : ''
                                }`}
                                mr="2"
                                onClick={snippetTab1Clicked}
                              >
                                General
                              </Button>
                              <Button
                                textStyle="caption"
                                fontSize="11px"
                                className={`gtmTab ${
                                  !embedSnippedTabActive.gtm ? 'inactive' : ''
                                }`}
                                onClick={snippetTab2Clicked}
                              >
                                Google Tag Manager
                              </Button>
                            </div>
                            <Box cursor="pointer" onClick={_copySnippet}>
                              <MdContentCopy size="18px" />
                            </Box>
                          </HStack>
                          {/* Tab Content */}
                          <Textarea
                            id="textarea-snippet"
                            fontFamily="Cascadia Mono, Menlo, Monaco, monospace"
                            className={`text-area-snippet ${
                              org.notifications.overageFreeGrace >= 5 &&
                              org.balancesEngagementsTotal <
                                org.plan.conversationLimit &&
                              !bot.active
                                ? 'blur'
                                : ''
                            }`}
                            minH="350px"
                            size="sm"
                            bg={colors.pureWhite}
                            isReadOnly={true}
                            value={
                              embedSnippedTabActive.general
                                ? embedSnippet
                                : gtmSnippet
                            }
                          />
                        </div>
                      </Grid>
                    </Card>
                  </WrapItem>
                )}
              </Wrap>
            )}
            {hasDefaultOrNoProjectId ? null : (
              <Grid container={true} className="container-grid-connect">
                <ChakraGrid h="100%" w="100%" templateColumns="repeat(2, 1fr)">
                  <GridItem colSpan={4} rowSpan={1}>
                    {org.featureFlags?.includes('agent-one') && (
                      <Accordion
                        as={Card}
                        bg={colors.lightGreyScale100}
                        allowToggle={true}
                        maxW="1214px"
                        border="1px solid"
                        borderColor={colors.lightGreyScale800}
                      >
                        <AccordionItem className="agent-one-accordion-item">
                          <AccordionButton
                            borderRadius={6}
                            border="none"
                            p={8}
                            h="auto"
                          >
                            <Box as="span" flex="1" textAlign="left">
                              <HStack>
                                <Text
                                  textStyle="h5"
                                  fontSize="21px"
                                  whiteSpace="nowrap"
                                >
                                  Agent One
                                </Text>
                              </HStack>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel>
                            <Box p={5}>
                              {currentBot.botMenu ? (
                                <>
                                  <Text
                                    textStyle="h5"
                                    fontSize="21px"
                                    whiteSpace="nowrap"
                                  >
                                    {currentBot.botMenu.name}
                                  </Text>
                                  <Button
                                    isLoading={detachBotMenuLoading}
                                    onClick={handleDetachBotMenu}
                                    mt={5}
                                  >
                                    Detach Bot Menu
                                  </Button>
                                </>
                              ) : (
                                <InputWithResults
                                  searchValue={botMenuSearchValue.searchValue}
                                  onChange={handleAvailableBotMenuSearch}
                                  resultsListItems={botMenusToShow}
                                />
                              )}
                            </Box>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    )}
                    <Accordion
                      as={Card}
                      bg={colors.lightGreyScale100}
                      allowToggle={true}
                      maxW="1214px"
                      border="1px solid"
                      borderColor={colors.lightGreyScale800}
                    >
                      <AccordionItem>
                        <AccordionButton
                          borderRadius={6}
                          border="none"
                          p={8}
                          h="auto"
                        >
                          <Box as="span" flex="1" textAlign="left">
                            <DomainAllowlistToggle
                              isDisabled={me.roles.includes(Roles.MARKETER)}
                              isChecked={formElements.installedOnRestricted}
                              onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                              ) => {
                                const newValue = (
                                  event.target as HTMLInputElement
                                ).checked
                                console.log({ newValue })
                                setInstalledOnRestrictedDialog(true)
                                setInstalledOnRestrictedToSet(newValue)
                              }}
                            />
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel p={8} pt={0}>
                          <DomainAllowlist
                            formElements={{
                              newInstalledOn: formElements.newInstalledOn,
                              newInstalledOnException:
                                formElements.newInstalledOnException,
                              installedOn: formElements.installedOn,
                              installedOnExceptions:
                                formElements.installedOnExceptions,
                            }}
                            onFormElementsChange={onFormElementsChange}
                            setInstalledOnDomainToDeleteDialog={
                              setInstalledOnDomainToDeleteDialog
                            }
                            setInstalledOnDomainToDelete={
                              setInstalledOnDomainToDelete
                            }
                            setInstalledOnExceptionToDeleteDialog={
                              setInstalledOnExceptionToDeleteDialog
                            }
                            setInstalledOnExceptionToDelete={
                              setInstalledOnExceptionToDelete
                            }
                          />
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </GridItem>
                  <GridItem colSpan={4} rowSpan={1}>
                    <Accordion
                      as={Card}
                      bg={colors.lightGreyScale100}
                      allowToggle={true}
                      maxW="1214px"
                      border="1px solid"
                      borderColor={colors.lightGreyScale800}
                    >
                      <AccordionItem>
                        <h2>
                          <AccordionButton
                            borderRadius={6}
                            border="none"
                            p={8}
                            h="auto"
                          >
                            <Box as="span" flex="1" textAlign="left">
                              <Text textStyle="h5" fontSize="21px">
                                Language Selection
                              </Text>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>

                        <AccordionPanel p={8} pt={0}>
                          <Grid item={true} xs={12}>
                            {bot.languageSelection.active ? (
                              <Grid container={true} direction="column">
                                <Text textStyle="body2" mb={4}>
                                  Language selection is enabled. Your users will
                                  be prompted to choose the language your bot
                                  responds in.
                                </Text>
                                <Flex>
                                  <Button
                                    className="connect-edit-button"
                                    onClick={() =>
                                      setLanguageSelectionDialog(true)
                                    }
                                    disabled={me.roles.includes(Roles.MARKETER)}
                                    mr={2}
                                  >
                                    Edit Languages
                                  </Button>
                                  <Button
                                    variant="light"
                                    onClick={_disableLanguageSelection}
                                    disabled={me.roles.includes(Roles.MARKETER)}
                                  >
                                    Disable Language Selection
                                  </Button>
                                </Flex>
                              </Grid>
                            ) : (
                              <div>
                                <Text textStyle="body2" my={4}>
                                  By default, language selection is off. Your
                                  bot detects a user’s preferred browser
                                  language and sends it with a Dialogflow query.
                                  If your Dialogflow agent supports the user’s
                                  browser language, responses will appear in
                                  that language.
                                  <br />
                                  <br />
                                  However, if you enable Language Selection,
                                  your end-users will see that your bot has
                                  other languages available. A dropdown menu in
                                  the chat window makes it easy for end-users to
                                  select their preferred language.
                                </Text>
                                <Button onClick={_enableLanguageSelection}>
                                  Enable Language Selection
                                </Button>
                              </div>
                            )}
                          </Grid>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </GridItem>
                </ChakraGrid>
              </Grid>
            )}
          </Grid>
          {/* DIALOGS AND SNACKBARS */}
          {/* DELETE BOT */}
          <Dialog
            open={confirmDelete}
            onClose={_closeDialog}
            aria-labelledby="alert-dialog-title"
            maxWidth="lg"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to permanently delete {name}?
            </DialogTitle>
            <DialogContent>
              <Text textStyle="body2">
                This action cannot be undone. All data associated with this bot
                will be lost.
              </Text>
            </DialogContent>
            <DialogActions>
              <Button variant="light" onClick={_closeDialog}>
                Cancel
              </Button>
              <Button onClick={_deleteBot}>Yes</Button>
            </DialogActions>
          </Dialog>
          {/* DISCONNECT AGENT */}
          <Dialog
            open={disconnectBotDialog}
            onClose={_closeDialog}
            aria-labelledby="alert-dialog-title"
          >
            <DialogTitle id="alert-dialog-title">
              <Text textStyle="subtitle1">
                Disconnect your bot from Dialogflow
              </Text>
            </DialogTitle>
            <DialogContent>
              <Text textStyle="body2">
                This action will disconnect {name} from the agent associated
                with {store.bots.currentBot.projectId}, requiring you to
                reconnect it to an agent again.
                <br />
                <br />
                If you want to temporarily <strong>deactivate</strong> the bot,
                press cancel and use the switch.
              </Text>
            </DialogContent>
            <DialogActions>
              <Button variant="light" onClick={_closeDialog}>
                Cancel
              </Button>
              <Button onClick={() => _disconnectBot()}>Confirm</Button>
            </DialogActions>
          </Dialog>

          {/* CLONE BOT */}
          <Dialog
            open={cloneBotDialog}
            onClose={_closeDialog}
            aria-labelledby="clone-dialog-title"
          >
            <DialogTitle id="clone-dialog-title">
              <Text textStyle="subtitle1">Clone Bot</Text>
            </DialogTitle>
            <DialogContent>
              <Text textStyle="body2">
                This will create a copy of {name} with the same agent connection
                and configuration settings. The new bot will be named "{name}{' '}
                Copy".
              </Text>
            </DialogContent>
            <DialogActions>
              <Button variant="light" onClick={_closeDialog}>
                Cancel
              </Button>
              <Button onClick={_cloneBot}>Create Clone</Button>
            </DialogActions>
          </Dialog>

          {/* Toggle Domain Allowlist Dialog */}
          <Dialog
            open={installedOnRestrictedDialog}
            onClose={_closeDialog}
            aria-labelledby="alert-dialog-title"
          >
            <DialogTitle id="alert-dialog-title">
              {installedOnRestrictedToSet
                ? 'Enable Domain Allowlist'
                : 'Disable Domain Allowlist'}
            </DialogTitle>
            <DialogContent>
              <span className="domain-text">
                {installedOnRestrictedToSet
                  ? 'Warning: This is potentially a destructive action. Any domain not in the allowlist will be blocked from loading this bot.'
                  : 'Warning: This will allow your bot to use any domain.'}
              </span>
            </DialogContent>
            <DialogActions>
              <Button variant="light" onClick={_closeDialog}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setFormElements((prev) => ({
                    ...prev,
                    installedOnRestricted: installedOnRestrictedToSet,
                  }))
                  _closeDialog()
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          {/* Delete Domain from Allowlist  */}
          <Dialog
            open={installedOnDomainToDeleteDialog}
            onClose={_closeDialog}
            aria-labelledby="alert-dialog-title"
          >
            <DialogTitle id="alert-dialog-title">Delete Domain</DialogTitle>
            <DialogContent>
              <span className="dialog-text">
                Are you sure you want to delete the following domain:
              </span>
              <div className="domain-text">{installedOnDomainToDelete}</div>
              <br />
              <span>
                Warning: This is a destructive action. Deleting a domain will
                stop your bot from loading on that domain.
              </span>
            </DialogContent>
            <DialogActions>
              <Button variant="light" onClick={_closeDialog}>
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  setFormElements((prev) => ({
                    ...prev,
                    installedOn: formElements.installedOn.filter(
                      (installedOnDomain) =>
                        installedOnDomain !== installedOnDomainToDelete,
                    ),
                  }))
                  _closeDialog()
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          {/* Delete URL from Allowlist Exceptions */}
          <Dialog
            open={installedOnExceptionToDeleteDialog}
            onClose={_closeDialog}
            aria-labelledby="alert-dialog-title"
          >
            <DialogTitle id="alert-dialog-title">
              Delete URL Exception
            </DialogTitle>
            <DialogContent>
              <span className="dialog-text">
                Are you sure you want to delete the following URL:
              </span>
              <div className="domain-text">{installedOnExceptionToDelete}</div>
              <br />
              <span>
                Warning: This is a destructive action. Deleting a URL will allow
                your bot to load on that URL.
              </span>
            </DialogContent>
            <DialogActions>
              <Button variant="light" onClick={_closeDialog}>
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  setFormElements((prev) => ({
                    ...prev,
                    installedOnExceptions:
                      formElements.installedOnExceptions.filter(
                        (installedOnException) =>
                          installedOnException !== installedOnExceptionToDelete,
                      ),
                  }))
                  _closeDialog()
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          {/* CHECK PERMISSIONS */}
          <Dialog
            open={checkPermissionsDialog}
            onClose={_closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                backgroundColor: colors.darkGreyBlue,
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">
              <div
                style={{
                  fontWeight: 600,
                  color: colors.offWhite,
                }}
              >
                Missing Role
              </div>
            </DialogTitle>

            <DialogContent className="permissions-dialog-content">
              {
                // tslint:disable
              }
              In order to connect to: <strong>{errorAgent}</strong>
              <br />
              <br />
              <strong>{me.email}</strong> must have an{' '}
              <strong>Owner role</strong> in the project's IAM policy in the{' '}
              <a
                href="https://console.cloud.google.com/home/dashboard"
                target="_blank"
                rel="noreferrer"
              >
                Google Cloud Console
              </a>
              .
              <br />
              <br />
              This is required so we can create a service account on the
              project. If the problem persists, reach out to our support team.
              {
                // tslint:enable
              }
            </DialogContent>
            <DialogActions>
              <Button
                variant="light"
                onClick={() => {
                  window.open('https://calendly.com/aseegers/15min-1', '_blank')
                }}
              >
                Schedule a call
              </Button>
              <Button onClick={_closeDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          {/* MAX KEYS DIALOG */}
          <Dialog
            open={maxKeysDialog}
            onClose={_closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                backgroundColor: colors.darkGreyBlue,
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">
              <div
                style={{
                  fontWeight: 600,
                  color: colors.offWhite,
                }}
              >
                Please delete some keys for {errorAgent}.
              </div>
            </DialogTitle>

            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  fontWeight: 550,
                  color: colors.offWhite,
                }}
              >
                Google Cloud Project limits the number of keys on a service
                account to 10. Looks like you're at that limit! Please delete
                some keys to connect this bot.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={_closeDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          {/* MAX KEYS DIALOG */}
          <Dialog
            open={noAgentsDialog}
            onClose={_closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Grid
              style={{
                display: 'flex',
              }}
            >
              <DialogTitle id="alert-dialog-title">
                <Text textStyle="subtitle1">No Agents Found!</Text>
              </DialogTitle>
            </Grid>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Text color={colors.lightGreyScale1200} textStyle="body2">
                  We couldn't find any Dialogflow agents tied to {me.email} in
                  the {bot.dialogflow.location} data region. Please follow{' '}
                  <Link
                    href="https://dialogflow.cloud.google.com"
                    isExternal={true}
                    p={0}
                  >
                    this link
                  </Link>{' '}
                  and create an agent to continue using Botcopy.
                </Text>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={_closeDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          {/* DELETED DIALOGFLOW AGENT DIALOG */}
          <Dialog
            open={deletedDFAgentDialog}
            onClose={_closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                backgroundColor: colors.darkGreyBlue,
              },
            }}
          >
            <Grid
              style={{
                display: 'flex',
              }}
            >
              <DialogTitle id="alert-dialog-title">
                <div
                  style={{
                    fontWeight: 600,
                    color: colors.offWhite,
                  }}
                >
                  Uh Oh!
                </div>
              </DialogTitle>
            </Grid>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  fontWeight: 550,
                  color: colors.offWhite,
                }}
              >
                It appears you might have deleted your Dialogflow Agent:{' '}
                {store.bots.currentBot.projectId}. Please select a new agent
                from the drop down.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={_closeDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          {/* LANGUAGE SELECTION DIALOG */}
          <Dialog
            open={languageSelectionDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
          >
            <HStack justify="space-between" p={8}>
              <Text textStyle="subtitle1">Select Languages</Text>
              <ClearIcon
                style={{ cursor: 'pointer' }}
                size="24px"
                onClick={() => setLanguageSelectionDialog(false)}
              />
            </HStack>

            <DialogContent
              style={{
                padding: '0 32px',
                borderTop: `1px solid ${colors.lightGreyScale800}`,
              }}
            >
              <DialogContentText id="alert-dialog-description">
                <Text
                  textStyle="body2"
                  color={colors.lightGreyScale1200}
                  mt={8}
                  mb={6}
                >
                  Choose only the languages you've configured on your Dialogflow
                  agent. These languages will appear as options for your user in
                  the drop down menu.
                </Text>
              </DialogContentText>

              <FormControl>
                <FormGroup
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    overflowY: 'auto',
                    width: '100%',
                    height: '350px',
                  }}
                >
                  <SimpleGrid columns={[1, 2, null, 3]} spacing={4}>
                    {map(
                      dialogflowLanguages,
                      (languageObject: ILanguageObject, index: number) => (
                        <Box
                          key={index}
                          border={`1px solid ${colors.lightGreyScale800}`}
                          borderRadius="4px"
                          minW="300px"
                          // width="300px"
                          py={6}
                          px={4}
                        >
                          <Flex justify="space-between">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disableRipple={true}
                                  checked={
                                    bot.languageSelection.languages.filter(
                                      (language: any) =>
                                        language.code === languageObject.code,
                                    ).length > 0
                                  }
                                  onChange={(e: any) =>
                                    _handleLanguageSelection(e, languageObject)
                                  }
                                />
                              }
                              label={
                                <>
                                  <Text textStyle="overline">
                                    {`${languageObject.name}`}
                                  </Text>
                                  <Text textStyle="caption">
                                    {`${languageObject.code}`}
                                  </Text>
                                </>
                              }
                            />
                            {bot.languageSelection.languages.filter(
                              (language: any) =>
                                language.code === languageObject.code,
                            ).length > 0 ? (
                              <FormControlLabel
                                hidden={
                                  bot.languageSelection.languages.filter(
                                    (language: any) => language.default,
                                  ).length > 0 &&
                                  !bot.languageSelection.languages.filter(
                                    (language: any) =>
                                      language.code === languageObject.code,
                                  )[0].default
                                }
                                control={
                                  <Switch
                                    checked={
                                      bot.languageSelection.languages.filter(
                                        (language: any) =>
                                          language.code === languageObject.code,
                                      )[0].default
                                    }
                                    onChange={(e: any) =>
                                      _handleDefaultLanguage(e, languageObject)
                                    }
                                  />
                                }
                                label={
                                  <Text textStyle="overline" ml={2}>
                                    Default
                                  </Text>
                                }
                              />
                            ) : null}
                          </Flex>
                        </Box>
                      ),
                    )}
                  </SimpleGrid>
                </FormGroup>
              </FormControl>
            </DialogContent>
            <DialogActions style={{ padding: '32px' }}>
              <Button
                variant="light"
                onClick={() => setLanguageSelectionDialog(false)}
              >
                Cancel
              </Button>
              <Button onClick={_submitLanguageSelection}>Submit</Button>
            </DialogActions>
          </Dialog>
          {/* Active Projects Dialog */}
          <Dialog
            open={selectCXProjectDialog}
            onClose={_closeDialog}
            maxWidth="sm"
          >
            <div className="cx-dialog-title">
              {loadingCXProjects ? (
                ''
              ) : (
                <div>
                  Your Projects
                  <div className="cx-dialog-subtitle">
                    Select the project that hosts your Dialogflow CX agent
                  </div>
                  <Input
                    mt={5}
                    type="text"
                    value={searchValue.searchValue}
                    onChange={handleCxProjectSearch}
                    placeholder="Search projects..."
                  />
                </div>
              )}
            </div>

            <DialogContent>
              {loadingCXProjects ? (
                <div className="loading-paper">
                  <div className="loading">Checking your project roles</div>
                  <div className="loading-description">
                    <strong>{me.email}</strong> must have an{' '}
                    <strong>Owner role</strong> on the Google Cloud Project that
                    hosts the CX Agent.
                  </div>
                  <br />
                  <br />
                </div>
              ) : (
                map(
                  searchValue.searchValue ? filteredCxProjects : activeProjects,
                  (activeProject: any, index: number) => {
                    return (
                      <div key={index} className="project-tile">
                        <div className="project-text-grid">
                          <div className="project-id">
                            {activeProject.project.projectId}
                          </div>
                          {activeProject.access ? null : (
                            <div className="owner-required-subtext">
                              An owner role is required to connect.
                            </div>
                          )}
                        </div>
                        <Button
                          disabled={!activeProject.access}
                          onClick={() =>
                            getCXAgents(
                              activeProject.project.projectId,
                              bot._id,
                            )
                          }
                          style={
                            activeProject.access
                              ? {}
                              : {
                                  opacity: 0.5,
                                }
                          }
                        >
                          Select
                        </Button>
                      </div>
                    )
                  },
                )
              )}
            </DialogContent>
          </Dialog>
          {/* CX Agents Dialog */}
          <Dialog
            open={selectCXAgentDialog}
            onClose={_closeDialog}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
          >
            <div className="cx-dialog-title">
              {loadingCXProjects ? (
                ''
              ) : (
                <div>
                  Your Agents
                  <div className="cx-dialog-subtitle">
                    Select a CX agent to connect
                  </div>
                  <Tooltip
                    label="The region you selected will need to match the region you set when creating your agent in Dialogflow"
                    placement="top"
                    padding={5}
                  >
                    <Text fontSize="14px" fontWeight="bold">
                      Not seeing your bot?
                    </Text>
                  </Tooltip>
                  <Input
                    mt={5}
                    type="text"
                    value={searchValue.searchValue}
                    onChange={handleCxAgentSearch}
                    placeholder="Search agents..."
                  />
                </div>
              )}
            </div>
            <DialogContent>
              {map(
                searchValue.searchValue ? filteredCxAgents : cxAgents,
                (agent: any, index: number) => {
                  return (
                    <div key={index} className="project-tile">
                      <div className="project-id">{agent.displayName}</div>
                      <Button onClick={() => setCXAgent(agent)}>Select</Button>
                    </div>
                  )
                },
              )}
              {cxAgents.length === 0 ? (
                <div className="no-cx-agents-found">No agents found...</div>
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setSelectCXAgentDialog(false)
                  setSelectCXProjectDialog(true)
                }}
                variant="light"
                mr="12px"
              >
                Back to projects
              </Button>
            </DialogActions>
          </Dialog>

          {/* SNACKBARS */}
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={successSnackbar}
            autoHideDuration={2000}
            onClose={_closeSnackbar}
          >
            <SnackbarContent
              style={divStyle.snackbar}
              message={
                <Grid style={divStyle.snackbarGrid}>
                  <CheckCircle size="24px" />
                  <Text ml="8px">{snackbarMessage}</Text>
                  {snackbarLink && snackbarLinkLabel && (
                    <Link href={snackbarLink} isExternal={true} p={0}>
                      {snackbarLinkLabel}
                    </Link>
                  )}
                </Grid>
              }
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={errorSnackbar}
            autoHideDuration={10000}
            onClose={_closeSnackbar}
          >
            <SnackbarContent
              style={divStyle.errorSnackbar}
              message={
                <Flex direction={'column'} alignItems={'flex-start'}>
                  <Text ml="8px">{snackbarMessage}</Text>
                  {snackbarLink && snackbarLinkLabel && (
                    <Link
                      ml="8px"
                      display="block"
                      textDecoration={'underline'}
                      color="white"
                      href={snackbarLink}
                      isExternal={true}
                      p={0}
                    >
                      {snackbarLinkLabel}
                    </Link>
                  )}
                </Flex>
              }
            />
          </Snackbar>
          {/* <SaveDetector formElements={formElements} /> */}
          <SaveDetectorConfirmation
            onSave={handleSaveFormElements}
            hasUnsavedChanges={hasUnsavedChanges}
          />
        </div>
      )
    } else {
      return <LoadingSpinner />
    }
  }
  return <LoadingSpinner />
}

export default withRouter(withStyles(styles)(observer(Connect)))
