import * as React from 'react'
import { MdOutlineFeedback, MdInfoOutline } from 'react-icons/md'
import {
  FormControl,
  FormLabel,
  Input,
  Text,
  Tooltip,
  Box,
  Flex,
  Stack,
  Switch,
  Link,
  Icon,
} from '@chakra-ui/react'

import { CardCustom } from '@botcopy/ui-shared'

import { IBot } from '../../models/bots'
import { colors } from '../../hocs/withTheme'

interface FeedbackFormComponentProps {
  bot: IBot
  tooltipText: any
  toggleAskFeedback: () => Promise<void>
  toggleCollectComments: () => Promise<void>
  formValues: {
    bcAskFeedback: boolean
    bcShowCommentButton: boolean
    bcFeedbackTitle: string
    bcFeedbackThankYou: string
  }
  onTitleChange: (value: string) => void
  onThankYouChange: (value: string) => void
}

const FeedbackFormComponent: React.FC<FeedbackFormComponentProps> = ({
  bot,
  tooltipText,
  toggleAskFeedback,
  toggleCollectComments,
  formValues,
  onTitleChange,
  onThankYouChange,
}) => {
  if (!bot.theme.feedback) {
    return null
  }

  return (
    <Box mx="auto">
      <CardCustom>
        <Stack direction="column" spacing={4} width="100%">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            className="paper-title-grid"
          >
            <Stack direction="column" spacing={2} width="100%">
              <Flex alignItems="center" justifyContent="space-between">
                <Flex alignItems="center" className="feedback-title-grid">
                  <MdOutlineFeedback size="24px" />
                  <Box ml={2} className="paper-title">
                    Feedback
                  </Box>
                  <Tooltip
                    label={tooltipText.askFeedbackToggle}
                    placement="bottom"
                    p={2}
                  >
                    <Link
                      href="https://docs.botcopy.com/#/basics/components?id=feedback"
                      isExternal={true}
                      display="inline-flex"
                      p={2}
                    >
                      <Icon as={MdInfoOutline} />
                    </Link>
                  </Tooltip>
                </Flex>
                <FormControl
                  display="flex"
                  alignItems="center"
                  justifyContent={'flex-end'}
                >
                  <FormLabel marginRight={2} my={0}>
                    Enabled
                  </FormLabel>
                  <Switch
                    isChecked={formValues.bcAskFeedback}
                    onChange={toggleAskFeedback}
                    className="feedback-toggle-switch"
                  />
                </FormControl>
              </Flex>
              <Box className="feedback-paper-subtitle">
                By default, the Feedback Component is translated to all
                languages that Dialogflow supports.
                <br />
                <br />
                Any changes made here will display the Feedback component in the
                given language.
              </Box>
            </Stack>
          </Flex>

          <Box className="default-items-grid">
            <FormControl mb={4}>
              <FormLabel as={Text} textStyle="overline" casing="uppercase">
                Feedback Title
              </FormLabel>
              <Input
                placeholder="How would you rate your experience today?"
                value={formValues.bcFeedbackTitle}
                onChange={(e) => onTitleChange(e.target.value)}
                height="56px"
                width="100%"
                color={colors.lightGreyScale1200}
                border="1px solid"
                borderColor={colors.lightGreyScale800}
              />
            </FormControl>
            <FormControl>
              <FormLabel as={Text} textStyle="overline" casing="uppercase">
                Thank you message
              </FormLabel>
              <Input
                placeholder="Thank you!"
                value={formValues.bcFeedbackThankYou}
                onChange={(e) => onThankYouChange(e.target.value)}
                height="56px"
                width="100%"
                color={colors.lightGreyScale1200}
                border="1px solid"
                borderColor={colors.lightGreyScale800}
              />
            </FormControl>
            <Flex
              alignItems="center"
              mt={4}
              className="feedback-toggle-container"
            >
              <Switch
                isChecked={formValues.bcShowCommentButton}
                onChange={toggleCollectComments}
                mr={2}
              />
              <Box className="feedback-toggle-label">
                Display Add Comment button
              </Box>
            </Flex>
          </Box>
        </Stack>
      </CardCustom>
    </Box>
  )
}

export default FeedbackFormComponent
