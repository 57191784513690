import { useCallback, useEffect } from 'react'

import { usePrevious } from '@chakra-ui/react'
import { NxUserModelType, useQuery } from '@botcopy/utils-shared'

import { useStore } from '../providers'

export const useMe = () => {
  const store = useStore()
  const {
    data: meData,
    error: meError,
    loading: meLoading,
    setQuery: meSetQuery,
    query: meQuery,
  } = useQuery<{
    me: NxUserModelType
  }>()
  const previousMeLoading = usePrevious(meLoading)

  useEffect(() => {
    if (!meData && !meLoading && !meError && store.user?.accessToken) {
      meSetQuery(
        store.api.queryMe(undefined, (meSelect) =>
          meSelect._id.email.roles((roleSelect) =>
            roleSelect._id.organization((orgSelect) =>
              orgSelect._id.bots(
                (botSelect) => botSelect._id.name.defaultTransitionRouteGroup,
              ),
            ),
          ),
        ),
      )
    }
  }, [meData, meError, meLoading, meQuery, meSetQuery, store.api, store.user])

  const meFetchData = useCallback(() => {
    meSetQuery(
      store.api.queryMe(undefined, (meSelect) =>
        meSelect._id.email.roles((roleSelect) =>
          roleSelect._id.organization((orgSelect) =>
            orgSelect._id.bots(
              (botSelect) => botSelect._id.name.defaultTransitionRouteGroup,
            ),
          ),
        ),
      ),
    )
  }, [store.api, meSetQuery])

  useEffect(() => {
    if (previousMeLoading && !meLoading && meData) {
      store.user.setMe(meData.me)
    }
  }, [meData, meLoading, previousMeLoading, store.user])

  // const defaultTransitionRouteGroup =
  //   meData?.me?.roles?.[0]?.organization?.bots?.[0]?.defaultTransitionRouteGroup

  return {
    userStore: store.user,
    me: meData?.me,
    meError,
    meLoading,
    meSetQuery,
    meQuery,
    meFetchData,
    // defaultTransitionRouteGroup,
  }
}
