import * as React from 'react'
import { Center, Spinner } from '@chakra-ui/react'

const LoadingSpinner: React.FC = () => {
  return (
    <Center>
      <Spinner size="xl" mt={10} color="blue.500" />
    </Center>
  )
}

export default LoadingSpinner
