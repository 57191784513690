export const generateThemeCss = async (themeName: string) => {
  const themeCss = {
    bcBotResponseBorderColor: '',
    bcBotResponseFillColor: '',
    bcBotResponseFontColor: '',
    bcButtonFillColor: '',
    bcButtonFontColor: '',
    bcChatBackground: '',
    bcChatroomFillColor: '',
    bcFeedbackAddCommentBackground: '',
    bcFeedbackAddCommentFontColor: '',
    bcFeedbackSubmitBackground: '',
    bcFeedbackSubmitFontColor: '',
    bcFocusFillColor: '',
    bcFontType: '',
    bcGreeterAnimationName: '',
    bcGreeterAnimationSpeed: '',
    bcGreeterAnimationTimingFunction: '',
    bcGreeterBackground: '',
    bcGreeterBorder: '',
    bcGreeterBorderHoverColor: '',
    bcGreeterFontColor: '',
    bcGreeterHoverFillColor: '',
    bcGreeterHoverFontColor: '',
    bcGreeterPromptBackground: '',
    bcHeaderFillColor: '',
    bcHeaderFontColor: '',
    bcHeaderType: '',
    bcHoverFillColor: '',
    bcHoverFontColor: '',
    bcInputBarBorderColor: '',
    bcInputBarContainerFillColor: '',
    bcInputBarFillColor: '',
    bcInputBarFontColor: '',
    bcInputBarLabelColor: '',
    bcInputBarPlaceholderColor: '',
    bcInputBarStyle: '',
    bcMenuButtonColor: '',
    bcPrivacyPolicyAcceptBorderColor: '',
    bcPrivacyPolicyAcceptFillColor: '',
    bcPrivacyPolicyAcceptFontColor: '',
    bcPrivacyPolicyDeclineBorderColor: '',
    bcPrivacyPolicyDeclineFillColor: '',
    bcPrivacyPolicyDeclineFontColor: '',
    bcPrivacyPolicyDescriptionFontColor: '',
    bcPromptCloseButtonColor: '',
    bcPromptSuggestionFillColor: '',
    bcPromptSuggestionFontColor: '',
    bcPromptSuggestionHoverFillColor: '',
    bcPromptSuggestionHoverFontColor: '',
    bcSendActiveColor: '',
    bcTypingAnimationDots: '',
    bcTypingAnimationFill: '',
    bcUiByBotcopyCircleColor: '',
    bcUiByBotcopyImg: '',
    bcUiByBotcopyToggleColor: '',
    bcUserBoxColor: '',
    bcUserResponseColor: '',
  }

  switch (themeName) {
    // tslint:disable
    case 'botcopy':
      themeCss.bcGreeterBackground =
        themeCss.bcGreeterBorder =
        themeCss.bcGreeterPromptBackground =
        themeCss.bcGreeterBorderHoverColor =
        themeCss.bcHeaderFillColor =
        themeCss.bcMenuButtonColor =
        themeCss.bcUserBoxColor =
        themeCss.bcTypingAnimationFill =
        themeCss.bcUiByBotcopyToggleColor =
        themeCss.bcFeedbackSubmitBackground =
        themeCss.bcFeedbackAddCommentFontColor =
        themeCss.bcPrivacyPolicyAcceptFillColor =
        themeCss.bcPrivacyPolicyAcceptBorderColor =
        themeCss.bcSendActiveColor =
          '#4b60cc'
      themeCss.bcGreeterFontColor =
        themeCss.bcGreeterHoverFontColor =
        themeCss.bcHeaderFontColor =
        themeCss.bcBotResponseFillColor =
        themeCss.bcBotResponseBorderColor =
        themeCss.bcButtonFontColor =
        themeCss.bcPromptSuggestionFontColor =
        themeCss.bcUserResponseColor =
        themeCss.bcTypingAnimationDots =
        themeCss.bcPromptSuggestionHoverFontColor =
        themeCss.bcHoverFontColor =
        themeCss.bcFeedbackSubmitFontColor =
        themeCss.bcFeedbackAddCommentBackground =
        themeCss.bcPrivacyPolicyAcceptFontColor =
        themeCss.bcPrivacyPolicyDeclineBorderColor =
        themeCss.bcPrivacyPolicyDeclineFillColor =
        themeCss.bcInputBarContainerFillColor =
        themeCss.bcInputBarFillColor =
          '#FCFCFC'
      themeCss.bcBotResponseFontColor =
        themeCss.bcButtonFillColor =
        themeCss.bcPromptSuggestionFillColor =
        themeCss.bcInputBarLabelColor =
          '#0e0e0e'
      themeCss.bcPrivacyPolicyDeclineFontColor =
        themeCss.bcPrivacyPolicyDescriptionFontColor = '#212121'
      themeCss.bcFocusFillColor =
        themeCss.bcHoverFillColor =
        themeCss.bcPromptSuggestionHoverFillColor =
        themeCss.bcGreeterHoverFillColor =
          '#2f3c80'
      themeCss.bcFontType = 'Open Sans'
      themeCss.bcGreeterAnimationName = 'slide'
      themeCss.bcGreeterAnimationSpeed = '300ms'
      themeCss.bcGreeterAnimationTimingFunction = 'ease-in-out'
      themeCss.bcUiByBotcopyCircleColor = '#FCFCFC'
      themeCss.bcInputBarStyle = 'minimal'
      themeCss.bcInputBarBorderColor = '#e6e6e6'
      themeCss.bcUiByBotcopyImg = 'blueLogo'
      themeCss.bcHeaderType = 'rich'
      themeCss.bcChatroomFillColor = themeCss.bcChatBackground = '#ededed'
      themeCss.bcInputBarFontColor = '#000'
      themeCss.bcInputBarPlaceholderColor = themeCss.bcPromptCloseButtonColor =
        '#757575'
      break

    case 'classic':
      themeCss.bcGreeterBackground =
        themeCss.bcGreeterFontColor =
        themeCss.bcHeaderFillColor =
        themeCss.bcBotResponseFontColor =
        themeCss.bcMenuButtonColor =
        themeCss.bcButtonFillColor =
        themeCss.bcPromptSuggestionFillColor =
        themeCss.bcUserBoxColor =
        themeCss.bcTypingAnimationDots =
        themeCss.bcUiByBotcopyToggleColor =
        themeCss.bcFeedbackSubmitBackground =
        themeCss.bcFeedbackAddCommentFontColor =
        themeCss.bcPrivacyPolicyAcceptFillColor =
        themeCss.bcPrivacyPolicyAcceptBorderColor =
        themeCss.bcInputBarLabelColor =
        themeCss.bcSendActiveColor =
          '#5C5DA6'
      themeCss.bcGreeterPromptBackground =
        themeCss.bcGreeterBorder =
        themeCss.bcGreeterHoverFontColor =
        themeCss.bcHeaderFontColor =
        themeCss.bcBotResponseFillColor =
        themeCss.bcBotResponseBorderColor =
        themeCss.bcButtonFontColor =
        themeCss.bcPromptSuggestionFontColor =
        themeCss.bcUserResponseColor =
        themeCss.bcHoverFontColor =
        themeCss.bcChatBackground =
        themeCss.bcPromptSuggestionHoverFontColor =
        themeCss.bcPromptSuggestionFontColor =
        themeCss.bcPrivacyPolicyDeclineFillColor =
          '#fff'
      themeCss.bcBotResponseFillColor =
        themeCss.bcBotResponseBorderColor =
        themeCss.bcFeedbackSubmitFontColor =
        themeCss.bcFeedbackAddCommentBackground =
        themeCss.bcPrivacyPolicyAcceptFontColor =
        themeCss.bcPrivacyPolicyDeclineBorderColor =
          '#f2f2f2'
      themeCss.bcFocusFillColor =
        themeCss.bcHoverFillColor =
        themeCss.bcGreeterHoverFillColor =
        themeCss.bcGreeterBorderHoverColor =
        themeCss.bcPromptSuggestionHoverFillColor =
          '#404f73'
      themeCss.bcPrivacyPolicyDeclineFontColor =
        themeCss.bcPrivacyPolicyDescriptionFontColor = '#212121'
      themeCss.bcTypingAnimationFill = '#f2f2f2'
      themeCss.bcFontType = 'Open Sans'
      themeCss.bcGreeterAnimationName = 'centerExpand'
      themeCss.bcGreeterAnimationSpeed = '180ms'
      themeCss.bcGreeterAnimationTimingFunction = 'ease-in'
      themeCss.bcUiByBotcopyCircleColor = '#FFFFFF'
      themeCss.bcInputBarBorderColor = '#e6e6e6'
      themeCss.bcInputBarStyle = 'classic'
      themeCss.bcUiByBotcopyImg = 'purpleLogo'
      themeCss.bcHeaderType = 'rich'
      themeCss.bcChatroomFillColor =
        themeCss.bcInputBarContainerFillColor =
        themeCss.bcInputBarFillColor =
          '#fff'
      themeCss.bcInputBarFontColor = '#000'
      themeCss.bcInputBarPlaceholderColor = themeCss.bcPromptCloseButtonColor =
        '#757575'
      break

    case 'blueberry':
      themeCss.bcGreeterBackground =
        themeCss.bcGreeterPromptBackground =
        themeCss.bcGreeterBorder =
        themeCss.bcPromptSuggestionHoverFillColor =
        themeCss.bcGreeterBorderHoverColor =
        themeCss.bcGreeterHoverFontColor =
        themeCss.bcHeaderFillColor =
        themeCss.bcBotResponseFontColor =
        themeCss.bcMenuButtonColor =
        themeCss.bcButtonFillColor =
        themeCss.bcUserBoxColor =
        themeCss.bcTypingAnimationFill =
        themeCss.bcChatBackground =
        themeCss.bcUiByBotcopyToggleColor =
        themeCss.bcFeedbackSubmitBackground =
        themeCss.bcFeedbackAddCommentFontColor =
        themeCss.bcPrivacyPolicyAcceptFillColor =
        themeCss.bcPrivacyPolicyAcceptBorderColor =
        themeCss.bcInputBarLabelColor =
        themeCss.bcSendActiveColor =
          '#0960bd'
      themeCss.bcGreeterFontColor =
        themeCss.bcGreeterHoverFillColor =
        themeCss.bcHeaderFontColor =
        themeCss.bcBotResponseFillColor =
        themeCss.bcBotResponseBorderColor =
        themeCss.bcButtonFontColor =
        themeCss.bcPromptSuggestionFontColor =
        themeCss.bcUserResponseColor =
        themeCss.bcTypingAnimationDots =
        themeCss.bcPromptSuggestionHoverFontColor =
        themeCss.bcHoverFontColor =
        themeCss.bcFeedbackSubmitFontColor =
        themeCss.bcFeedbackAddCommentBackground =
        themeCss.bcPrivacyPolicyAcceptFontColor =
        themeCss.bcPrivacyPolicyDeclineBorderColor =
        themeCss.bcPrivacyPolicyDeclineFillColor =
          '#eeeeee'

      themeCss.bcPrivacyPolicyDeclineFontColor =
        themeCss.bcPrivacyPolicyDescriptionFontColor = '#212121'
      themeCss.bcFocusFillColor =
        themeCss.bcHoverFillColor =
        themeCss.bcPromptSuggestionFillColor =
          '#011f3f'
      themeCss.bcFontType = 'Open Sans'
      themeCss.bcGreeterAnimationName = 'slide'
      themeCss.bcGreeterAnimationSpeed = '300ms'
      themeCss.bcGreeterAnimationTimingFunction = 'ease-in-out'
      themeCss.bcUiByBotcopyCircleColor = '#FFFFFF'
      themeCss.bcInputBarStyle = 'minimal'
      themeCss.bcInputBarBorderColor = '#e6e6e6'
      themeCss.bcUiByBotcopyImg = 'blueLogo'
      themeCss.bcHeaderType = 'rich'
      themeCss.bcChatroomFillColor =
        themeCss.bcInputBarContainerFillColor =
        themeCss.bcInputBarFillColor =
          '#fff'
      themeCss.bcInputBarFontColor = '#000'
      themeCss.bcInputBarPlaceholderColor = themeCss.bcPromptCloseButtonColor =
        '#757575'
      break

    case 'modern':
      themeCss.bcGreeterBackground =
        themeCss.bcGreeterPromptBackground =
        themeCss.bcGreeterBorder =
        themeCss.bcGreeterHoverFontColor =
        themeCss.bcHeaderFillColor =
        themeCss.bcPromptSuggestionFontColor =
        themeCss.bcBotResponseFontColor =
        themeCss.bcMenuButtonColor =
        themeCss.bcButtonFillColor =
        themeCss.bcPromptSuggestionHoverFillColor =
        themeCss.bcUserBoxColor =
        themeCss.bcTypingAnimationFill =
        themeCss.bcHoverFontColor =
        themeCss.bcChatBackground =
        themeCss.bcUiByBotcopyCircleColor =
        themeCss.bcFeedbackSubmitBackground =
        themeCss.bcFeedbackAddCommentFontColor =
        themeCss.bcPrivacyPolicyAcceptFillColor =
        themeCss.bcPrivacyPolicyAcceptBorderColor =
        themeCss.bcInputBarLabelColor =
        themeCss.bcSendActiveColor =
          '#0a2740'
      themeCss.bcFeedbackSubmitFontColor =
        themeCss.bcFeedbackAddCommentBackground =
        themeCss.bcGreeterFontColor =
        themeCss.bcHeaderFontColor =
        themeCss.bcBotResponseFillColor =
        themeCss.bcBotResponseBorderColor =
        themeCss.bcPromptSuggestionHoverFontColor =
        themeCss.bcButtonFontColor =
        themeCss.bcUserResponseColor =
        themeCss.bcFeedbackSubmitFontColor =
        themeCss.bcFeedbackAddCommentBackground =
        themeCss.bcPrivacyPolicyAcceptFontColor =
        themeCss.bcPrivacyPolicyDeclineBorderColor =
        themeCss.bcPrivacyPolicyDeclineFillColor =
          '#eeeeee'

      themeCss.bcGreeterHoverFillColor =
        themeCss.bcGreeterBorderHoverColor =
        themeCss.bcTypingAnimationDots =
        themeCss.bcFocusFillColor =
        themeCss.bcHoverFillColor =
        themeCss.bcPromptSuggestionFillColor =
          '#dec79b'
      themeCss.bcPrivacyPolicyDeclineFontColor =
        themeCss.bcPrivacyPolicyDescriptionFontColor = '#212121'
      themeCss.bcFontType = 'Roboto'
      themeCss.bcGreeterAnimationName = 'openEye'
      themeCss.bcGreeterAnimationSpeed = '300ms'
      themeCss.bcGreeterAnimationTimingFunction = 'ease-out'
      themeCss.bcUiByBotcopyToggleColor = '#767776'
      themeCss.bcInputBarStyle = 'minimal'
      themeCss.bcInputBarBorderColor = '#e6e6e6'
      themeCss.bcUiByBotcopyImg = 'logo'
      themeCss.bcHeaderType = 'rich'
      themeCss.bcChatroomFillColor =
        themeCss.bcInputBarContainerFillColor =
        themeCss.bcInputBarFillColor =
          '#fff'
      themeCss.bcInputBarFontColor = '#000'
      themeCss.bcInputBarPlaceholderColor = themeCss.bcPromptCloseButtonColor =
        '#757575'
      break

    case 'roxanne':
      themeCss.bcGreeterBackground =
        themeCss.bcGreeterPromptBackground =
        themeCss.bcGreeterBorder =
        themeCss.bcGreeterBorderHoverColor =
        themeCss.bcGreeterHoverFontColor =
        themeCss.bcHeaderFillColor =
        themeCss.bcBotResponseFontColor =
        themeCss.bcPromptSuggestionFontColor =
        themeCss.bcMenuButtonColor =
        themeCss.bcButtonFillColor =
        themeCss.bcPromptSuggestionHoverFillColor =
        themeCss.bcUserBoxColor =
        themeCss.bcTypingAnimationFill =
        themeCss.bcHoverFontColor =
        themeCss.bcUiByBotcopyCircleColor =
        themeCss.bcFeedbackSubmitBackground =
        themeCss.bcFeedbackAddCommentFontColor =
        themeCss.bcPrivacyPolicyAcceptFillColor =
        themeCss.bcPrivacyPolicyAcceptBorderColor =
        themeCss.bcInputBarLabelColor =
        themeCss.bcSendActiveColor =
          '#900048'
      themeCss.bcGreeterFontColor =
        themeCss.bcHeaderFontColor =
        themeCss.bcBotResponseFillColor =
        themeCss.bcBotResponseBorderColor =
        themeCss.bcButtonFontColor =
        themeCss.bcUserResponseColor =
        themeCss.bcFeedbackSubmitFontColor =
        themeCss.bcFeedbackAddCommentBackground =
        themeCss.bcPrivacyPolicyAcceptFontColor =
        themeCss.bcPrivacyPolicyDeclineBorderColor =
        themeCss.bcPrivacyPolicyDeclineFillColor =
          '#f2f2f2'
      themeCss.bcFocusFillColor =
        themeCss.bcGreeterHoverFillColor =
        themeCss.bcTypingAnimationDots =
        themeCss.bcHoverFillColor =
        themeCss.bcPromptSuggestionFillColor =
        themeCss.bcPromptSuggestionHoverFontColor =
          '#f2eee0'
      themeCss.bcPrivacyPolicyDeclineFontColor =
        themeCss.bcPrivacyPolicyDescriptionFontColor = '#212121'
      themeCss.bcChatBackground = '#fff'
      themeCss.bcFontType = 'Arial'
      themeCss.bcGreeterAnimationName = 'rightCornerExpand'
      themeCss.bcGreeterAnimationSpeed = '180ms'
      themeCss.bcGreeterAnimationTimingFunction = 'ease'
      themeCss.bcUiByBotcopyToggleColor = '#767776'
      themeCss.bcInputBarStyle = 'minimal'
      themeCss.bcInputBarBorderColor = '#e6e6e6'
      themeCss.bcUiByBotcopyImg = 'logo'
      themeCss.bcHeaderType = 'rich'
      themeCss.bcChatroomFillColor =
        themeCss.bcInputBarContainerFillColor =
        themeCss.bcInputBarFillColor =
          '#fff'
      themeCss.bcInputBarFontColor = '#000'
      themeCss.bcInputBarPlaceholderColor = themeCss.bcPromptCloseButtonColor =
        '#757575'
      break

    case 'bubblegum':
      themeCss.bcGreeterBackground =
        themeCss.bcGreeterPromptBackground =
        themeCss.bcGreeterBorder =
        themeCss.bcGreeterBorderHoverColor =
        themeCss.bcHeaderFillColor =
        themeCss.bcBotResponseFontColor =
        themeCss.bcMenuButtonColor =
        themeCss.bcButtonFillColor =
        themeCss.bcPromptSuggestionFillColor =
        themeCss.bcUserBoxColor =
        themeCss.bcUiByBotcopyCircleColor =
        themeCss.bcFeedbackSubmitBackground =
        themeCss.bcFeedbackAddCommentFontColor =
        themeCss.bcPrivacyPolicyAcceptFillColor =
        themeCss.bcPrivacyPolicyAcceptBorderColor =
        themeCss.bcInputBarLabelColor =
        themeCss.bcSendActiveColor =
          '#1a847b'
      themeCss.bcGreeterFontColor =
        themeCss.bcGreeterHoverFontColor =
        themeCss.bcHeaderFontColor =
        themeCss.bcBotResponseFillColor =
        themeCss.bcBotResponseBorderColor =
        themeCss.bcButtonFontColor =
        themeCss.bcPromptSuggestionFontColor =
        themeCss.bcUserResponseColor =
        themeCss.bcTypingAnimationFill =
        themeCss.bcPromptSuggestionHoverFontColor =
        themeCss.bcHoverFontColor =
        themeCss.bcFeedbackSubmitFontColor =
        themeCss.bcFeedbackAddCommentBackground =
        themeCss.bcPrivacyPolicyAcceptFontColor =
        themeCss.bcPrivacyPolicyDeclineBorderColor =
        themeCss.bcPrivacyPolicyDeclineFillColor =
          '#f3f3f3'
      themeCss.bcFocusFillColor =
        themeCss.bcGreeterHoverFillColor =
        themeCss.bcTypingAnimationDots =
        themeCss.bcHoverFillColor =
        themeCss.bcPromptSuggestionHoverFillColor =
          '#e95280'
      themeCss.bcPrivacyPolicyDeclineFontColor =
        themeCss.bcPrivacyPolicyDescriptionFontColor = '#212121'
      themeCss.bcChatBackground = '#fff'
      themeCss.bcFontType = 'Roboto'
      themeCss.bcGreeterAnimationName = 'mold'
      themeCss.bcGreeterAnimationSpeed = '600ms'
      themeCss.bcGreeterAnimationTimingFunction = 'ease-in'
      themeCss.bcUiByBotcopyToggleColor = '#767776'
      themeCss.bcInputBarBorderColor = '#e6e6e6'
      themeCss.bcInputBarStyle = 'classic'
      themeCss.bcUiByBotcopyImg = 'logo'
      themeCss.bcHeaderType = 'rich'
      themeCss.bcChatroomFillColor =
        themeCss.bcInputBarContainerFillColor =
        themeCss.bcInputBarFillColor =
          '#fff'
      themeCss.bcInputBarFontColor = '#000'
      themeCss.bcInputBarPlaceholderColor = themeCss.bcPromptCloseButtonColor =
        '#757575'
      break

    case 'flux':
      themeCss.bcGreeterBackground =
        themeCss.bcGreeterPromptBackground =
        themeCss.bcGreeterBorder =
        themeCss.bcHeaderFillColor =
        themeCss.bcBotResponseBorderColor =
        themeCss.bcBotResponseFontColor =
        themeCss.bcMenuButtonColor =
        themeCss.bcButtonFillColor =
        themeCss.bcPromptSuggestionHoverFillColor =
        themeCss.bcUserBoxColor =
        themeCss.bcTypingAnimationFill =
        themeCss.bcUiByBotcopyToggleColor =
        themeCss.bcFeedbackSubmitBackground =
        themeCss.bcFeedbackAddCommentFontColor =
        themeCss.bcPrivacyPolicyAcceptFillColor =
        themeCss.bcPrivacyPolicyAcceptBorderColor =
        themeCss.bcInputBarLabelColor =
        themeCss.bcSendActiveColor =
          '#240041'
      themeCss.bcGreeterFontColor =
        themeCss.bcGreeterHoverFontColor =
        themeCss.bcHeaderFontColor =
        themeCss.bcBotResponseFillColor =
        themeCss.bcButtonFontColor =
        themeCss.bcPromptSuggestionFontColor =
        themeCss.bcUserResponseColor =
        themeCss.bcPromptSuggestionHoverFontColor =
        themeCss.bcHoverFontColor =
        themeCss.bcChatBackground =
        themeCss.bcFeedbackSubmitFontColor =
        themeCss.bcFeedbackAddCommentBackground =
        themeCss.bcPrivacyPolicyAcceptFontColor =
        themeCss.bcPrivacyPolicyDeclineBorderColor =
        themeCss.bcPrivacyPolicyDeclineFillColor =
          '#fff'
      themeCss.bcGreeterHoverFillColor =
        themeCss.bcGreeterBorderHoverColor =
        themeCss.bcTypingAnimationDots =
        themeCss.bcFocusFillColor =
        themeCss.bcHoverFillColor =
        themeCss.bcPromptSuggestionFillColor =
          '#ff8260'
      themeCss.bcPrivacyPolicyDeclineFontColor =
        themeCss.bcPrivacyPolicyDescriptionFontColor = '#212121'
      themeCss.bcFontType = 'Open Sans'
      themeCss.bcGreeterAnimationName = 'sideEye'
      themeCss.bcGreeterAnimationSpeed = '300ms'
      themeCss.bcGreeterAnimationTimingFunction = 'linear'
      themeCss.bcUiByBotcopyCircleColor = '#FFFFFF'
      themeCss.bcInputBarBorderColor = '#e6e6e6'
      themeCss.bcInputBarStyle = 'minimal'
      themeCss.bcUiByBotcopyImg = 'darkBlueLogo'
      themeCss.bcHeaderType = 'classic'
      themeCss.bcChatroomFillColor =
        themeCss.bcInputBarContainerFillColor =
        themeCss.bcInputBarFillColor =
          '#fff'
      themeCss.bcInputBarFontColor = '#000'
      themeCss.bcInputBarPlaceholderColor = themeCss.bcPromptCloseButtonColor =
        '#757575'
      break

    case 'noir':
      themeCss.bcGreeterBackground =
        themeCss.bcGreeterBorder =
        themeCss.bcHeaderFillColor =
        themeCss.bcBotResponseBorderColor =
        themeCss.bcBotResponseFontColor =
        themeCss.bcMenuButtonColor =
        themeCss.bcButtonFillColor =
        themeCss.bcPromptSuggestionFillColor =
        themeCss.bcUserBoxColor =
        themeCss.bcGreeterFontColor =
        themeCss.bcUiByBotcopyCircleColor =
        themeCss.bcFeedbackSubmitBackground =
        themeCss.bcFeedbackAddCommentFontColor =
        themeCss.bcPrivacyPolicyAcceptFillColor =
        themeCss.bcPrivacyPolicyAcceptBorderColor =
        themeCss.bcInputBarLabelColor =
        themeCss.bcSendActiveColor =
          '#222127'
      themeCss.bcGreeterPromptBackground =
        themeCss.bcGreeterHoverFillColor =
        themeCss.bcGreeterBorderHoverColor =
        themeCss.bcHeaderFontColor =
        themeCss.bcBotResponseFillColor =
        themeCss.bcButtonFontColor =
        themeCss.bcPromptSuggestionFontColor =
        themeCss.bcUserResponseColor =
        themeCss.bcTypingAnimationFill =
        themeCss.bcPromptSuggestionHoverFontColor =
        themeCss.bcHoverFontColor =
        themeCss.bcChatBackground =
        themeCss.bcFeedbackSubmitFontColor =
        themeCss.bcFeedbackAddCommentBackground =
        themeCss.bcPrivacyPolicyAcceptFontColor =
        themeCss.bcPrivacyPolicyDeclineBorderColor =
        themeCss.bcPrivacyPolicyDeclineFillColor =
          '#fff'
      themeCss.bcGreeterHoverFontColor =
        themeCss.bcTypingAnimationDots =
        themeCss.bcFocusFillColor =
        themeCss.bcHoverFillColor =
        themeCss.bcPromptSuggestionHoverFillColor =
          '#3d4343'
      themeCss.bcPrivacyPolicyDeclineFontColor =
        themeCss.bcPrivacyPolicyDescriptionFontColor = '#212121'
      themeCss.bcFontType = 'Trebuchet MS'
      themeCss.bcGreeterAnimationName = 'expandOutIn'
      themeCss.bcGreeterAnimationSpeed = '300ms'
      themeCss.bcGreeterAnimationTimingFunction = 'ease-in-out'
      themeCss.bcUiByBotcopyToggleColor = '#767776'
      themeCss.bcInputBarStyle = 'minimal'
      themeCss.bcInputBarBorderColor = '#e6e6e6'
      themeCss.bcUiByBotcopyImg = 'logo'
      themeCss.bcHeaderType = 'rich'
      themeCss.bcChatroomFillColor =
        themeCss.bcInputBarContainerFillColor =
        themeCss.bcInputBarFillColor =
          '#fff'
      themeCss.bcInputBarFontColor = '#000'
      themeCss.bcInputBarPlaceholderColor = themeCss.bcPromptCloseButtonColor =
        '#757575'
      break
  }
  return themeCss
}
