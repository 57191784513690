import React from 'react'
import { Box, WrapItem, Text } from '@chakra-ui/react' // Assuming you're using Chakra UI
import { styles } from '../utils/styles'

interface ThemeTileProps {
  themeName: string
  bgColor1: string
  bgColor2: string
  bgColor3: string
  onClick(): void
}

const ThemeTile: React.FC<ThemeTileProps> = ({
  themeName,
  bgColor1,
  bgColor2,
  bgColor3,
  onClick,
}) => (
  <WrapItem>
    <Box style={styles.defaultStylePreview} onClick={onClick}>
      <Box style={styles.themeTile}>
        <Box height="60%" backgroundColor={bgColor1} />
        <Box height="20%" backgroundColor={bgColor2} />
        <Box height="20%" backgroundColor={bgColor3} />
      </Box>
      <Text style={styles.defaultStyleTitle} textTransform="capitalize">
        {themeName}
      </Text>
    </Box>
  </WrapItem>
)

export default ThemeTile
