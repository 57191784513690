import axios from 'axios'

export const authenticateDialogflowES = async () => {
  const { data } = await axios.post(`/platforms/dialogflow-es/authenticate`)
  return data
}

export const getActiveProjects = async (
  pageToken?: string,
): Promise<{ projects: unknown[]; nextPageToken: string | undefined }> => {
  const { data } = await axios.get(
    `/platforms/dialogflow-cx/list-active-projects`,
    {
      params: {
        pageToken,
      },
    },
  )
  return data
}

interface IGetCXAgentsPayload {
  projectId: string
  botId: string
}

export const fetchCXAgents = async (payload: IGetCXAgentsPayload) => {
  const { data } = await axios.post(
    `/platforms/dialogflow-cx/list-project-agents`,
    payload,
  )
  return data
}
