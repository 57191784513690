import React, { useCallback, useEffect, useState } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { dialogflowLanguages } from 'src/utils/languages'
import {
  CardCustom,
  ConfirmationModal,
  useLocalizedEditor,
} from '@botcopy/ui-shared'
import { debounce } from 'lodash-es'

export interface LocalizedHeaders {
  [languageCode: string]: { languageCode?: string; main?: string }
}

interface BotMenuHeaderProps {
  onHeaderChange: (localizedHeaders: LocalizedHeaders) => void
  headers: LocalizedHeaders
  isOpen?: boolean
  onClose?: () => void
}

const defaultLanguageCode = 'en'

const BotMenuHeader: React.FC<BotMenuHeaderProps> = ({
  onClose,
  onHeaderChange,
  headers,
}) => {
  const {
    currentContent,
    selectedLanguage,
    localizedContent,

    changeLanguage,
    hasTranslation,
  } = useLocalizedEditor<any>({
    localizedContent: headers,
    defaultLanguage: defaultLanguageCode,
  })

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [textAreaValue, setTextAreaValue] = useState(currentContent?.main || '')

  // onHeaderChange is a bit expensive to call on each keystroke
  // calling 300ms after the user stopped typing
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnHeaderChange = useCallback(
    debounce((updatedContent) => {
      onHeaderChange(updatedContent)
    }, 300),
    [],
  )

  useEffect(() => {
    // Keep the text in sync when the prop changes
    setTextAreaValue(currentContent?.main || '')
  }, [currentContent?.main])

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    onClose?.()
  }

  const handleConfirm = () => {
    handleCloseModal()
  }

  const onTextAreaChange = (e: any) => {
    const newValue = e.target.value
    setTextAreaValue(newValue) // Update the text instantly for the UI

    localizedContent[selectedLanguage].languageCode = selectedLanguage
    localizedContent[selectedLanguage].main = newValue

    debouncedOnHeaderChange(localizedContent) // Call the debounced function
  }

  return (
    <CardCustom>
      <Button onClick={handleOpenModal}>Set & Localize Header</Button>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        header="Set & Localize Header"
        content={
          <>
            <FormLabel>Language</FormLabel>
            <Menu>
              <MenuButton
                as={Button}
                variant="outline"
                width="100%"
                textAlign="left"
                rightIcon={<ChevronDownIcon />}
              >
                {/* This is expensive to look through each item in the array. */}
                {dialogflowLanguages.find(
                  (dialogflowLanguage) =>
                    dialogflowLanguage.code === selectedLanguage,
                )?.name || 'Select Language'}
              </MenuButton>
              {/* The languages will go past the page height, so we need to limit the height of the menu */}
              <MenuList maxHeight="260px" overflowX="auto">
                {dialogflowLanguages.map((dialogflowLanguage) => (
                  <MenuItem
                    key={dialogflowLanguage.code}
                    onClick={() => changeLanguage(dialogflowLanguage.code)}
                  >
                    <VStack width="100%" alignItems="flex-start">
                      <Text>{dialogflowLanguage.name}</Text>
                      {hasTranslation(dialogflowLanguage.code) &&
                        localizedContent[dialogflowLanguage.code]?.main && (
                          <Text color="gray.500">
                            {localizedContent[dialogflowLanguage.code]?.main}
                          </Text>
                        )}
                    </VStack>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            <FormControl mt={6}>
              <FormLabel htmlFor="headerText">Header Text</FormLabel>
              <Textarea
                id="headerText"
                minHeight="15vh"
                maxHeight="70vh"
                placeholder="Enter header text"
                value={textAreaValue}
                onChange={onTextAreaChange}
              />
            </FormControl>
          </>
        }
        confirmText="Done Editing"
        onConfirm={handleConfirm}
      />
    </CardCustom>
  )
}

export default BotMenuHeader
