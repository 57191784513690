import React, { useEffect, useState } from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react'
import { CardCustom } from '@botcopy/ui-shared'
import { BotModelType } from '@botcopy/utils-shared'
import { useBotPublic, UseBotPublicDataType } from 'src/hooks/useBotPublic'
import { observer } from 'mobx-react'

interface AddMenuItemsProps {
  availableBots: BotModelType[]
  handleAddMenuItem: (
    bot: UseBotPublicDataType,
    options?: { external: boolean },
  ) => void
}

const AddMenuItems: React.FC<AddMenuItemsProps> = ({
  availableBots,
  handleAddMenuItem,
}) => {
  const { botPublicQuery, botPublicFetchData, botPublicLoading } =
    useBotPublic()

  const [externalBotIdError, setExternalBotIdError] = useState('')
  const [externalBotIdInput, setExternalBotIdInput] = useState('')

  useEffect(() => {
    if (botPublicQuery) {
      botPublicQuery.promise.then(
        (botPublicQueryData) => {
          setExternalBotIdInput('')
          handleAddMenuItem(botPublicQueryData.botPublic, { external: true })
        },
        (error) => {
          setExternalBotIdError('Bot not found')
        },
      )
    }
  }, [botPublicQuery, handleAddMenuItem])

  const validateExternalBotId = (id: string): boolean => {
    if (!id.trim()) {
      setExternalBotIdError('Bot ID is required')
      return false
    }

    // Check if the ID is a valid MongoDB ObjectId (24 hex characters)
    if (!/^[a-fA-F0-9]{24}$/.test(id)) {
      setExternalBotIdError('Bot ID format is invalid')
      return false
    }

    setExternalBotIdError('')
    return true
  }

  return (
    <CardCustom>
      <Heading size="md" mb={4}>
        Add Menu Items
      </Heading>
      <VStack
        maxHeight={'400px'}
        overflowY="scroll"
        width="100%"
        maxWidth="430px"
      >
        {availableBots.map((bot) => (
          <Flex
            key={bot._id}
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <Text maxWidth="320px" isTruncated={true}>
              {bot.name}
            </Text>
            <Button onClick={() => handleAddMenuItem(bot)}>Add</Button>
          </Flex>
        ))}
      </VStack>
      <form
        onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault()

          const formData = new FormData(e.target as HTMLFormElement)
          const botId = formData.get('botId')

          if (validateExternalBotId(botId as string)) {
            await botPublicFetchData(botId as string)
          }
        }}
      >
        <FormControl mt={6} isInvalid={!!externalBotIdError}>
          <FormLabel>Add external bot by Botcopy bot ID</FormLabel>
          <HStack>
            <Input
              name="botId"
              placeholder="i.e. 6548d5f025abf8129b5abb61"
              maxLength={24}
              value={externalBotIdInput}
              onChange={(e) => setExternalBotIdInput(e.target.value)}
            />
            <Button isLoading={botPublicLoading} type="submit">
              Add
            </Button>
          </HStack>
          <FormErrorMessage>{externalBotIdError}</FormErrorMessage>
        </FormControl>
      </form>
    </CardCustom>
  )
}

export default observer(AddMenuItems)
