/**
 * Validates a phone number format
 * @param phone - The phone number to validate
 * @returns True if the phone number is valid or empty, false otherwise
 */
export const validatePhone = (phone: string): boolean => {
  if (!phone) return true
  const phoneRegex = /^(\+\d{1,3}[\s-.]?)?\(?\d{3}\)?[\s-.]?\d{3}[\s-.]?\d{4}$/
  return phoneRegex.test(phone)
}
