import './login.css'

import mixpanel from 'mixpanel-browser'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { withAuth0, WithAuth0Props, User } from '@auth0/auth0-react'
import { Link as ReactRouterLink, Navigate } from 'react-router-dom'
import trackUserEvent from 'src/components/trackEvents'
import { Events } from 'src/utils/gtm'

import { Button, Link } from '@chakra-ui/react'
import { Grid, StyleRulesCallback, withStyles } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import { colors } from '../hocs/withTheme'
import { RootStore } from '../models/root-store/root.store'
import { renderBillboard } from './onboarding/billboard'
import { RouteProps, withRouter } from 'src/utils/withRouter'

const divStyle = {
  or: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 450,
    alignText: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 5,
    marginTop: 10,
  },
  paper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  billboard: {
    backgroundColor: colors.darkGreyBlue,
    width: '100%',
    padding: '40px 70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  billboardHeader: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 650,
    fontSize: '3em',
    color: '#ffffff',
    display: 'flex',
    alignText: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '6em',
  },
  billboardBody: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 550,
    fontSize: '1.5em',
    color: '#ffffff',
    display: 'flex',
    flex: 1,
    alignText: 'center',
    justifyContent: 'center',
    paddingTop: 25,
  },

  login: {
    backgroundColor: '#5C5DA6',
    color: '#F0F3F6',
    width: 350,
  },
}

const styles: StyleRulesCallback = (theme) => ({
  formControl: {
    margin: theme.spacing.unit,
    width: 350,
  },
})

interface ILoginProps extends WithAuth0Props, RouteProps {
  classes: any
  store?: RootStore
}

interface ILoginState {
  cookiesBlocked: boolean
}

@inject('store')
@observer
class Login extends React.Component<ILoginProps, ILoginState, {}> {
  // public state = {
  //   hasError: false,
  //   email: '',
  //   encryptedPassword: '',
  //   password: '',
  //   cookiesBlocked: false
  // }

  constructor(props: ILoginProps) {
    super(props)
    this.state = {
      cookiesBlocked: false,
    }
  }

  public componentDidMount(): void {
    const googleCypress = localStorage.getItem('googleCypress')
    if (googleCypress) {
      // this.props.store.singleSignOn(JSON.parse(googleCypress))
    }

    const queryParams = new URLSearchParams(this.props.location?.search || '')
    const logoutRequested = queryParams.get('logout') === 't'

    if (logoutRequested) {
      this.props.auth0.logout({
        logoutParams: {
          returnTo: window.location.origin + window.location.pathname,
        },
      })
    }
  }

  componentDidUpdate(prevProps: ILoginProps) {
    // Check if the specific prop you're interested in has changed
    if (
      !prevProps.auth0.isLoading &&
      this.props.auth0.isAuthenticated &&
      this.props.auth0.user
    ) {
      // Run your handler here
      this.responseGoogle(this.props.auth0.user)
    }
  }

  public render() {
    const {
      auth0: { isLoading, isAuthenticated },
    } = this.props

    if (isLoading) {
      return null
    }

    if (isAuthenticated) {
      return <Navigate to="/" />
    }

    return (
      <Grid
        container={true}
        style={{
          height: '100vh',
        }}
      >
        {this.state.cookiesBlocked ? (
          <Grid container={true} className="error-alert" direction="row">
            <ErrorOutlineIcon className="error-icon" />
            <Grid item={true}>
              <div className="error-title">
                We've detected you are blocking third party cookies.
              </div>
              <div className="error-text">
                To login you'll need to either add portal.botcopy.com to your
                allowed cookies, or allow all cookies in your browser's
                settings.
              </div>
              <div className="error-instruction">
                1. Settings {'>'} Cookies and other site data {'>'} Sites that
                can always use cookies {'>'} add portal.botcopy.com
              </div>

              <Grid container={true} alignItems="center">
                2. Once you have done the above steps, please refresh the page
                <a href={`${process.env.PUBLIC_URL}/login`}>
                  <div className="refresh-button">Refresh</div>
                </a>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        {/* Login Grid */}

        <Grid
          container={true}
          justify="center"
          alignItems="center"
          style={{
            flex: '1 1 0%',
          }}
        >
          <Grid
            container={true}
            justify="center"
            alignItems="flex-start"
            direction="column"
            className="login-grid"
          >
            <div className="welcome-back">Welcome back!</div>
            <div className="login-title">Log in to Botcopy</div>
            <br />
            <Button onClick={() => this.props.auth0.loginWithRedirect()}>
              Sign in
            </Button>

            <div className="no-account-grid">
              Don't have an account?
              <div className="login-redirect">
                <Link as={ReactRouterLink} to="/signup/step1" type="camelCase">
                  Sign up
                </Link>
              </div>
            </div>
          </Grid>
          <Grid
            container={true}
            justify="center"
            alignItems="center"
            className="login-company-links-grid"
          >
            <a className="login-company-links" href="https://botcopy.com">
              © Botcopy 2023
            </a>
            |
            <a className="login-company-links" href="https://botcopy.com/terms">
              Terms of Use
            </a>
            |
            <a
              className="login-company-links"
              href="https://botcopy.com/privacy"
            >
              Privacy Policy
            </a>
          </Grid>
        </Grid>

        {/* Billboard Grid */}
        <Grid
          item={true}
          xs={12}
          style={{
            display: 'flex',
            flex: '1',
            backgroundColor: colors.darkGreyBlue,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* Billboard */}
          <div style={divStyle.billboard}>{renderBillboard()}</div>
        </Grid>
      </Grid>
    )
  }

  private responseGoogle = async (googleUser: User) => {
    const googleId = googleUser.sub?.match(/\|(.*)$/)?.[1] || ''

    try {
      const dataLayer = {
        event: Events.login.returning.success.type,
        eventName: Events.login.returning.success.eventName,
        eventCode: Events.login.returning.success.eventCode,
      }
      mixpanel.identify(googleId)
      trackUserEvent('Logged In', dataLayer)
    } catch (error) {
      const dataLayer = {
        event: Events.login.returning.failure.type,
        eventName: Events.login.returning.failure.eventName,
        eventCode: Events.login.returning.failure.eventCode,
      }
      mixpanel.identify(googleId)
      trackUserEvent('Logged In', dataLayer)
      // @ts-ignore
      window.onerror(error)
      console.error('responseGoogle', error)
      this.props.navigate('/signup/step1')
    }
  }
}

export default withRouter(withStyles(styles)(withAuth0(Login)))
