import React, { useMemo } from 'react'
import { Box, FormLabel, Text } from '@chakra-ui/react'
import { DragAndDropItem, DragAndDropList } from '@botcopy/ui-shared'
import { BotModelType } from '@botcopy/utils-shared'
import { UseBotPublicDataType } from 'src/hooks/useBotPublic'

interface SortBotMenuProps {
  menuBots: BotModelType['_id'][]
  availableBots: BotModelType[]
  handleReorderMenuItems: (botIds: string[]) => void
  handleRemoveMenuItem: (botId: string) => void
}

const SortBotMenu: React.FC<SortBotMenuProps> = ({
  menuBots,
  availableBots,
  handleReorderMenuItems,
  handleRemoveMenuItem,
}) => {
  const menuBotsData = useMemo(
    () =>
      menuBots
        .map((botId) => availableBots.find((bot) => bot._id === botId))
        .filter(Boolean) as UseBotPublicDataType[],
    [availableBots, menuBots],
  )

  return (
    <Box>
      <FormLabel>Drag & Drop to Sort Bot Menu</FormLabel>
      {menuBots.length === 0 && (
        <Text color="gray.500" mt={2}>
          Add menu items to get started
        </Text>
      )}
      <Box maxHeight="500px" overflowY="auto" width="100%">
        {menuBots.length > 0 && (
          <DragAndDropList
            items={menuBotsData.map((menuBotData, index) => ({
              id: `${menuBotData._id}-${index}`, // ID carries the bot ID and index
              name: menuBotData.name || '',
              label: menuBotData._id,
              avatar: {
                src: menuBotData.theme.images.logo,
                backgroundColor: menuBotData.theme.css.bcGreeterBackground,
                borderRadius: menuBotData.theme.css.bcWidgetShape,
                padding: 1,
              },
            }))}
            onReorder={(items) =>
              // Extract the bot ID from the item ID
              handleReorderMenuItems(items.map(({ id }) => id.split('-')[0]))
            }
            removableItems={{
              onRemoveItem: ({ id }: DragAndDropItem) =>
                // Extract the index from the item ID
                handleRemoveMenuItem(id.split('-')[1]),
            }}
          />
        )}
      </Box>
    </Box>
  )
}

export default SortBotMenu
