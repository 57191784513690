import './saveDetector.css'

import { debounce } from 'lodash-es'
import { observer } from 'mobx-react'
import * as React from 'react'

import { Button, Text } from '@chakra-ui/react'

import { delay } from '../utils/delay'
import Logger from '../utils/logger'
import { useStore } from 'src/providers'
import { useAuth0 } from '@auth0/auth0-react'

const log = Logger('SaveDetector').log

export const SAVE_DETECTOR_BAR_HEIGHT = 58
export const noop = () => undefined

const styles = {
  bottomBar: {
    position: 'fixed' as 'fixed',
    height: SAVE_DETECTOR_BAR_HEIGHT,
    bottom: 0,
    left: 72,
    right: 0,
    background: 'white',
    padding: '10px 20px',
    zIndex: 100,
    boxShadow: 'rgba(0, 0, 0, 0.4) 2px 2px 4px 2px',
    transition: 'bottom 0.5s',
  },
  saveButton: {
    marginRight: 10,
    minWidth: 90,
  },
  revertButton: {
    minWidth: 90,
    marginRight: 20,
  },
  text: {
    display: 'inline-block',
  },
}

interface ICommonProps {
  onKeyPress: (
    e: React.KeyboardEvent<HTMLInputElement> &
      React.ChangeEvent<HTMLInputElement>,
  ) => boolean | Promise<boolean | undefined>
  updateOnKeyPress?: (
    onKeyPress: (
      e: React.KeyboardEvent<HTMLInputElement> &
        React.ChangeEvent<HTMLInputElement>,
    ) => boolean | Promise<boolean | undefined>,
  ) => void
  onChange: (
    e: React.ChangeEvent<HTMLInputElement> | { target: { value: string } },
  ) => void
  onRevert?: (value: any) => void
}

interface ISaveDetectorField {
  changed?: boolean
  defaultValue?: string
  optionalValue?: string
}

export interface ISaveDetectorFormElements {
  [key: string]: (IMuiTextField | IChakraInput) &
    ICommonProps &
    ISaveDetectorField
}
interface IMuiTextField {
  ref: React.RefObject<any>
  inputRef: React.RefObject<HTMLInputElement>
  defaultValue?: string
  isMui?: true
}

interface IChakraInput {
  inputRef: React.RefObject<HTMLInputElement>
  defaultValue?: string
  isMui?: false
}

interface ISaveDetectorProps {
  formElements: ISaveDetectorFormElements
  length?: number
  initiated?: () => void
}

const SaveDetector: React.FC<ISaveDetectorProps> = ({
  formElements,
  length,
  initiated,
}) => {
  const store = useStore()
  const { isAuthenticated } = useAuth0()
  const [barVisible, setBarVisible] = React.useState(false)
  const [changeCount, setChangeCount] = React.useState(0)

  const checkValuesChanged = React.useCallback(() => {
    let count = 0

    Object.keys(formElements).forEach((key) => {
      const formElement = formElements[key]

      if (formElement.inputRef.current) {
        const originalValue = formElement.defaultValue
        const currentValue = formElement.inputRef.current.value

        if ((originalValue || currentValue) && currentValue !== originalValue) {
          count += 1
          formElement.inputRef.current.classList.add('value-changed')
        } else {
          formElement.inputRef.current.classList.remove('value-changed')
        }
      }
    })

    setBarVisible(count > 0)
    setChangeCount(count)
  }, [formElements])

  React.useEffect(() => {
    const debouncedCheck = debounce(checkValuesChanged, 150)
    debouncedCheck()

    return () => debouncedCheck.cancel() // Cleanup the debounce on unmount
  }, [checkValuesChanged])

  React.useEffect(() => {
    const monkeyPatchAllFormElements = () => {
      Object.keys(formElements).forEach((key) => {
        const formElement = formElements[key]

        if (formElement.inputRef.current) {
          // Save the initial value when the component is initialized
          formElement.defaultValue = formElement.inputRef.current.value

          if (formElement.onKeyPress) {
            const formElementOnKeyPress = formElement.onKeyPress

            formElement.onKeyPress = async (e) => {
              if (e.persist) {
                e.persist()
              }
              await delay(100)

              if (formElementOnKeyPress) {
                const executedSuccessfully = await formElementOnKeyPress(e)
                if (executedSuccessfully) {
                  if (e.key === 'Enter' && formElement.inputRef.current) {
                    // Update defaultValue when Enter is pressed
                    if (formElement.optionalValue) {
                      formElement.defaultValue = formElement.optionalValue
                      formElement.inputRef.current.value =
                        formElement.optionalValue
                    } else {
                      formElement.defaultValue =
                        formElement.inputRef.current.value
                    }
                  }
                  if (formElement.inputRef.current) {
                    formElement.changed =
                      formElement.inputRef.current.value !==
                      formElement.defaultValue
                  }
                  checkValuesChanged()
                }
              }
              return true
            }
          }

          if (formElement.onChange) {
            const formElementOnChange = formElement.onChange

            formElement.onChange = (e) => {
              if ('persist' in e) {
                e.persist()
              }
              if (e.target.value !== formElement.defaultValue) {
                log(
                  'changed from',
                  formElement.defaultValue,
                  'to',
                  e.target.value,
                )
              }
              checkValuesChanged()
              if (formElementOnChange) {
                if ('persist' in e) {
                  e.persist()
                }
                formElementOnChange(
                  e as React.ChangeEvent<
                    HTMLInputElement & { persist: () => void }
                  >,
                )
              }
            }
          }
        }
      })
    }

    monkeyPatchAllFormElements()

    if (initiated) {
      initiated()
    }
  }, [formElements, checkValuesChanged, initiated])

  const left = isAuthenticated
    ? store?.session?.isNavDrawerOpen
      ? 350
      : 72
    : 0

  const onClickSave = () => {
    Object.keys(formElements).forEach((key) => {
      const formElement = formElements[key]

      if (formElement && formElement.onKeyPress) {
        formElement.onKeyPress({
          key: 'Enter',
          target: { value: formElement.inputRef.current?.value || '' },
          persist: noop,
        } as any)
      }
    })
  }

  const onRevertCancel = () => {
    Object.keys(formElements).forEach((key) => {
      const formElement = formElements[key]

      if (formElement.inputRef.current) {
        formElement.inputRef.current.value = formElement.defaultValue || ''
      }
      if (formElement.onRevert) {
        formElement.onRevert(formElement.inputRef.current?.value)
      }
    })

    checkValuesChanged()
  }

  return (
    <div
      style={{
        ...styles.bottomBar,
        left,
        bottom: barVisible ? 0 : -(SAVE_DETECTOR_BAR_HEIGHT + 10),
      }}
    >
      <Button onClick={onClickSave} mr={4}>
        Save
      </Button>
      <Button variant="light" onClick={onRevertCancel} mr={5}>
        Revert
      </Button>
      <Text style={styles.text}>
        You have {changeCount} unsaved change{changeCount !== 1 ? 's' : ''}
      </Text>
    </div>
  )
}

export default observer(SaveDetector)
