import { useCallback } from 'react'

import { BotMenuModelType, useQuery } from '@botcopy/utils-shared'
import { useStore } from 'src/providers'

export const useBotMenus = () => {
  const {
    data: botMenusData,
    setQuery: botMenusSetQuery,
    query: botMenusQuery,
    loading: botMenusLoading,
    error: botMenusError,
  } = useQuery<{
    botMenus: BotMenuModelType[]
  }>()

  const store = useStore()

  const botMenusFetchData = useCallback(
    (organizationId: string) => {
      botMenusSetQuery(() =>
        store.api.queryBotMenus(
          {
            organizationId,
          },
          (botMenuSelect) =>
            botMenuSelect._id.name
              .headers(
                (botMenuHeaderSelect) => botMenuHeaderSelect.languageCode.main,
              )
              .bots((botSelect) =>
                botSelect._id.name.theme((themeSelect) =>
                  themeSelect
                    .images((imagesSelect) => imagesSelect.logo)
                    .css(
                      (cssSelect) =>
                        cssSelect.bcGreeterBackground.bcWidgetShape,
                    ),
                ),
              ),
        ),
      )
    },
    [botMenusSetQuery, store.api],
  )

  return {
    botMenusData: botMenusData?.botMenus,
    botMenusQuery,
    botMenusFetchData,
    botMenusLoading,
    botMenusError,
  }
}
