import './billboard.css'

import * as React from 'react'
import { MdInfoOutline } from 'react-icons/md'

import { Tooltip } from '@chakra-ui/react'
import { Grid } from '@material-ui/core'

import { colors } from '../../hocs/withTheme'

const billboard = {
  infoIcon: {
    color: colors.lightestGreyBlue,
    fontSize: '18px',
    fontWeight: 550,
    cursor: 'inherit',
    opacity: 1,
  },
}

const tooltipText = {
  // tslint:disable-next-line:max-line-length
  updateOne: `An elegant alternative to slot filling, form components give your UX the best of both worlds.`,
  updateTwo: `Great news for enterprise. We now offer rich content custom payloads for CX.`,
  updateThree: `Found on the bot prompt page.`,
  // updateFour: `Click to learn more`
}

export const renderBillboard = () => {
  return (
    <Grid
      container={true}
      direction="column"
      justify="center"
      alignContent="center"
    >
      <div className="main-header">New Updates</div>

      <div className="content-header">
        In-Chat Forms{' '}
        <a
          href="https://docs.botcopy.com/#/responses/botcopy-custom-payloads?id=forms"
          target="_blank"
          rel="noreferrer"
          className="content-link"
        >
          <Tooltip
            label={tooltipText.updateOne}
            placement={'right-end'}
            padding={5}
          >
            <span>
              <MdInfoOutline style={billboard.infoIcon} />
            </span>
          </Tooltip>
        </a>
      </div>
      <div className="content-text">
        Use a full chat window form or smaller form components.
      </div>

      <div className="content-header">
        Rich Content For Dialogflow CX!{' '}
        <a
          className="content-link"
          href="https://docs.botcopy.com/#/responses/botcopy-custom-payloads?id=forms"
          target="_blank"
          rel="noreferrer"
        >
          <Tooltip
            label={tooltipText.updateTwo}
            placement={'right-end'}
            padding={5}
          >
            <span>
              <MdInfoOutline style={billboard.infoIcon} />
            </span>
          </Tooltip>
        </a>
      </div>
      <div className="content-text">
        Rich web chat UI for enterprise is now as easy as copy/paste.
      </div>

      <div className="content-header">
        Preset prompt contexts and session parameters{' '}
        <Tooltip
          label={tooltipText.updateThree}
          placement={'right-end'}
          padding={5}
        >
          <span>
            <MdInfoOutline style={billboard.infoIcon} />
          </span>
        </Tooltip>
      </div>
      <div className="content-text">
        Set contexts for Dialogflow ES or session parameters for Dialogflow CX
      </div>

      {/* <div  className="content-header">
        {'Bot prompt suggestion chips '}
        <Tooltip
          disableFocusListener={true}
          title={tooltipText.updateFour}
          placement={'right-end'}
          PopperProps={{
            style: {
              opacity: 1
            }
          }}
          TransitionProps={{
            style: {
              backgroundColor: colors.purple,
              padding: '8px'
            }
          }}
        >
          <Button
            style={{ minWidth: '18px', padding: '0' }}
            href="https://www.youtube.com/watch?v=SZBMD435mV0"
            target="_blank"
          >
            <InfoIcon style={billboard.infoIcon} />
          </Button>
        </Tooltip>
      </div>
      <div style={billboard.contentTextLast} className="content-text-last">
        Users can now display suggestion chips under prompts.
      </div> */}
      {/* <div  className="content-link">
        <a href="https://www.youtube.com/watch?v=SZBMD435mV0" target="_blank">
          Learn more
        </a>
      </div> */}
    </Grid>
  )
}
