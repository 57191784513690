import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Wrap,
  Text,
} from '@chakra-ui/react'
import ThemeTile from './ThemeTile'

import { preStyledThemes as themes } from '../data/preStyledThemes'

interface Props {
  isOpen: boolean
  onClose: () => void
  onPreStyledThemeClick: (themeName: string) => void
}

const PreStyledThemesModal = ({
  isOpen,
  onClose,
  onPreStyledThemeClick,
}: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} size="4xl">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader borderBottom={`1px solid`}>
        <Text>Pre-Styled Themes</Text>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text textStyle="body2" mb={4}>
          Pick a default style, or create your own - your options are limitless.
        </Text>
        {/* premade style container */}
        <Wrap spacing={6}>
          {themes.map((theme) => (
            <ThemeTile
              key={theme.name}
              themeName={theme.name}
              bgColor1={theme.bgColor1}
              bgColor2={theme.bgColor2}
              bgColor3={theme.bgColor3}
              onClick={() => onPreStyledThemeClick(theme.name)}
            />
          ))}
        </Wrap>
      </ModalBody>
    </ModalContent>
  </Modal>
)

export default PreStyledThemesModal
