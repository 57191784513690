import { useCallback } from 'react'

import { BotModelType, useQuery } from '@botcopy/utils-shared'
import { useStore } from 'src/providers'

export type UseBotPublicDataType = Pick<BotModelType, '_id' | 'name'> & {
  theme: {
    images: Pick<BotModelType['theme']['images'], 'logo'>
    css: Pick<
      BotModelType['theme']['css'],
      'bcGreeterBackground' | 'bcWidgetShape'
    >
  }
}

export const useBotPublic = () => {
  const {
    data: botPublicData,
    setQuery: botPublicSetQuery,
    query: botPublicQuery,
    loading: botPublicLoading,
    error: botPublicError,
  } = useQuery<{
    botPublic: BotModelType
  }>()

  const store = useStore()

  const botPublicFetchData = useCallback(
    (botId: string) => {
      botPublicSetQuery(() =>
        store.api.queryBotPublic(
          {
            botId,
          },
          (botMenuSelect) =>
            botMenuSelect._id.name.theme((themeSelect) =>
              themeSelect
                .images((imagesSelect) => imagesSelect.logo)
                .css(
                  (cssSelect) => cssSelect.bcGreeterBackground.bcWidgetShape,
                ),
            ),
        ),
      )
    },
    [botPublicSetQuery, store.api],
  )

  return {
    botPublicData: botPublicData?.botPublic,
    botPublicQuery,
    botPublicFetchData,
    botPublicLoading,
    botPublicError,
  }
}
