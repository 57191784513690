import { Instance, SnapshotOut, types } from 'mobx-state-tree'

import {
  NxUserModel,
  NxUserModelType,
  OrganizationModelType,
  RoleName,
} from '@botcopy/utils-shared'

import { withApiStore } from '../_extensions'

export interface IUserAuthInfo {
  email: string
  email_verified: boolean
  name: string
  nickname: string
  org_id: string
  picture: string
  sub: string
  updated_at: string
}

export const UserModel = types
  .model('UserModel', {
    accessToken: types.maybeNull(types.string),
    me: types.union(
      types.undefined,
      types.late(() => NxUserModel),
    ),
  })
  .extend(withApiStore)
  .views((self) => ({
    get organization(): OrganizationModelType {
      return self.me?.roles?.[0].organization
    },
    get roleName(): RoleName {
      return self.me?.roles?.[0].role.name
    },
    get permissions(): string[] {
      return self.me?.roles?.[0].role.permissions ?? []
    },
  }))
  .actions((self) => ({
    setMe(me: NxUserModelType) {
      self.me = me
    },
  }))

export type UserStore = Instance<typeof UserModel>
export type UserSnapshot = SnapshotOut<typeof UserModel>
