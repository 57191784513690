import { colors } from 'src/hocs/withTheme'

export const parseUiByBotcopy = (themeCss: any) => {
  if (themeCss) {
    const {
      bcUiByBotcopyCircleColor,
      bcUiByBotcopyToggleColor,
      bcUiByBotcopyImg: img,
    } = themeCss

    if (!(bcUiByBotcopyCircleColor && bcUiByBotcopyToggleColor && img)) {
      return
    }

    const circle = bcUiByBotcopyCircleColor.toLowerCase()
    const toggle = bcUiByBotcopyToggleColor.toLowerCase()

    const whites = ['#ffffff', colors.pureWhite]
    const blues = ['#0960bd', colors.botcopyBlue]
    const bluesTransparent = ['#0960bdb3', colors.botcopyBlueTransparent]
    const darkBlues = ['#0a2740', colors.prussianBlue]
    const darkBluesTransparent = ['#0a2740b3', `${colors.prussianBlue}b3`]
    const purples = ['#5c5da6', colors.purple]
    const purplesTransparent = ['#5c5da6b3', `${colors.purple}b3`]
    const greys = ['#767776', colors.grey]

    if (
      whites.includes(circle) &&
      blues.includes(toggle) &&
      img === 'blueLogo'
    ) {
      return 'blueWhite'
    }
    if (
      whites.includes(circle) &&
      darkBlues.includes(toggle) &&
      img === 'darkBlueLogo'
    ) {
      return 'darkBlueWhite'
    }
    if (
      whites.includes(circle) &&
      purples.includes(toggle) &&
      img === 'purpleLogo'
    ) {
      return 'purpleWhite'
    }
    if (
      blues.includes(circle) &&
      bluesTransparent.includes(toggle) &&
      img === 'logo'
    ) {
      return 'blue'
    }
    if (
      darkBlues.includes(circle) &&
      darkBluesTransparent.includes(toggle) &&
      img === 'logo'
    ) {
      return 'darkBlue'
    }
    if (
      purples.includes(circle) &&
      purplesTransparent.includes(toggle) &&
      img === 'logo'
    ) {
      return 'purple'
    }
    if (
      darkBlues.includes(circle) &&
      greys.includes(toggle) &&
      img === 'logo'
    ) {
      return 'greyDarkBlue'
    }
    if (blues.includes(circle) && greys.includes(toggle) && img === 'logo') {
      return 'greyBlue'
    }
    if (purples.includes(circle) && greys.includes(toggle) && img === 'logo') {
      return 'greyPurple'
    }
    if (
      whites.includes(circle) &&
      greys.includes(toggle) &&
      img === 'greyscaleLogo'
    ) {
      return 'greyscale'
    }
  }
  return undefined
}
