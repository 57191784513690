import './OnboardingForm.css'

import { map } from 'lodash-es'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import trackUserEvent from 'src/components/trackEvents'
import { colors } from 'src/hocs/withTheme'
import { RootStore } from 'src/models/root-store/root.store'
import { Events } from 'src/utils/gtm'
import { reportToRollBar } from 'src/utils/rollbar'
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react'

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Snackbar,
  SnackbarContent,
  StyleRulesCallback,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core'

// import Logger from 'src/utils/logger'
// const { log } = Logger('OnboardingForm')

// if updating, be sure to update util/organizationEnums in API
const OrganizationIndustry = [
  'Advertising',
  'Agency',
  'Automotive',
  'Banking & Finance',
  'Beauty & Cosmetics',
  'Books',
  'Communication',
  'Construction',
  'Consumer Electronics',
  'Consumer Products',
  'Customer Service',
  'Dating',
  'Delivery & Shipping',
  'eCommerce',
  'Education',
  'Enterprise Software',
  'Entertainment & Media',
  'Events & Ticketing',
  'Family & Parenting',
  'Fitness',
  'Food & Drink',
  'Gaming',
  'Government',
  'Hardware',
  'Healthcare & Life Sciences',
  'Home Automation',
  'Human Resources',
  'Insurance',
  'Job Searching',
  'Kids',
  'Legal',
  'Lifestyle',
  'Local',
  'Magazines',
  'Maps & Navigation',
  'Marketing',
  'Medical',
  'Movies & TV',
  'Music & Audio',
  'News',
  'Pharmaceutical',
  'Photos',
  'Productivity',
  'Real Estate',
  'Religion',
  'Retail & Shopping',
  'Telecommunications',
  'Travel & Hospitality',
  'Utilities',
  'Weather',
  'Other',
]

const OrganizationSize = [
  '1-10',
  '11-100',
  '101-1000',
  '1000+',
  'Student',
  'Prefer not to answer',
]

const OrganizationRegion = [
  'Africa',
  'Central Asia',
  'East Asia',
  'Southeast Asia',
  'Central America & Caribbean',
  'Europe',
  'India',
  'Middle East',
  'North America',
  'Oceania',
  'South America',
  'Other',
]

const OrganizationSignupReasons = [
  {
    value: 'need_customer_facing_solution',
    label: 'I need a customer-facing solution',
  },
  {
    value: 'need_internal_facing_solution',
    label: 'I need an internal-facing solution',
  },
  {
    value: 'demo_poc',
    label: 'I want to create a demo for proof of concept',
  },
  {
    value: 'migrate_bot',
    label: 'I want to migrate my current bot',
  },
  {
    value: 'offer_botcopy_agency',
    label: 'Looking to offer Botcopy through my agency',
  },
]

const tooltipText = {
  // TODO: credit card copy
  creditCardPolicy:
    'You will be signed up for a Standard Plan. The 50 engagements will expire after a week, at which point your Standard plan will take effect.',
  emailPolicy: `In the future, we'll send you a few emails a month with updates,
  promotions, and special events. You’ll be able to unsubscribe at
  any time and we never share your information.`,
}

const styles: StyleRulesCallback = (theme) => ({
  cssFocused: {},
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: colors.darkGreyBlue,
    },
  },
  input: {
    color: colors.darkGreyBlue,
    font: 'Open Sans, sans-serif',
    fontSize: '14px',
  },
  notchedOutline: {
    borderColor: colors.darkGreyBlue,
  },
})

const divs = {
  ourPolicy: {
    color: colors.offWhite,
    textDecoration: 'underline',
  },
  fields: {
    width: '360px',
    margin: '10px',
  },
  secondaryFields: { width: '360px', margin: '10px' },
  dataTitles: {
    color: colors.darkGreyBlue,
    fontSize: '1rem',
    fontWeight: 600,
    margin: '10px',
  },
}

interface IOnboardingFormProps extends WithAuth0Props {
  classes: any
  store?: RootStore
}

interface IOnboardingFormState {
  businessName: string
  companySize: string
  industry: string
  introCompleted: boolean
  formProgress: boolean
  preferredEmail: string
  reasonsForSignup: string[]
  region: string
  requiredFieldsErrorSnackbar: boolean
  website: string
}

@inject('store')
@observer
class OnboardingForm extends React.Component<
  IOnboardingFormProps,
  IOnboardingFormState
> {
  constructor(props: IOnboardingFormProps) {
    super(props)
    this.state = {
      businessName: '',
      companySize: '',
      industry: '',
      introCompleted: false,
      formProgress: false,
      preferredEmail: '',
      reasonsForSignup: [],
      region: '',
      requiredFieldsErrorSnackbar: false,
      website: '',
    }
  }

  public render() {
    const { classes } = this.props
    const org = this.props.store?.organizations.current
    if (!org) {
      reportToRollBar(
        new Error(),
        'No current organization when rendering onboarding form',
      )
      this.props.auth0.logout({
        logoutParams: { returnTo: `${window.location.origin}/login` },
      })
      return
    }
    return (
      <div>
        <Dialog
          open={true}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll="paper"
          PaperProps={{
            style: {
              backgroundColor: colors.darkGreyBlue,
              overflowY: 'hidden',
              borderBottom: '5px solid #238ae4',
            },
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              padding: 0,
            }}
          >
            <Grid container={true}>
              {!org.onboarding.creditCardCaptured ? null : (
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: '.95em',
                    color: colors.darkGreyBlue,
                    backgroundColor: colors.offWhite,
                    width: '50%',
                    padding: '40px 20px 0',
                    position: 'relative',
                    textAlign: 'center',
                  }}
                >
                  Congratulations! You have successfully created your Botcopy
                  account.
                </div>
              )}
            </Grid>
          </DialogTitle>
          <DialogContent
            style={{
              padding: 0,
              overflowY: 'hidden',
              display: 'flex',
              minHeight: '400px',
            }}
          >
            {!this.state.introCompleted &&
            !org.onboarding.onboardingFormCompleted ? (
              <Grid
                container={true}
                direction="column"
                justify="center"
                alignItems="center"
                className="onboarding-form-intro-grid"
              >
                <img
                  className="onboarding-into-img"
                  alt="Welcome to Botcopy"
                  src="/images/logos/50x50logoblue.png"
                  height="50px"
                  width="50px"
                />
                <div className="onboarding-board-title">
                  Welcome to Botcopy!
                </div>

                <div className="onboarding-form-intro-welcome-text">
                  Let's find out how we can help you, in less than a minute.
                </div>
                <button
                  onClick={(e) => this.setState({ introCompleted: true })}
                  className="onboarding-form-intro-button"
                >
                  Get Started
                </button>
              </Grid>
            ) : this.state.introCompleted &&
              !org.onboarding.onboardingFormCompleted ? (
              <Grid
                container={true}
                justify="center"
                alignItems="center"
                className="onboarding-form-grid"
              >
                <div className="onboarding-form-header">
                  <div>
                    Please provide an email address you check often, and some
                    details that will help us welcome you in style.
                  </div>
                  <Grid container={true} justify="flex-end">
                    <Tooltip
                      disableFocusListener={true}
                      title={tooltipText.emailPolicy}
                      placement={'bottom'}
                      className="onboarding-form-email-policy"
                      PopperProps={{
                        style: {
                          opacity: 1,
                        },
                      }}
                      TransitionProps={{
                        style: {
                          backgroundColor: colors.purple,
                          padding: '8px',
                        },
                      }}
                    >
                      <div>Email Policy</div>
                    </Tooltip>
                  </Grid>
                </div>
                <Grid
                  container={true}
                  justify="center"
                  alignItems="center"
                  direction="column"
                >
                  <div className="field-grid">
                    <Grid
                      container={true}
                      justify="space-between"
                      alignItems="center"
                    >
                      <div className="onboarding-form-title">Contact Info</div>
                    </Grid>
                    <TextField
                      label="Preferred Contact Email *"
                      autoComplete="email"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      autoFocus={true}
                      onChange={(e) => {
                        this.setState({ preferredEmail: e.target.value })
                      }}
                      style={divs.fields}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      InputLabelProps={
                        !this.state.preferredEmail &&
                        this.state.requiredFieldsErrorSnackbar
                          ? {
                              style: {
                                color: colors.errorRed,
                                fontSize: '1rem',
                                fontWeight: 600,
                              },
                              shrink: true,
                            }
                          : {
                              style: {
                                color: colors.darkGreyBlue,
                                fontSize: '1rem',
                                fontWeight: 600,
                              },
                              shrink: true,
                            }
                      }
                    />
                  </div>
                </Grid>
                <Grid
                  container={true}
                  justify="center"
                  direction="column"
                  alignItems="center"
                >
                  <div className="field-grid">
                    <div className="onboarding-form-title">Company Details</div>
                    <TextField
                      label="Company Name *"
                      autoComplete="organization"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      onChange={(e) => {
                        this.setState({ businessName: e.target.value })
                      }}
                      style={divs.fields}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      InputLabelProps={
                        !this.state.businessName &&
                        this.state.requiredFieldsErrorSnackbar
                          ? {
                              style: {
                                color: colors.errorRed,
                                fontSize: '1rem',
                                fontWeight: 600,
                              },
                              shrink: true,
                            }
                          : {
                              style: {
                                color: colors.darkGreyBlue,
                                fontSize: '1rem',
                                fontWeight: 600,
                              },
                              shrink: true,
                            }
                      }
                    />
                    <TextField
                      label="Company Website"
                      autoComplete="url"
                      variant="outlined"
                      margin="dense"
                      fullWidth={true}
                      onChange={(e) => {
                        this.setState({ website: e.target.value })
                      }}
                      style={divs.fields}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: colors.darkGreyBlue,
                          fontSize: '1rem',
                          fontWeight: 600,
                        },
                        shrink: true,
                      }}
                    />

                    <TextField
                      select={true}
                      variant="outlined"
                      margin="normal"
                      value={this.state.region}
                      id="company-region-label"
                      style={divs.fields}
                      label={
                        <div
                          style={{
                            color:
                              !this.state.region &&
                              this.state.requiredFieldsErrorSnackbar
                                ? colors.errorRed
                                : colors.darkGreyBlue,
                            fontSize: '1rem',
                            fontWeight: 600,
                          }}
                        >
                          Company Region *
                        </div>
                      }
                      className={classes.select}
                      SelectProps={{
                        autoWidth: true,
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel,
                        shrink: true,
                      }}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      onChange={(e) =>
                        this.setState({ region: e.target.value })
                      }
                    >
                      {map(OrganizationRegion, (region: string) => {
                        return <MenuItem value={region}>{region}</MenuItem>
                      })}
                    </TextField>

                    <TextField
                      select={true}
                      variant="outlined"
                      margin="normal"
                      value={this.state.companySize}
                      style={divs.fields}
                      id="company-size-label"
                      label={
                        <div
                          style={{
                            color:
                              !this.state.companySize &&
                              this.state.requiredFieldsErrorSnackbar
                                ? colors.errorRed
                                : colors.darkGreyBlue,
                            fontSize: '1rem',
                            fontWeight: 600,
                          }}
                        >
                          Company Size *
                        </div>
                      }
                      className={classes.select}
                      SelectProps={{
                        autoWidth: true,
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel,
                        shrink: true,
                      }}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      onChange={(e) =>
                        this.setState({ companySize: e.target.value })
                      }
                    >
                      {map(OrganizationSize, (size: string) => {
                        return <MenuItem value={size}>{size}</MenuItem>
                      })}
                    </TextField>

                    <TextField
                      select={true}
                      variant="outlined"
                      margin="normal"
                      value={this.state.industry}
                      style={divs.fields}
                      id="company-industry-label"
                      label={
                        <div
                          style={{
                            color:
                              !this.state.industry &&
                              this.state.requiredFieldsErrorSnackbar
                                ? colors.errorRed
                                : colors.darkGreyBlue,
                            fontSize: '1rem',
                            fontWeight: 600,
                          }}
                        >
                          Primary Industry *
                        </div>
                      }
                      className={classes.select}
                      SelectProps={{
                        autoWidth: true,
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel,
                        shrink: true,
                      }}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      onChange={(e) =>
                        this.setState({ industry: e.target.value })
                      }
                    >
                      {map(OrganizationIndustry, (industry: string) => {
                        return <MenuItem value={industry}>{industry}</MenuItem>
                      })}
                    </TextField>
                  </div>
                  <div className="field-grid">
                    <div className="onboarding-form-title-need-help">
                      Need Dialogflow help?
                    </div>
                    {!org.onboarding.onboardingFormCompleted ? (
                      <FormGroup className="onboarding-form-need-help">
                        <FormControlLabel
                          label="Yes, receive consultation."
                          control={
                            <Checkbox
                              checked={org.onboarding.wantsConsultation}
                              onClick={() =>
                                org.onboarding.toggleWantsConsultation()
                              }
                            />
                          }
                          labelPlacement="end"
                        />
                      </FormGroup>
                    ) : null}
                  </div>
                </Grid>

                <Grid
                  container={true}
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <FormControl style={{ margin: '10px' }}>
                    <FormLabel>
                      <div className="onboarding-form-reasons">
                        Reasons for signing up:
                      </div>
                      <div className="onboarding-form-reasons-subtitle">
                        (Select all that apply)
                      </div>
                    </FormLabel>
                    <FormGroup>
                      {map(OrganizationSignupReasons, (signupReason) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={signupReason.value}
                              onChange={(e: any) => this._handleSignupReason(e)}
                            />
                          }
                          style={{
                            color: colors.green,
                            fontSize: '14px',
                            fontWeight: 600,
                          }}
                          label={signupReason.label}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                  <Button
                    style={{
                      textTransform: 'none',
                      backgroundColor: colors.darkGreyBlue,
                      color: colors.offWhite,
                      padding: '8px 150px',
                      margin: '10px 10px 20px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={this._submitOnboardingForm}
                  >
                    Submit
                    {this.state.formProgress ? (
                      <CircularProgress
                        size={16}
                        className="onboarding-form-submitted-icon"
                      />
                    ) : null}
                  </Button>
                </Grid>
              </Grid>
            ) : null}
          </DialogContent>
        </Dialog>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.requiredFieldsErrorSnackbar}
          autoHideDuration={2500}
          onClose={this._closeSnackbar}
        >
          <SnackbarContent
            style={{
              backgroundColor: colors.errorRed,
              color: colors.offWhite,
            }}
            message="Please fill out the required fields."
          />
        </Snackbar>
      </div>
    )
  }

  private _closeSnackbar = () => {
    if (this.state.requiredFieldsErrorSnackbar) {
      this.setState({ requiredFieldsErrorSnackbar: false })
    }
  }

  private _handleSignupReason = async (e: any) => {
    const editArray = this.state.reasonsForSignup

    if (e.target.checked) {
      editArray.push(e.target.value)
      this.setState({ reasonsForSignup: editArray })
    } else {
      const filtered = editArray.filter(
        (element: string) => element !== e.target.value,
      )
      this.setState({ reasonsForSignup: filtered })
    }
  }

  private handleContinue = async () => {
    const org = this.props.store?.organizations.current
    if (!org) return

    await org.incProductTourStep()
  }

  private _submitOnboardingForm = async () => {
    const { store } = this.props
    const org = store?.organizations.current
    if (org) {
      this.setState({ formProgress: true })
      const {
        businessName,
        companySize,
        industry,
        preferredEmail,
        reasonsForSignup,
        region,
        website,
      } = this.state
      const { wantsConsultation } = org.onboarding

      if (
        !preferredEmail ||
        !businessName ||
        !companySize ||
        !industry ||
        !region
      ) {
        return this.setState({ requiredFieldsErrorSnackbar: true })
      }

      const dataLayer = {
        event: Events.onboarding.form.success.type,
        eventName: Events.onboarding.form.success.eventName,
        eventCode: Events.onboarding.form.success.eventCode,
      }
      trackUserEvent('Onboarding Step', dataLayer)

      await org.handleOnboardingForm({
        profile: {
          businessName,
          companySize,
          industry,
          preferredEmail,
          reasonsForSignup,
          region,
          website,
        },
        onboarding: {
          onboardingFormCompleted: true,
          wantsConsultation,
        },
      })

      this.handleContinue()
    }
  }
}

export default withStyles(styles)(withAuth0(OnboardingForm))
