import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useState, useEffect, useCallback, useMemo } from 'react'

import {
  Box,
  FormControl,
  FormLabel,
  Switch,
  useToast,
  Flex,
  Tooltip,
  Icon,
} from '@chakra-ui/react'

import { InfoIcon } from '@chakra-ui/icons'

import LoadingSpinner from '../../components/LoadingSpinner'

import {
  CardCustom,
  SaveDetectorConfirmation,
  useSaveDetector,
} from '@botcopy/ui-shared'
import CreditCardCapture from '../onboarding/CreditCardCapture'
import { RouteProps, withRouter } from 'src/utils/withRouter'
import { validateEmail } from 'src/utils/validateEmail'
import { validatePhone } from 'src/utils/validatePhone'
import BotProfileForm from './BotProfileForm'
import CustomFields from './CustomFields'
import { useStore } from 'src/providers'
import { useParams } from 'react-router-dom'

const tooltipText = {
  showProfileToggle:
    'Adds a customizable profile to your bot. Interacted with through the header.',
}

function BotProfile(props: RouteProps) {
  const store = useStore()
  const { botId } = useParams()
  const toast = useToast()

  const defaultBotProfileState = useMemo(
    () => ({
      bcShowBotProfile: false,
      bcBotProfileEmail: '',
      bcBotProfilePhone: '',
      bcBotProfileCustomFields: [] as any[],
    }),
    [],
  )

  const [formElements, setFormElements] = useState({
    ...defaultBotProfileState,
  })
  const [lastSavedValues, setLastSavedValues] = useState({
    ...defaultBotProfileState,
  })

  // Add validation states
  const [validation, setValidation] = useState({
    isEmailValid: true,
    isPhoneValid: true,
  })

  const [isSaveLoading, setIsSaveLoading] = useState(false)

  const currentBot = store?.bots.currentBot
  const org = store?.organizations.current
  const me = store?.users.me

  const initialValue = useMemo(() => {
    if (!currentBot?.theme.botProfile) return { ...defaultBotProfileState }

    const botProfile = toJS(currentBot.theme.botProfile)
    return {
      bcShowBotProfile:
        botProfile.bcShowBotProfile || defaultBotProfileState.bcShowBotProfile,
      bcBotProfileEmail:
        botProfile.bcBotProfileEmail ||
        defaultBotProfileState.bcBotProfileEmail,
      bcBotProfilePhone:
        botProfile.bcBotProfilePhone ||
        defaultBotProfileState.bcBotProfilePhone,
      bcBotProfileCustomFields:
        botProfile.bcBotProfileCustomFields ||
        defaultBotProfileState.bcBotProfileCustomFields,
    }
  }, [currentBot, defaultBotProfileState])

  const hasUnsavedChanges = useSaveDetector(
    lastSavedValues.bcShowBotProfile !== undefined
      ? lastSavedValues
      : initialValue,
    formElements,
  )

  const showSuccessToast = useCallback(
    (message: string) => {
      toast({
        title: 'Success',
        description: message,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      })
    },
    [toast],
  )

  const showErrorToast = useCallback(
    (message: string) => {
      toast({
        title: 'Error',
        description: message,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      })
    },
    [toast],
  )

  const toggleShowBotProfile = () => {
    setFormElements((prev) => ({
      ...prev,
      bcShowBotProfile: !prev.bcShowBotProfile,
    }))
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value

    setFormElements((prev) => ({
      ...prev,
      bcBotProfileEmail: newEmail,
    }))
  }

  const handleEmailBlur = () => {
    const isValid = validateEmail(formElements.bcBotProfileEmail)

    setValidation((prev) => ({
      ...prev,
      isEmailValid: isValid,
    }))
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhone = e.target.value

    setFormElements((prev) => ({
      ...prev,
      bcBotProfilePhone: newPhone,
    }))
  }

  const handlePhoneBlur = () => {
    const isValid = validatePhone(formElements.bcBotProfilePhone)

    setValidation((prev) => ({
      ...prev,
      isPhoneValid: isValid,
    }))
  }

  const handleCustomFieldChange = (
    index: string,
    field: 'label' | 'value',
    value: string,
  ) => {
    if (!currentBot) return

    setFormElements((prev) => {
      const updatedFields = [...prev.bcBotProfileCustomFields]
      const fieldIndex = updatedFields.findIndex((f) => f._id === index)

      if (fieldIndex !== -1) {
        const updatedField = { ...updatedFields[fieldIndex] }
        if (field === 'label') {
          updatedField.bcCustomFieldLabel = value
        } else {
          updatedField.bcCustomFieldValue = value
        }
        updatedFields[fieldIndex] = updatedField
      }

      return {
        ...prev,
        bcBotProfileCustomFields: updatedFields,
      }
    })
  }

  const addCustomField = () => {
    if (!currentBot || formElements.bcBotProfileCustomFields.length >= 5) return

    const tempId = `temp_${Date.now()}`

    const newField = {
      _id: tempId,
      bcCustomFieldLabel: '',
      bcCustomFieldValue: '',
    }

    setFormElements((prev) => ({
      ...prev,
      bcBotProfileCustomFields: [...prev.bcBotProfileCustomFields, newField],
    }))
  }

  const deleteCustomField = (index: string) => {
    if (!currentBot) return

    setFormElements((prev) => ({
      ...prev,
      bcBotProfileCustomFields: prev.bcBotProfileCustomFields.filter(
        (field) => field._id !== index,
      ),
    }))
  }

  const handleSave = async () => {
    if (!(org && currentBot)) return

    const isEmailValid = validateEmail(formElements.bcBotProfileEmail)
    const isPhoneValid = validatePhone(formElements.bcBotProfilePhone)

    setValidation({
      isEmailValid,
      isPhoneValid,
    })

    // Don't proceed if validation fails
    if (!isEmailValid || !isPhoneValid) {
      showErrorToast('Please fix validation errors before saving.')
      return
    }

    setIsSaveLoading(true)

    try {
      const themeUpdate = JSON.parse(JSON.stringify(currentBot.theme))

      const processedCustomFields = formElements.bcBotProfileCustomFields.map(
        (field) => ({
          bcCustomFieldLabel: field.bcCustomFieldLabel || '',
          bcCustomFieldValue: field.bcCustomFieldValue || '',
        }),
      )

      themeUpdate.botProfile = {
        ...themeUpdate.botProfile,
        bcShowBotProfile: formElements.bcShowBotProfile,
        bcBotProfileEmail: formElements.bcBotProfileEmail,
        bcBotProfilePhone: formElements.bcBotProfilePhone,
        bcBotProfileCustomFields: processedCustomFields,
      }

      await currentBot.patchTheme(themeUpdate)

      setLastSavedValues({ ...formElements })

      showSuccessToast('Your changes have been saved.')

      store?.bots.fetchBots()
    } catch (error) {
      console.error('Error saving bot profile:', error)
      showErrorToast(
        'There was an error saving your changes. Please try again.',
      )
    } finally {
      setIsSaveLoading(false)
    }
  }

  useEffect(() => {
    if (currentBot?.theme.botProfile) {
      const initialValues = {
        bcShowBotProfile:
          toJS(currentBot.theme.botProfile.bcShowBotProfile) || false,
        bcBotProfileEmail:
          toJS(currentBot.theme.botProfile.bcBotProfileEmail) || '',
        bcBotProfilePhone:
          toJS(currentBot.theme.botProfile.bcBotProfilePhone) || '',
        bcBotProfileCustomFields:
          toJS(currentBot.theme.botProfile.bcBotProfileCustomFields) || [],
      }

      setFormElements(initialValues)
      setLastSavedValues(initialValues)
    }
  }, [currentBot])

  useEffect(() => () => store?.bots.setCurrentBotId(undefined), [store?.bots])

  if (store?.bots.currentBotId !== botId) {
    store?.bots.setCurrentBotId(botId)
    return null
  }

  if (currentBot?.theme.css && org && me) {
    if (!currentBot.theme.botProfile) {
      return null
    }

    return (
      <Flex py={10}>
        <CreditCardCapture store={store} classes={{}} />
        <Box width="container.sm" mx="auto">
          <CardCustom>
            <Flex justifyContent="space-between" mb={3}>
              <Flex width="100%">
                <FormLabel fontWeight="bold" mb={0}>
                  Bot Profile
                </FormLabel>
                <Tooltip label={tooltipText.showProfileToggle}>
                  <Icon as={InfoIcon} />
                </Tooltip>
              </Flex>
              <FormControl
                display="flex"
                justifyContent={'flex-end'}
                alignItems="center"
              >
                <FormLabel marginRight={2} my={0}>
                  Enabled
                </FormLabel>
                <Switch
                  isChecked={formElements.bcShowBotProfile}
                  onChange={toggleShowBotProfile}
                />
              </FormControl>
            </Flex>
            <BotProfileForm
              formValues={formElements}
              handleEmailChange={handleEmailChange}
              handlePhoneChange={handlePhoneChange}
              handleEmailBlur={handleEmailBlur}
              handlePhoneBlur={handlePhoneBlur}
              tooltipText={tooltipText}
              validation={validation}
            />
            <CustomFields
              customFields={formElements.bcBotProfileCustomFields}
              handleCustomFieldChange={handleCustomFieldChange}
              addCustomField={addCustomField}
              deleteCustomField={deleteCustomField}
              showToast={(type: string, message: string) =>
                type === 'success'
                  ? showSuccessToast(message)
                  : showErrorToast(message)
              }
            />
          </CardCustom>
        </Box>

        {hasUnsavedChanges && (
          <SaveDetectorConfirmation
            hasUnsavedChanges={hasUnsavedChanges}
            isSaveLoading={isSaveLoading}
            onSave={handleSave}
            left={store.session.isNavDrawerOpen ? '350px' : '71px'}
            width={
              store.session.isNavDrawerOpen
                ? 'calc(100% - 350px)'
                : 'calc(100% - 71px)'
            }
          />
        )}
      </Flex>
    )
  }
  return <LoadingSpinner />
}

export default withRouter(observer(BotProfile))
