import { inject, observer } from 'mobx-react'
import React from 'react'
import {
  MdOutlineAccountCircle,
  MdOutlineCastConnected,
  MdOutlineDashboard,
  MdOutlineDescription,
  MdOutlineFeedback,
  MdOutlineForum,
  MdOutlineHelpOutline,
  MdOutlinePalette,
  MdOutlinePermIdentity,
  MdOutlinePrivacyTip,
  MdRssFeed,
  MdGridView,
  MdViewList,
} from 'react-icons/md'
import { RoleDisplayNames, Roles } from 'src/models/users'

import { Search, SidebarSubMenu } from '@botcopy/ui-shared'
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  HStack,
  Image,
  Tag,
  Text,
} from '@chakra-ui/react'

import { RootStore } from '../../models/root-store/root.store'

interface IBotDrawerProps {
  onOpen?: () => void
  onClose?: () => void
  store?: RootStore
  navItems?: any
  accordionPanelItems?: any
  searchQuery: string
  isMaximized: boolean
  onSearchChange: (query: string) => void
  toggleViewMode: (mode: 'grid' | 'table') => void
  viewMode: 'grid' | 'table'
}

interface IBotDrawerState {
  open: boolean
}

// bind open state to session
@inject('store')
@observer
class BotDrawer extends React.Component<IBotDrawerProps, IBotDrawerState> {
  constructor(props: IBotDrawerProps) {
    super(props)
    this.state = {
      open: false,
    }
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onSearchChange(event.target.value)
  }

  public render() {
    const { store } = this.props
    const botId = store?.bots.currentBotId
    const isDashboard = window.location.pathname === '/'
    let currentBot
    const isMaximized = this.props.isMaximized
    if (botId) {
      currentBot = store?.bots.currentBot
    }

    const portalMainItems = [
      {
        label: 'Dashboard',
        path: '/',
        icon: MdOutlineDashboard,
      },
      {
        label: 'Account',
        path: '/account',
        icon: MdOutlineAccountCircle,
      },
      {
        label: 'Engagements',
        path: '/engagements',
        icon: MdOutlineForum,
      },
      {
        label: 'Docs',
        path: '//docs.botcopy.com/#/?fromPortal=true',
        icon: MdOutlineDescription,
        external: true,
      },
      {
        label: 'Support',
        path: '//www.botcopy.com/support',
        icon: MdOutlineHelpOutline,
        external: true,
      },
    ]

    const portalBotItems = botId
      ? [
          {
            label: 'Connect',
            path: `/bot/${botId}/connect`,
            icon: MdRssFeed,
          },
          {
            label: 'Integrations',
            path: `/bot/${botId}/integrations`,
            icon: MdOutlineCastConnected,
          },
          {
            label: 'Branding',
            path: `/bot/${botId}/branding`,
            icon: MdOutlinePalette,
          },
          {
            label: 'Bot Prompts',
            path: `/bot/${botId}/bot-prompts`,
            icon: MdOutlineForum,
          },
          {
            label: 'Feedback',
            path: `/bot/${botId}/component/feedback`,
            icon: MdOutlineFeedback,
          },
          {
            label: 'Privacy Policy',
            path: `/bot/${botId}/component/privacypolicy`,
            icon: MdOutlinePrivacyTip,
          },
          {
            label: 'Profile',
            path: `/bot/${botId}/component/profile`,
            icon: MdOutlinePermIdentity,
          },
        ]
      : []

    const me = store?.users.me

    return (
      <>
        <SidebarSubMenu
          navItems={portalMainItems}
          accordionPanelItems={this.props.accordionPanelItems}
          isMaximized={isMaximized}
        />
        {isDashboard && isMaximized && (
          <Box p={4}>
            <Search
              placeholder="Search Bots"
              defaultValue={this.props.searchQuery}
              onChange={this.handleInputChange}
            />
          </Box>
        )}
        {isDashboard && (
          <Box p={4}>
            {!isMaximized && (
              <ButtonGroup
                spacing={0}
                width="100%"
                display="flex"
                flexDirection="column"
              >
                <IconButton
                  aria-label="View grid view"
                  icon={<MdGridView />}
                  variant={this.props.viewMode === 'grid' ? 'solid' : 'outline'}
                  onClick={() => this.props.toggleViewMode('grid')}
                />
                <IconButton
                  aria-label="View list view"
                  icon={<MdViewList />}
                  variant={
                    this.props.viewMode === 'table' ? 'solid' : 'outline'
                  }
                  onClick={() => this.props.toggleViewMode('table')}
                />
              </ButtonGroup>
            )}
            {isMaximized && (
              <HStack spacing={2}>
                <Button
                  leftIcon={<MdGridView />}
                  variant={this.props.viewMode === 'grid' ? 'solid' : 'outline'}
                  onClick={() => this.props.toggleViewMode('grid')}
                  width="100%"
                >
                  Grid View
                </Button>
                <Button
                  leftIcon={<MdViewList />}
                  variant={
                    this.props.viewMode === 'table' ? 'solid' : 'outline'
                  }
                  onClick={() => this.props.toggleViewMode('table')}
                  width="100%"
                >
                  Table View
                </Button>
              </HStack>
            )}
          </Box>
        )}
        {currentBot && currentBot.theme.css && (
          <Flex
            px={4}
            background="#ccc"
            alignContent="space-between"
            py={4}
            alignItems="center"
          >
            {isMaximized && (
              <Image
                backgroundColor={currentBot.theme.css.bcGreeterBackground}
                borderRadius={currentBot.theme.css.bcWidgetShape}
                padding="8px"
                height="40px"
                width="40px"
                marginRight="15px"
                objectFit="contain"
                src={currentBot.theme.images.logo}
              />
            )}

            <Text
              fontSize="md"
              flexGrow={1}
              width="100%"
              noOfLines={2}
              title={currentBot.name}
            >
              {currentBot.name}
            </Text>
            {isMaximized && (
              <Tag
                background="#000"
                color="#fff"
                ml={3}
                maxWidth="100%"
                width="auto"
                py={2}
                px={5}
                textAlign="center"
                justifyContent="center"
                overflow="hidden"
              >
                {(() => {
                  const rolePriorities = [
                    Roles.ADMIN,
                    Roles.DEV,
                    Roles.MARKETER,
                  ]

                  for (const role of rolePriorities) {
                    if (me?.roles.includes(role)) {
                      return RoleDisplayNames[role]
                    }
                  }

                  return null
                })()}
              </Tag>
            )}
          </Flex>
        )}
        {currentBot && currentBot.theme.css && (
          <SidebarSubMenu
            navItems={portalBotItems}
            accordionPanelItems={this.props.accordionPanelItems}
            isMaximized={isMaximized}
          />
        )}
      </>
    )
  }
}

export default BotDrawer
