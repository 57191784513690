export const promptsTileConfigs = [
  { cssVal: 'bcGreeterFontColor', tileHeader: 'Font Color' },
  { cssVal: 'bcGreeterPromptBackground', tileHeader: 'Fill Color' },
  { cssVal: 'bcGreeterBorder', tileHeader: 'Border Color' },
  { cssVal: 'bcGreeterBorderHoverColor', tileHeader: 'Border Hover Color' },
  { cssVal: 'bcGreeterHoverFillColor', tileHeader: 'Hover Fill Color' },
  { cssVal: 'bcGreeterHoverFontColor', tileHeader: 'Hover Font Color' },
  { cssVal: 'bcPromptCloseButtonColor', tileHeader: 'Close Button Color' },
  {
    cssVal: 'bcPromptSuggestionFillColor',
    tileHeader: 'Suggestion Fill Color',
  },
  { cssVal: 'bcPromptSuggestionFontColor', tileHeader: 'Font Color' },
  {
    cssVal: 'bcPromptSuggestionHoverFillColor',
    tileHeader: 'Suggestion Hover Fill Color',
  },
  {
    cssVal: 'bcPromptSuggestionHoverFontColor',
    tileHeader: 'Suggestion Hover Font Color',
  },
]

export const chatWindowTileConfigs = [
  { cssVal: 'bcAvatarBackgroundColor', tileHeader: 'Avatar Background Color' },
  { cssVal: 'bcBotResponseFillColor', tileHeader: 'Bot Response Fill Color' },
  {
    cssVal: 'bcBotResponseBorderColor',
    tileHeader: 'Bot Response Border Color',
  },
  { cssVal: 'bcBotResponseFontColor', tileHeader: 'Font Color' },
  { cssVal: 'bcUserBoxColor', tileHeader: 'User Fill Color' },
  { cssVal: 'bcUserResponseColor', tileHeader: 'User Font Color' },
  { cssVal: 'bcButtonFillColor', tileHeader: 'Button Fill Color' },
  { cssVal: 'bcButtonFontColor', tileHeader: 'Button Font Color' },
  { cssVal: 'bcTypingAnimationDots', tileHeader: 'Typing Animation Dot Color' },
  {
    cssVal: 'bcTypingAnimationFill',
    tileHeader: 'Typing Animation Fill Color',
  },
  { cssVal: 'bcHoverFillColor', tileHeader: 'Hover Fill Color' },
  { cssVal: 'bcHoverFontColor', tileHeader: 'Hover Font Color' },
  { cssVal: 'bcFocusFillColor', tileHeader: 'Focus Fill Color' },
  { cssVal: 'bcChatroomFillColor', tileHeader: 'Chatroom Fill Color' },
  { cssVal: 'bcChatBackground', tileHeader: 'Fullscreen Panel Color' },
]

export const inputBarTileConfigs = [
  { cssVal: 'bcMenuButtonColor', tileHeader: 'Menu Button Color' },
  {
    cssVal: 'bcInputBarContainerFillColor',
    tileHeader: 'Container Fill Color',
  },
  { cssVal: 'bcInputBarFillColor', tileHeader: 'Fill Color' },
  { cssVal: 'bcInputBarFontColor', tileHeader: 'Font Color' },
  { cssVal: 'bcInputBarLabelColor', tileHeader: 'Label Color' },
  { cssVal: 'bcInputBarPlaceholderColor', tileHeader: 'Placeholder Color' },
  { cssVal: 'bcInputBarBorderColor', tileHeader: 'Border Color' },
  { cssVal: 'bcSendActiveColor', tileHeader: 'Send Button Active Color' },
]

export const feedbackTileConfigs = [
  {
    cssVal: 'bcFeedbackAddCommentBackground',
    tileHeader: 'Comment Button Fill Color',
  },
  {
    cssVal: 'bcFeedbackAddCommentFontColor',
    tileHeader: 'Comment Button Font Color',
  },
  {
    cssVal: 'bcFeedbackSubmitBackground',
    tileHeader: 'Submit Button Fill Color',
  },
  {
    cssVal: 'bcFeedbackSubmitFontColor',
    tileHeader: 'Submit Button Font Color',
  },
]

export const privacyPolicyTileConfigs = [
  { cssVal: 'bcPrivacyPolicyAcceptFontColor', tileHeader: 'Accept Font Color' },
  { cssVal: 'bcPrivacyPolicyAcceptFillColor', tileHeader: 'Accept Fill Color' },
  {
    cssVal: 'bcPrivacyPolicyAcceptBorderColor',
    tileHeader: 'Accept Border Color',
  },
  {
    cssVal: 'bcPrivacyPolicyDeclineFontColor',
    tileHeader: 'Decline Font Color',
  },
  {
    cssVal: 'bcPrivacyPolicyDeclineFillColor',
    tileHeader: 'Decline Fill Color',
  },
  {
    cssVal: 'bcPrivacyPolicyDeclineBorderColor',
    tileHeader: 'Decline Border Color',
  },
  {
    cssVal: 'bcPrivacyPolicyDescriptionFontColor',
    tileHeader: 'Description Font Color',
  },
]
