import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  Flex,
  FormControl as ChakraFormControl,
  FormLabel as ChakraFormLabel,
  FormLabel,
  Grid,
  HStack,
  IconButton,
  Image,
  Input,
  Select as ChakraSelect,
  SimpleGrid,
  Stack,
  Switch as ChakraSwitch,
  Text,
  Tooltip,
  VStack,
  Wrap,
  WrapItem,
  useToast,
} from '@chakra-ui/react'

import PaywallDialog from './parts/PaywallDialog'
import FocusTrapModal from './parts/FocusTrapModal'

import { whiteBotcopyIcons, blackBotcopyIcons } from './data/botcopyIcons'

import { uiByBotcopyThemes } from './data/uiByBotcopyThemes'

import fontFamily from './data/fontFamily'

import DefaultImage from './parts/DefaultImage'

import { generateThemeCss } from './utils/theme-styles'
import { playLiveChatEntrySound } from './utils/play-live-chat-entry-sound'
import { handleUiByBotcopyChange } from './utils/handle-ui-by-botcopy'
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  withStyles,
} from '@material-ui/core'

import CustomInput from './parts/CustomInput'

import {
  MdBrush as BrushRoundedIcon,
  MdClose as Close,
  MdExpandMore as ExpandMoreIcon,
  MdInfoOutline as InfoIcon,
  MdPlayArrow as PlayCircleOutlineRoundedIcon,
} from 'react-icons/md'
import { tooltipText } from './data/tooltip-text'
import ChatWidget from './parts/ChatWidget'

import { isUndefined } from 'lodash-es'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { ColorResult } from '../../../.types/react-color'
import { SAVE_DETECTOR_BAR_HEIGHT } from '../../components/saveDetector'
import { colors } from '../../hocs/withTheme'

import ImageSettings from './parts/ImageSettings'
import ColorSettings from './parts/ColorSettings'
import validateHex from './utils/validate-hex'

import { decimalToHex } from './utils/decimal-to-hex'

import ColorPickerModal from './parts/ColorPickerModal'
import { AppConfig } from '../../config'
import { Events, EventName } from '../../utils/gtm'
import CreditCardCapture from '../onboarding/CreditCardCapture'
import { parseUiByBotcopy } from './utils/parse-ui-by-botcopy'
import './branding.css'
import trackUserEvent from 'src/components/trackEvents'
import PreStyledThemesModal from './parts/PreStyledThemesModal'
import { styles } from './utils/styles'
import { handleThemeCssChange } from './utils/handle-theme-css-change'
import { handleNewPhoto } from './utils/handle-new-photo'
import { withRouter } from 'src/utils/withRouter'
import { useStore } from 'src/providers'
import { useParams } from 'react-router-dom'
import { useSaveDetector, SaveDetectorConfirmation } from '@botcopy/ui-shared'
import { toJS } from 'mobx'
import LoadingSpinner from 'src/components/LoadingSpinner'

const widgetBaseURL = AppConfig.get('/WidgetHostname')

const DimensionsWindowHeight = {
  Small: { label: 'Small', height: '560px', width: '405px' },
  Medium: { label: 'Medium', height: '635px', width: '461px' },
  Large: { label: 'Large', height: '695px', width: '505px' },
  XL: { label: 'XL', height: '742px', width: '535px' },
}

const Branding = () => {
  const [showDefaultImages, setShowDefaultImages] = useState(false)
  const [showPreStyledThemes, setShowPreStyledThemes] = useState(false)
  const [pickerColorChanged, setPickerColorChanged] = useState(false)
  const [cssToBeChanged, setCssToBeChanged] = useState('')
  const [imageToBeChanged, setImageToBeChanged] = useState('')
  const [currentHex, setCurrentHex] = useState('')
  const [displayPicker, setDisplayPicker] = useState(false)
  const [switchDialog, setSwitchDialog] = useState(false)
  const [enableFocusTrapDialog, setEnableFocusTrapDialog] = useState(false)
  const [previewBot, setPreviewBot] = useState(true)
  const [isSaveLoading, setIsSaveLoading] = useState(false)

  const toast = useToast()

  const store = useStore()
  const params = useParams()
  const currentBot = store.bots.currentBot
  const currentBotId = store.bots.currentBotId

  const initialValue = currentBot
    ? {
        css: toJS(currentBot.theme.css),
        defaultGreeterStyle: toJS(currentBot.theme.defaultGreeterStyle),
        images: toJS(currentBot.theme.images),
      }
    : { css: {}, defaultGreeterStyle: '', images: {} }

  const [formElements, setFormElements] = useState<any>(initialValue)

  useEffect(() => {
    if (!currentBot) return
    setFormElements({
      css: toJS(currentBot.theme.css),
      defaultGreeterStyle: toJS(currentBot.theme.defaultGreeterStyle),
      images: toJS(currentBot.theme.images),
    })
  }, [currentBot])

  const hasUnsavedChanges = useSaveDetector(initialValue, formElements)

  const handleSave = async () => {
    try {
      setIsSaveLoading(true)
      await currentBot.patchTheme({
        ...currentBot.theme,
        css: formElements.css,
        defaultGreeterStyle: formElements.defaultGreeterStyle,
        images: formElements.images,
      })

      _handleSnackbar('success', 'Your changes have been saved.')
    } catch (error) {
      _handleSnackbar(
        'error',
        'There was an error saving your changes. Please try again.',
      )
    } finally {
      setIsSaveLoading(false)
    }
  }

  useEffect(() => () => store.bots.setCurrentBotId(undefined), [store.bots])
  if (currentBotId !== params.botId) {
    store.bots.setCurrentBotId(params.botId)
    return null
  }

  const renderInput = (
    inputValue: string,
    name: string,
    label?: string,
    type?: string,
  ) => {
    const tooltip = tooltipText[name as keyof typeof tooltipText]

    return (
      <ChakraFormControl>
        <FormLabel
          as={Text}
          textStyle="body2"
          color={colors.lightGreyScale1100}
          mb={4}
          mx={0}
          textTransform="none"
        >
          <Flex justify="space-between">
            <Text textStyle="body2" color={colors.lightGreyScale1100}>
              {label}
            </Text>
            <Tooltip label={tooltip} placement={'top'} padding={5}>
              <div>
                <InfoIcon color={colors.lightGreyScale1100} size="20px" />
              </div>
            </Tooltip>
          </Flex>
        </FormLabel>
        <Input
          key={name}
          placeholder={'#4b60cc'}
          defaultValue={inputValue}
          onChange={(e) => {
            setFormElements((prev: any) => ({
              css: {
                ...prev.css,
                [name]: e.target.value,
              },
              defaultGreeterStyle: prev.defaultGreeterStyle,
              images: prev.images,
            }))
          }}
          type={type}
          style={{ width: '100%' }}
          h="56px"
        />
      </ChakraFormControl>
    )
  }

  const handleChange = async (
    event: any,
    name: string,
    handleAsNumber: boolean = false,
    type?: string,
  ): Promise<boolean> => {
    let value = event.target.value.trim()

    // Check if we are explicitly handling a string type
    if (type === 'string') {
      // Directly assign the value without additional checks
    } else if (handleAsNumber) {
      value = String(value)
      if (isNaN(Number(value))) {
        _handleSnackbar('error', 'Please enter a valid number.')
        return false
      }
    } else if (!validateHex(value)) {
      // For hex codes, ensure it starts with '#' and is a valid hex code
      value = value.startsWith('#') ? value : `#${value}`
      if (!validateHex(value)) {
        _handleSnackbar('error', 'Please enter a valid hexcode.')
        return false
      }
    }

    handleThemeCssChange({
      property: name,
      newValue: value,
      setFormElements,
    }).catch((e) => {
      console.error(`${name} Change Error`, e)
      _handleSnackbar(
        'error',
        'There was an error saving your change. Please try again.',
      )
    })

    return true
  }

  const _handleBooleanChange = async (
    cssVal: string,
    premiumFeature: boolean,
  ) => {
    const org = store?.organizations.current

    if (premiumFeature && org?.plan.planType === 'free') {
      setSwitchDialog(true)
      return
    }

    handleThemeCssChange({
      property: cssVal,
      // @ts-ignore
      newValue: !formElements.css[cssVal],
      setFormElements,
    })
  }

  const _closeDialog = () => {
    setSwitchDialog(false)
    setEnableFocusTrapDialog(false)
  }

  const _handlePreviewBot = async () => {
    setPreviewBot(!previewBot)
  }

  const _setUpMeeting = () => {
    const user = store?.users.me
    if (user) {
      setSwitchDialog(false)
      const url = `https://calendly.com/botcopy?name=${user.name}&email=${user.email}`
      window.open(url, '_blank')
    }
  }

  const _handleSnackbar = (type: string, message: string) => {
    const status = type === 'success' ? 'success' : 'error'

    toast({
      title: message,
      status: status as 'success' | 'error',
    })
  }

  const _showDefaultImages = (image: string) => {
    if (!showDefaultImages) {
      setShowDefaultImages(true)
      setImageToBeChanged(image)
    } else {
      setShowDefaultImages(false)
      setImageToBeChanged('')
    }
  }

  const closeImages = () => {
    setShowDefaultImages(false)
  }

  const _imageSelected = async (value: string) => {
    if (isUndefined(value)) return 'Image is undefined'

    const weblink = `${widgetBaseURL}/assets/images/defaultIcons/${value}`

    setFormElements((prev: any) => ({
      css: prev.css,
      defaultGreeterStyle: prev.defaultGreeterStyle,
      images: {
        ...prev.images,
        [imageToBeChanged]: weblink,
      },
    }))
    return closeImages()
  }

  const pickerOnChangeComplete = (color: ColorResult) => {
    setPickerColorChanged(true)
    setCurrentHex(`${color.hex}${decimalToHex(color.rgb.a)}`)
  }

  const handlePickerChange = async () => {
    if (!pickerColorChanged) {
      setDisplayPicker(false)
      return
    }

    handleThemeCssChange({
      property: cssToBeChanged,
      newValue: currentHex,
      setFormElements,
    })

    setDisplayPicker(false)
  }

  const renderPicker = (name: string, hexValue: string) => {
    setDisplayPicker(true)
    setPickerColorChanged(false)
    setCssToBeChanged(name)
    setCurrentHex(hexValue)
  }

  const _closePicker = () => {
    setDisplayPicker(false)
  }

  const _toggleEnableFocusTrapDialog = () => {
    if (formElements.css.bcEnableFocusTrap) {
      // Disable Focus Trap
      _handleBooleanChange('bcEnableFocusTrap', false)
    } else {
      // Ask user to confirm enabling Focus Trap
      setEnableFocusTrapDialog(true)
    }
  }

  const _confirmEnableFocusTrap = () => {
    // Enable Focus Trap
    _handleBooleanChange('bcEnableFocusTrap', false)
    setEnableFocusTrapDialog(false)
  }

  const handlePreStyledThemeClick = async (themeName: string) => {
    const themeCss = await generateThemeCss(themeName)

    setFormElements((prev: any) => ({
      css: {
        ...prev.css,
        ...themeCss,
      },
      defaultGreeterStyle: prev.defaultGreeterStyle,
      images: prev.images,
    }))
    setShowPreStyledThemes(false)
  }

  const _goToPlans = () => {
    const dataLayer = {
      event: Events.subscription.changePlan.viewPlans.type,
      eventName: Events.subscription.changePlan.viewPlans.eventName,
      eventCode: Events.subscription.changePlan.viewPlans.eventCode,
    }
    trackUserEvent(EventName.PortalView, dataLayer)
    window.open(`${process.env.PUBLIC_URL}/account?showPlans=true`, '_blank')
  }

  if (currentBot) {
    const org = store.organizations.current

    const me = store.users.me

    const onChangeUiByBotcopy = async (
      event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
      const value = event.target.value
      await handleUiByBotcopyChange(value, setFormElements)
    }

    if (!store || !currentBot || !org || !me) {
      return <LoadingSpinner />
    }

    return (
      <Flex w="100%" direction="column">
        {org.notifications.overageFreeGrace >= 5 &&
        org.balancesEngagementsTotal < org.plan.conversationLimit ? (
          <div className="onhold-banner">
            <Flex direction="column">
              <h1 className="onhold-banner-title">
                Your free trial with Botcopy is up!
              </h1>
              <h3 className="onhold-banner-subtitle">
                Upgrade now to one of our cost saving plans.
              </h3>
            </Flex>
            <a
              className="onhold-plan-button"
              href={`${process.env.PUBLIC_URL}/account?showPlans=true`}
            >
              Upgrade Plan
            </a>
          </div>
        ) : null}
        <Flex justify="flex-start" className="branding-top-bar" w="100%">
          {/* Pre-styled themes switch */}
          <Text
            className="show-themes-link"
            onClick={() => setShowPreStyledThemes(true)}
          >
            <BrushRoundedIcon fontSize="small" className="brush-icon" />{' '}
            Pre-Styled Themes
          </Text>
        </Flex>
        {/* master branding container */}
        <Grid
          height="100%"
          alignItems="center"
          style={{
            paddingBottom: SAVE_DETECTOR_BAR_HEIGHT, // needed for save bar bottom
          }}
        >
          <CreditCardCapture classes={{}} />
          {previewBot ? (
            <ChatWidget botId={currentBotId} widgetBaseURL={widgetBaseURL} />
          ) : null}

          <Flex
            direction="column"
            alignItems="center"
            w="100%"
            p={8}
            m="auto"
            maxW="1200px"
          >
            {/* Preview Bot Switch */}
            <Flex mb={6} width="100%">
              <ChakraFormControl>
                <HStack>
                  <ChakraSwitch
                    isChecked={previewBot}
                    onChange={_handlePreviewBot}
                  />
                  <ChakraFormLabel>Preview Bot</ChakraFormLabel>
                </HStack>
              </ChakraFormControl>
            </Flex>
            {/* Images row container */}
            <ImageSettings
              styles={styles}
              css={formElements.css}
              images={formElements.images}
              tooltipText={tooltipText}
              showDefaultImages={_showDefaultImages}
              handleGreeterSize={(event: any) =>
                handleThemeCssChange({
                  property: 'bcGreeterImageHeight',
                  newValue: event?.target?.value,
                  setFormElements,
                })
              }
              handleWidgetShape={(value: string) => {
                handleThemeCssChange({
                  property: 'bcWidgetShape',
                  newValue: value,
                  setFormElements,
                })
              }}
              handleNewPhoto={(event: any, photoType: any) =>
                handleNewPhoto(
                  event,
                  photoType,
                  currentBotId,
                  formElements,
                  setFormElements,
                  _handleSnackbar,
                )
              }
            />

            <ColorSettings
              org={org}
              styles={styles}
              botThemeFormElements={formElements}
              tooltipText={tooltipText}
              handleDefaultGreeterStyleChange={(event: any) =>
                handleThemeCssChange({
                  property: 'defaultGreeterStyle',
                  newValue: event.target.value,
                  setFormElements,
                })
              }
              renderInput={renderInput}
              renderPicker={renderPicker}
            />

            {/* Advanced Settings*/}
            <Flex direction="column" w="100%">
              <Text textStyle="h5" fontSize="28px" my={6}>
                Advanced Settings
              </Text>
              <Accordion
                as={Card}
                bg={colors.lightGreyScale100}
                allowToggle={true}
                fullWidth={true}
                border="1px solid"
                borderColor={colors.lightGreyScale800}
              >
                <AccordionItem>
                  <h2>
                    <AccordionButton
                      borderRadius={6}
                      border="none"
                      p={8}
                      h="auto"
                    >
                      <Box as="span" flex="1" textAlign="left">
                        <Text textStyle="h5" fontSize="21px">
                          Settings
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel p={8} pt={0}>
                    <Flex direction="column">
                      <Text textStyle="body2" mb={5}>
                        Greeter
                      </Text>
                      {/* GREETER ANIMATION  */}
                      <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={6} my={5}>
                        {/* Greeter Load-in Animation GRID ITEM */}

                        {/* Select Greeter Animation */}
                        <ChakraFormControl>
                          <FormLabel
                            as={Text}
                            color={colors.lightGreyScale1100}
                            mb={4}
                            mx={0}
                            textTransform="none"
                          >
                            <Flex justify="space-between">
                              <Text textStyle="body2">
                                Greeter Load-in Animation
                              </Text>
                              <Tooltip
                                label={tooltipText.bcGreeterAnimationName}
                                placement={'top'}
                                padding={5}
                              >
                                <div>
                                  <InfoIcon
                                    color={colors.lightGreyScale1100}
                                    size="20px"
                                  />
                                </div>
                              </Tooltip>
                            </Flex>
                          </FormLabel>
                          <ChakraSelect
                            value={formElements.css.bcGreeterAnimationName}
                            defaultValue={'centerExpand'}
                            onChange={(event) => {
                              handleThemeCssChange({
                                property: 'bcGreeterAnimationName',
                                newValue: event.target.value,
                                setFormElements,
                              })
                            }}
                            backgroundColor={colors.pureWhite}
                            h="56px"
                          >
                            <option value={'centerExpand'}>
                              Center Expand
                            </option>
                            <option value={'expandOutIn'}>
                              Center Expand Out In
                            </option>
                            <option value={'kablam'}>Kablam</option>
                            <option value={'rightCornerExpand'}>
                              Right Corner Expand
                            </option>
                            {/* <option value={'shadowGlow'}>
                                    Shadow Glow
                                  </option> */}
                            {/* <option value={'spin'}>Spin</option> */}
                            <option value={'mold'}>Mold</option>
                            <option value={'openEye'}>Open Eye</option>
                            <option value={'sideEye'}>Side Eye</option>
                            <option value={'slide'}>Slide</option>
                            <option value={'none'}>None</option>
                          </ChakraSelect>
                        </ChakraFormControl>

                        {/* Greeter Load-in Animation speed GRID ITEM */}

                        {/* Select Animation Speed */}
                        <ChakraFormControl>
                          <FormLabel
                            as={Text}
                            color={colors.lightGreyScale1100}
                            mx={0}
                            mb={4}
                            textTransform="none"
                          >
                            <Flex justify="space-between">
                              <Text
                                textStyle="body2"
                                color={colors.lightGreyScale1100}
                              >
                                Greeter Load-in Speed
                              </Text>
                              <Tooltip
                                label={tooltipText.bcGreeterAnimationSpeed}
                                placement={'top'}
                                padding={5}
                              >
                                <div>
                                  <InfoIcon
                                    color={colors.lightGreyScale1100}
                                    size="20px"
                                  />
                                </div>
                              </Tooltip>
                            </Flex>
                          </FormLabel>
                          <ChakraSelect
                            value={formElements.css.bcGreeterAnimationSpeed}
                            defaultValue={'250ms'}
                            onChange={(event) => {
                              handleThemeCssChange({
                                property: 'bcGreeterAnimationSpeed',
                                newValue: event.target.value,
                                setFormElements,
                              })
                            }}
                            backgroundColor={colors.pureWhite}
                            h="56px"
                          >
                            <option value={'900ms'}>Turtle</option>
                            <option value={'600ms'}>Super Slow</option>
                            <option value={'300ms'}>Slow</option>
                            <option value={'180ms'}>Medium</option>
                            <option value={'120ms'}>Fast</option>
                            <option value={'100ms'}>Very Fast</option>
                            <option value={'80ms'}>Rabbit</option>
                          </ChakraSelect>
                        </ChakraFormControl>

                        {/* Greeter Load-in Animation Timing Function GRID ITEM */}

                        {/* Select Animation Speed */}
                        <ChakraFormControl>
                          <FormLabel
                            as={Text}
                            color={colors.lightGreyScale1100}
                            mb={4}
                            mx={0}
                            textTransform="none"
                          >
                            <Flex justify="space-between">
                              <Text textStyle="body2">
                                Greeter Timing Function
                              </Text>
                              <Tooltip
                                label={
                                  tooltipText.bcGreeterAnimationTimingFunction
                                }
                                placement={'top'}
                                padding={5}
                              >
                                <div>
                                  <InfoIcon
                                    color={colors.lightGreyScale1100}
                                    size="20px"
                                  />
                                </div>
                              </Tooltip>
                            </Flex>
                          </FormLabel>
                          <ChakraSelect
                            value={
                              formElements.css.bcGreeterAnimationTimingFunction
                            }
                            defaultValue={'ease-in'}
                            onChange={(event) => {
                              handleThemeCssChange({
                                property: 'bcGreeterAnimationTimingFunction',
                                newValue: event.target.value,
                                setFormElements,
                              })
                            }}
                            backgroundColor={colors.pureWhite}
                            h="56px"
                          >
                            <option value={'linear'}>Linear</option>
                            <option value={'ease'}>Ease</option>
                            <option value={'ease-in'}>Ease-in</option>
                            <option value={'ease-out'}>Ease-out</option>
                            <option value={'ease-in-out'}>Ease-in-out</option>
                          </ChakraSelect>
                        </ChakraFormControl>
                      </SimpleGrid>
                    </Flex>
                    <div className="divider" />
                    <Flex direction="column">
                      <Text textStyle="body2" my={5}>
                        Prompts
                      </Text>
                      <Wrap my={5} spacing={6}>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack mr={6}>
                              <ChakraSwitch
                                isChecked={formElements.css.bcShowGreeter}
                                onChange={() =>
                                  _handleBooleanChange('bcShowGreeter', false)
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                alignItems="center"
                                textTransform="none"
                              >
                                Display Greeter and Prompts{''}
                                <Tooltip
                                  label={tooltipText.displayGreeter}
                                  placement={'top'}
                                  padding={5}
                                >
                                  <div>
                                    <InfoIcon
                                      color={colors.lightGreyScale1100}
                                      size="20px"
                                    />
                                  </div>
                                </Tooltip>
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack mr={6}>
                              <ChakraSwitch
                                isChecked={
                                  !formElements.css.bcShowGreeter
                                    ? false
                                    : formElements.css.bcShowPrompts
                                }
                                isDisabled={!formElements.css.bcShowGreeter}
                                onChange={() =>
                                  _handleBooleanChange('bcShowPrompts', false)
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                fontSize="11px"
                              >
                                Display Prompts
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack>
                              <ChakraSwitch
                                isChecked={
                                  formElements.css.bcShowPromptSuggestions
                                }
                                onChange={() =>
                                  _handleBooleanChange(
                                    'bcShowPromptSuggestions',
                                    false,
                                  )
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                fontSize="11px"
                              >
                                Display Suggestion Chips under Prompts
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                      </Wrap>
                    </Flex>
                    <div className="divider" />

                    <Flex direction="column">
                      <Text textStyle="body2" my={5}>
                        Chat Window General Settings
                      </Text>

                      <SimpleGrid
                        columns={{ sm: 2, md: 3 }}
                        spacing={6}
                        my={5}
                        alignItems="end"
                      >
                        {/* Chat Window Type */}
                        <ChakraFormControl>
                          <FormLabel
                            as={Text}
                            color={colors.lightGreyScale1100}
                            mb={4}
                            mx={0}
                            textTransform="none"
                          >
                            <Text textStyle="body2">Window Style</Text>
                          </FormLabel>
                          <ChakraSelect
                            value={formElements.css.bcChatWindowType}
                            onChange={(e) => {
                              handleThemeCssChange({
                                property: 'bcChatWindowType',
                                newValue: e.target.value,
                                setFormElements,
                              })
                            }}
                            backgroundColor={colors.pureWhite}
                            h="56px"
                          >
                            <option value={'classic'}>Classic</option>
                            <option value={'contained'}>Contained</option>
                            <option value={'fullscreen'}>Full-screen</option>
                            <option value={'sidebar'}>Sidebar</option>
                          </ChakraSelect>
                        </ChakraFormControl>
                        {/* Size  */}
                        {formElements.css.bcChatWindowType ===
                        'fullscreen' ? null : (
                          <ChakraFormControl>
                            <FormLabel
                              as={Text}
                              color={colors.lightGreyScale1100}
                              mb={4}
                              mx={0}
                              textTransform="none"
                            >
                              <Text textStyle="body2">Size</Text>
                            </FormLabel>
                            <ChakraSelect
                              value={formElements.css.bcDimensionsWindowHeight}
                              onChange={(event: any) => {
                                const value = event.target.value

                                const selectedDimension = Object.values(
                                  DimensionsWindowHeight,
                                ).find((dim) => dim.height === value)

                                if (selectedDimension) {
                                  handleThemeCssChange({
                                    property: 'bcDimensionsWindowHeight',
                                    newValue: selectedDimension.height,
                                    setFormElements,
                                  })
                                  handleThemeCssChange({
                                    property: 'bcDimensionsWindowWidth',
                                    newValue: selectedDimension.width,
                                    setFormElements,
                                  })
                                }
                              }}
                              backgroundColor={colors.pureWhite}
                              h="56px"
                            >
                              <option
                                value={DimensionsWindowHeight.Small.height}
                              >
                                {DimensionsWindowHeight.Small.label}
                              </option>
                              <option
                                value={DimensionsWindowHeight.Medium.height}
                              >
                                {DimensionsWindowHeight.Medium.label}
                              </option>
                              <option
                                value={DimensionsWindowHeight.Large.height}
                              >
                                {DimensionsWindowHeight.Large.label}
                              </option>
                              <option value={DimensionsWindowHeight.XL.height}>
                                {DimensionsWindowHeight.XL.label}
                              </option>
                            </ChakraSelect>
                          </ChakraFormControl>
                        )}
                        <CustomInput
                          inputValue={String(formElements.css.bcZIndex)}
                          name={'bcZIndex'}
                          handleAsNumber={true}
                          label={'zIndex'}
                          type={'number'}
                          onChange={handleChange}
                          tooltipText={tooltipText.bcZIndex}
                        />
                        {/* Sidebar Options  */}
                        {formElements.css.bcChatWindowType === 'sidebar' ? (
                          <>
                            <ChakraFormControl>
                              <FormLabel
                                as={Text}
                                color={colors.lightGreyScale1100}
                                mb={4}
                                mx={0}
                                textTransform="none"
                              >
                                <Text textStyle="body2">Border Type</Text>
                              </FormLabel>
                              <RadioGroup
                                aria-label="window-height-radio"
                                value={formElements.css.bcSidebarBorderType}
                                onChange={(event, value) => {
                                  handleThemeCssChange({
                                    property: 'bcSidebarBorderType',
                                    newValue: value,
                                    setFormElements,
                                  })
                                }}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <FormControlLabel
                                  value="box-shadow"
                                  control={<Radio />}
                                  label="Box Shadow"
                                />
                                <FormControlLabel
                                  value="border-image"
                                  control={<Radio />}
                                  label="Border Image"
                                />
                              </RadioGroup>
                            </ChakraFormControl>
                            <>
                              <CustomInput
                                inputValue={formElements.css.bcSidebarMarginTop}
                                name={'bcSidebarMarginTop'}
                                handleAsNumber={true}
                                label={'Margin Top'}
                                type={'number'}
                                onChange={handleChange}
                                tooltipText={tooltipText.bcSidebarMarginTop}
                              />
                            </>
                            {formElements.css.bcSidebarBorderType ===
                              'border-image' && (
                              // TODO: *Important* fix this before merging
                              <CustomInput
                                inputValue={
                                  formElements.css
                                    .bcSidebarBorderImageLinearGradient
                                }
                                name={'bcSidebarBorderImageLinearGradient'}
                                handleAsNumber={false}
                                label={'Linear Gradient'}
                                type={'string'}
                                onChange={handleChange}
                                tooltipText={
                                  tooltipText.bcSidebarBorderImageLinearGradient
                                }
                              />
                            )}
                          </>
                        ) : null}
                      </SimpleGrid>

                      {/* FONT TYPE GRID ITEM */}
                      <SimpleGrid
                        columns={{ sm: 2, md: 3 }}
                        spacing={6}
                        my={5}
                        alignItems="end"
                      >
                        {/* Select Font Type */}

                        <ChakraFormControl>
                          <FormLabel
                            as={Text}
                            color={colors.lightGreyScale1100}
                            mb={4}
                            mx={0}
                            textTransform="none"
                          >
                            <Flex justify="space-between">
                              <Text textStyle="body2">Font Type</Text>
                              <Tooltip
                                label={tooltipText.fontFamily}
                                placement={'top'}
                                padding={5}
                              >
                                <div>
                                  <InfoIcon
                                    color={colors.lightGreyScale1100}
                                    size="20px"
                                  />
                                </div>
                              </Tooltip>
                            </Flex>
                          </FormLabel>
                          <ChakraSelect
                            height="56px"
                            onChange={(e) =>
                              handleThemeCssChange({
                                property: 'bcFontType',
                                newValue: e.target.value,
                                setFormElements,
                              })
                            }
                            backgroundColor={colors.pureWhite}
                          >
                            {Object.entries(fontFamily).map(
                              ([category, fonts]) => (
                                <optgroup label={category} key={category}>
                                  {fonts.map((font) => (
                                    <option value={font} key={font}>
                                      {font}
                                    </option>
                                  ))}
                                </optgroup>
                              ),
                            )}
                          </ChakraSelect>
                        </ChakraFormControl>
                      </SimpleGrid>
                      <Wrap my={5} spacing={6} alignItems="center">
                        <WrapItem>
                          {/* Use website font  */}
                          <ChakraFormControl my={5}>
                            <HStack mr={6}>
                              <ChakraSwitch
                                isChecked={
                                  formElements.css.bcFontTypeSitePriority
                                }
                                onChange={() =>
                                  _handleBooleanChange(
                                    'bcFontTypeSitePriority',
                                    false,
                                  )
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Prioritize Website Font
                              </FormLabel>
                              <Tooltip
                                label={tooltipText.bcFontTypeSitePriority}
                                placement={'top'}
                                padding={5}
                              >
                                <div>
                                  <InfoIcon
                                    color={colors.lightGreyScale1100}
                                    size="20px"
                                  />
                                </div>
                              </Tooltip>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                        <WrapItem>
                          {/* Focus Trap  */}
                          <ChakraFormControl my={5}>
                            <HStack mr={6}>
                              <ChakraSwitch
                                isChecked={formElements.css.bcEnableFocusTrap}
                                onChange={_toggleEnableFocusTrapDialog}
                              />
                              <FormLabel
                                as={Text}
                                color={colors.lightGreyScale1100}
                                display="flex"
                              >
                                <Text textStyle="caption" fontSize="11px">
                                  Focus Trap
                                </Text>
                              </FormLabel>
                              <Tooltip
                                label={tooltipText.bcEnableFocusTrap}
                                placement={'top'}
                                padding={5}
                              >
                                <div>
                                  <InfoIcon
                                    color={colors.lightGreyScale1100}
                                    size="20px"
                                  />
                                </div>
                              </Tooltip>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                      </Wrap>
                    </Flex>
                    <div className="divider" />

                    {/* Sounds  */}

                    <Flex direction="column">
                      <Text textStyle="body2" my={5}>
                        Sounds
                      </Text>
                      <HStack
                        my={5}
                        spacing={4}
                        shouldWrapChildren={true}
                        alignItems="end"
                      >
                        {/* Live chat sounds*/}
                        <ChakraFormControl width="288px">
                          <FormLabel
                            as={Text}
                            textStyle="body2"
                            color={colors.lightGreyScale1100}
                            mb={4}
                            mx={0}
                            textTransform="none"
                          >
                            <Flex justify="space-between">
                              <Text textStyle="body2">Live Chat Entry</Text>
                              <Tooltip
                                label={tooltipText.bcLiveChatEntrySound}
                                placement={'top'}
                                padding={5}
                              >
                                <div>
                                  <InfoIcon
                                    color={colors.lightGreyScale1100}
                                    size="20px"
                                  />
                                </div>
                              </Tooltip>
                            </Flex>
                          </FormLabel>
                          <ChakraSelect
                            value={formElements.css.bcLiveChatEntrySound}
                            onChange={(e) => {
                              handleThemeCssChange({
                                property: 'bcLiveChatEntrySound',
                                newValue: e.target.value,
                                setFormElements,
                              })
                            }}
                            backgroundColor={colors.pureWhite}
                            h="56px"
                          >
                            <option value={'sound1'}>Sound 1</option>
                            <option value={'sound2'}>Sound 2</option>
                          </ChakraSelect>
                        </ChakraFormControl>
                        <IconButton
                          aria-label="play sound"
                          variant="light"
                          isRound={true}
                          size="sm"
                          h="32px"
                          bg={colors.botcopyBlue}
                          onClick={() =>
                            playLiveChatEntrySound(
                              formElements.css.bcLiveChatEntrySound,
                            )
                          }
                          icon={
                            <PlayCircleOutlineRoundedIcon
                              color={colors.pureWhite}
                            />
                          }
                          mb="12px"
                        />
                      </HStack>
                    </Flex>
                    <div className="divider" />

                    {/* Header Grid  */}
                    <Flex direction="column" className="settings-container">
                      {/* Header Title  */}

                      <Text textStyle="body2" my={5}>
                        Header
                      </Text>

                      {/* HEADER TYPE GRID ITEM */}
                      <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={6} my={5}>
                        {/* Select Header Type */}
                        <ChakraFormControl>
                          <FormLabel
                            as={Text}
                            color={colors.lightGreyScale1100}
                            mb={4}
                            mx={0}
                            textTransform="none"
                          >
                            <Flex justify="space-between">
                              <Text textStyle="body2">Type</Text>
                              <Tooltip
                                label={tooltipText.bcHeaderType}
                                placement={'top'}
                                padding={5}
                              >
                                <div>
                                  <InfoIcon
                                    color={colors.lightGreyScale1100}
                                    size="20px"
                                  />
                                </div>
                              </Tooltip>
                            </Flex>
                          </FormLabel>
                          <ChakraSelect
                            value={formElements.css.bcHeaderType}
                            onChange={(e) => {
                              handleThemeCssChange({
                                property: 'bcHeaderType',
                                newValue: e.target.value,
                                setFormElements,
                              })
                            }}
                            backgroundColor={colors.pureWhite}
                            h="56px"
                          >
                            <option value={'classic'}>Classic</option>
                            {/* <option value={'modern'}>Modern</option> */}
                            <option value={'rich'}>Rich</option>
                          </ChakraSelect>
                        </ChakraFormControl>

                        {formElements.css.bcHeaderType === 'rich' ? (
                          <>
                            {/* Header Image Grid */}
                            <VStack>
                              <Flex
                                justify="space-between"
                                alignItems="center"
                                w="100%"
                                mb={4}
                                mx={0}
                              >
                                <Text
                                  textStyle="body2"
                                  color={colors.lightGreyScale1100}
                                >
                                  Header Image
                                </Text>
                                <Tooltip
                                  label={tooltipText.headerImage}
                                  placement={'top'}
                                  padding={5}
                                >
                                  <span>
                                    <InfoIcon
                                      color={colors.lightGreyScale1100}
                                      size="20px"
                                    />
                                  </span>
                                </Tooltip>
                              </Flex>
                              <Stack direction="column" w="100%">
                                <Flex
                                  justify="space-between"
                                  alignItems="flex-end"
                                >
                                  <Box
                                    backgroundColor={
                                      formElements.css.bcHeaderFillColor
                                    }
                                    className="header-image-background"
                                  >
                                    <Image
                                      src={formElements.images.headerImg}
                                      alt="header image"
                                      className="greeter-shapes"
                                      height={
                                        formElements.css.bcGreeterImageHeight
                                      }
                                      width={
                                        formElements.css.bcGreeterImageWidth
                                      }
                                      objectFit="contain"
                                    />
                                  </Box>

                                  {/* Header Upload Image */}

                                  <label htmlFor="contained-button-file-header-img">
                                    <Button
                                      as="span"
                                      cursor="pointer"
                                      size="sm"
                                      fontSize="sm"
                                      variant="light"
                                    >
                                      Choose File
                                    </Button>
                                  </label>
                                  <input
                                    accept="image/*"
                                    id="contained-button-file-header-img"
                                    multiple={false}
                                    type="file"
                                    hidden={true}
                                    onChange={(e) =>
                                      handleNewPhoto(
                                        e,
                                        'headerImg',
                                        currentBotId,
                                        formElements,
                                        setFormElements,
                                        _handleSnackbar,
                                      )
                                    }
                                  />
                                </Flex>
                                <Flex
                                  className="presetIconsButton"
                                  alignItems="center"
                                  onClick={() =>
                                    _showDefaultImages('headerImg')
                                  }
                                >
                                  Icons
                                  <ExpandMoreIcon />
                                </Flex>
                              </Stack>
                            </VStack>
                            <CustomInput
                              inputValue={formElements.css.bcHeaderSubtitle}
                              name={'bcHeaderSubtitle'}
                              handleAsNumber={false}
                              label={'Subtitle'}
                              type={'string'}
                              onChange={handleChange}
                              tooltipText={tooltipText.headerSubtitle}
                            />
                          </>
                        ) : null}
                      </SimpleGrid>

                      <Wrap my={5} spacing={6}>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack>
                              <ChakraSwitch
                                isChecked={
                                  formElements.css.bcShowMaximizeButton
                                }
                                onChange={() =>
                                  _handleBooleanChange(
                                    'bcShowMaximizeButton',
                                    false,
                                  )
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Display Maximize Icon
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack>
                              <ChakraSwitch
                                isChecked={
                                  formElements.css.bcShowMoreOptionsMenu
                                }
                                onChange={() =>
                                  _handleBooleanChange(
                                    'bcShowMoreOptionsMenu',
                                    false,
                                  )
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Display More Options Menu
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack>
                              <ChakraSwitch
                                isChecked={
                                  formElements.css.bcShowPrivacyPolicyMenuItem
                                }
                                onChange={(e) =>
                                  _handleBooleanChange(
                                    'bcShowPrivacyPolicyMenuItem',
                                    false,
                                  )
                                }
                                isDisabled={
                                  !formElements.css.bcShowMoreOptionsMenu ||
                                  !currentBot.theme.privacyPolicy
                                    .bcShowPrivacyPolicy
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Display Privacy Policy Menu Item
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack>
                              <ChakraSwitch
                                isChecked={
                                  formElements.css
                                    .bcShowClearConversationMenuItem
                                }
                                onChange={() =>
                                  _handleBooleanChange(
                                    'bcShowClearConversationMenuItem',
                                    false,
                                  )
                                }
                                isDisabled={
                                  !formElements.css.bcShowMoreOptionsMenu
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Display Clear Conversation Menu Item
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                      </Wrap>
                    </Flex>
                    <div className="divider" />

                    <Flex direction="column" my={5}>
                      {/* Body Title  */}

                      <Flex direction="column">
                        <Text textStyle="body2" my={5}>
                          Body
                        </Text>
                        <SimpleGrid
                          columns={{ sm: 2, md: 3 }}
                          my={5}
                          spacing={6}
                          alignItems="end"
                        >
                          <ChakraFormControl>
                            <FormLabel
                              as={Text}
                              color={colors.lightGreyScale1100}
                              mb={4}
                              mx={0}
                              textTransform="none"
                            >
                              <Flex justify="space-between">
                                <Text textStyle="body2">
                                  Text Alignment of Card Buttons
                                </Text>
                                <Tooltip
                                  label={tooltipText.cardButtonTextAlignment}
                                  placement={'top'}
                                  padding={5}
                                >
                                  <div>
                                    <InfoIcon
                                      color={colors.lightGreyScale1100}
                                      size="20px"
                                    />
                                  </div>
                                </Tooltip>
                              </Flex>
                            </FormLabel>
                            <ChakraSelect
                              value={formElements.css.bcCardButtonTextAlignment}
                              onChange={(event) => {
                                handleThemeCssChange({
                                  property: 'bcCardButtonTextAlignment',
                                  newValue: event.target.value,
                                  setFormElements,
                                })
                              }}
                              backgroundColor={colors.pureWhite}
                              h="56px"
                            >
                              <option value={'left'}>Left</option>
                              <option value={'center'}>Center</option>
                              <option value={'right'}>Right</option>
                            </ChakraSelect>
                          </ChakraFormControl>

                          {/* Botcopy Logo Select */}
                          <VStack>
                            <Flex
                              justify="space-between"
                              alignItems="center"
                              w="100%"
                              mb={4}
                              mx={0}
                            >
                              <Text
                                textStyle="body2"
                                color={colors.lightGreyScale1100}
                              >
                                Logo Style
                              </Text>
                              <Tooltip
                                label={tooltipText.bcUiByBotcopy}
                                placement={'top'}
                                padding={5}
                              >
                                <div>
                                  <InfoIcon
                                    color={colors.lightGreyScale1100}
                                    size="20px"
                                  />
                                </div>
                              </Tooltip>
                            </Flex>

                            <FormControl fullWidth={true}>
                              <Select
                                disabled={
                                  !formElements.css.bcShowPoweredByBotcopy
                                }
                                value={(() => {
                                  const parseUI = parseUiByBotcopy(
                                    formElements.css,
                                  )
                                  console.log({
                                    parseUI,
                                    css: formElements.css,
                                  })
                                  return parseUI || 'darkBlue'
                                })()}
                                autoWidth={true}
                                color={colors.pureWhite}
                                onChange={onChangeUiByBotcopy}
                                MenuProps={{
                                  disableAutoFocusItem: true,
                                }}
                                SelectDisplayProps={{
                                  style: {
                                    display: 'flex',
                                    height: '35px',
                                  },
                                }}
                              >
                                {uiByBotcopyThemes.map((theme) => (
                                  <MenuItem
                                    value={theme.value}
                                    key={theme.value}
                                  >
                                    <img
                                      className="ui-by-img"
                                      src={`${process.env.PUBLIC_URL}${theme.imagePath}`}
                                      alt={theme.label}
                                    />
                                    {theme.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </VStack>
                        </SimpleGrid>
                      </Flex>
                      <Wrap my={5} spacing={6}>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack mr={6}>
                              <ChakraSwitch
                                isChecked={formElements.css.bcShowAvatar}
                                onChange={() =>
                                  _handleBooleanChange('bcShowAvatar', false)
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Display Bot Avatar
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>{' '}
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack mr={6}>
                              <ChakraSwitch
                                isChecked={
                                  formElements.css.bcShowPoweredByBotcopy
                                }
                                onChange={() =>
                                  _handleBooleanChange(
                                    'bcShowPoweredByBotcopy',
                                    true,
                                  )
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Display Botcopy Logo
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                      </Wrap>
                    </Flex>
                    <div className="divider" />

                    {/* Input Bar  */}
                    <Flex direction="column">
                      <Text textStyle="body2" my={5}>
                        Input Bar
                      </Text>

                      {/* Botcopy Input Bar Select */}

                      <ChakraFormControl my={5} width="288px">
                        <FormLabel
                          as={Text}
                          color={colors.lightGreyScale1100}
                          mb={4}
                          mx={0}
                          textTransform="none"
                        >
                          <Flex justify="space-between">
                            <Text textStyle="body2">Input Bar Style</Text>
                            <Tooltip
                              label={tooltipText.bcInputBarStyle}
                              placement={'top'}
                              padding={5}
                            >
                              <div>
                                <InfoIcon
                                  color={colors.lightGreyScale1100}
                                  size="20px"
                                />
                              </div>
                            </Tooltip>
                          </Flex>
                        </FormLabel>
                        <ChakraSelect
                          value={formElements.css.bcInputBarStyle}
                          defaultValue={'classic'}
                          onChange={(e) => {
                            handleThemeCssChange({
                              property: 'bcInputBarStyle',
                              newValue: e.target.value,
                              setFormElements,
                            })
                          }}
                          backgroundColor={colors.pureWhite}
                          h="56px"
                        >
                          <option value={'minimal'}>Minimal</option>
                          <option value={'classic'}>Classic</option>
                          {/* <option value={'Modern'}>Modern</option> */}
                          {/* <option value={'Future'}>Futuristic</option> */}
                        </ChakraSelect>
                      </ChakraFormControl>

                      <Wrap my={5} spacing={6}>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack mr={6}>
                              <ChakraSwitch
                                isChecked={formElements.css.bcShowMenu}
                                onChange={() =>
                                  _handleBooleanChange('bcShowMenu', false)
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Display Menu
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack mr={6}>
                              <ChakraSwitch
                                isChecked={formElements.css.bcShowMicrophone}
                                onChange={() =>
                                  _handleBooleanChange(
                                    'bcShowMicrophone',
                                    false,
                                  )
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Display Microphone
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                      </Wrap>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Flex>
          </Flex>
          {/* Greeter & Avatar Preset Icons */}
          {showDefaultImages && imageToBeChanged ? (
            <div className="presetIconsParent">
              <div className="presetIconsContainer">
                <Close onClick={closeImages} style={styles.settingsClose} />
                <Text className="settingsTitle">Botcopy Icons</Text>
                <Stack justify="center" alignItems="center" direction="row">
                  <Box
                    backgroundColor={colors.darkGreyBlue}
                    padding="5px"
                    marginTop="5px"
                  >
                    {whiteBotcopyIcons.map((image) => (
                      <DefaultImage
                        key={image.fileName}
                        fileName={image.fileName}
                        onImageSelected={_imageSelected}
                      />
                    ))}
                  </Box>
                  <Box
                    backgroundColor={colors.offWhite}
                    padding="5px"
                    marginTop="5px"
                  >
                    {blackBotcopyIcons.map((image) => (
                      <DefaultImage
                        key={image.fileName}
                        fileName={image.fileName}
                        onImageSelected={_imageSelected}
                      />
                    ))}
                  </Box>
                </Stack>
              </div>
            </div>
          ) : null}

          <ColorPickerModal
            isOpen={displayPicker}
            onClose={_closePicker}
            currentHex={currentHex}
            onChangeComplete={pickerOnChangeComplete}
            onConfirm={handlePickerChange}
            loading={false}
          />

          <PreStyledThemesModal
            isOpen={showPreStyledThemes}
            onClose={() => setShowPreStyledThemes(false)}
            onPreStyledThemeClick={handlePreStyledThemeClick}
          />

          <PaywallDialog
            isOpen={switchDialog}
            onClose={_closeDialog}
            goToPlans={_goToPlans}
            setUpMeeting={_setUpMeeting}
          />

          <FocusTrapModal
            isOpen={enableFocusTrapDialog}
            onClose={_closeDialog}
            confirmEnableFocusTrap={_confirmEnableFocusTrap}
          />
        </Grid>
        <SaveDetectorConfirmation
          hasUnsavedChanges={hasUnsavedChanges}
          onSave={handleSave}
          left={store.session.isNavDrawerOpen ? '350px' : '71px'}
          width={
            store.session.isNavDrawerOpen
              ? 'calc(100% - 350px)'
              : 'calc(100% - 71px)'
          }
          isSaveLoading={isSaveLoading}
        />
      </Flex>
    )
  }

  return <LoadingSpinner />
}

export default withRouter(withStyles(styles)(observer(Branding)))
