import React from 'react'
import {
  FormControl,
  FormLabel,
  Input,
  VStack,
  FormErrorMessage,
} from '@chakra-ui/react'

interface BotProfileFormProps {
  formValues: {
    bcShowBotProfile: boolean
    bcBotProfileEmail: string
    bcBotProfilePhone: string
  }
  handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handlePhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleEmailBlur: () => void
  handlePhoneBlur: () => void
  tooltipText: {
    showProfileToggle: string
  }
  validation: {
    isEmailValid: boolean
    isPhoneValid: boolean
  }
}

const BotProfileForm: React.FC<BotProfileFormProps> = ({
  formValues,
  handleEmailChange,
  handlePhoneChange,
  handleEmailBlur,
  handlePhoneBlur,
  tooltipText,
  validation,
}) => {
  return (
    <VStack spacing={4} align="stretch" width="100%">
      <FormControl isInvalid={!validation.isEmailValid}>
        <FormLabel>Email</FormLabel>
        <Input
          value={formValues.bcBotProfileEmail}
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
          placeholder="Email"
          type="email"
        />
        {!validation.isEmailValid && (
          <FormErrorMessage>
            Please enter a valid email address.
          </FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={!validation.isPhoneValid}>
        <FormLabel>Phone</FormLabel>
        <Input
          value={formValues.bcBotProfilePhone}
          onChange={handlePhoneChange}
          onBlur={handlePhoneBlur}
          placeholder="Phone"
          type="number"
        />
        {!validation.isPhoneValid && (
          <FormErrorMessage>
            Please enter a valid phone number.
          </FormErrorMessage>
        )}
      </FormControl>
    </VStack>
  )
}

export default BotProfileForm
