import 'src/views/dashboard/Components/OnboardingForm/OnboardingForm.css'

import { inject, observer } from 'mobx-react'
import React from 'react'
import trackUserEvent from 'src/components/trackEvents'
import { colors } from 'src/hocs/withTheme'
import { RootStore } from 'src/models/root-store/root.store'
import { Events } from 'src/utils/gtm'
import CheckoutForm from 'src/views/stripe/checkout'
import { PlanType } from 'src/views/stripe/interfaces'
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'

import { stripePromise } from '../../stripe'

interface ICreditCardCaptureProps {
  store?: RootStore
  classes: any
}

interface ICreditCardCaptureState {
  open: boolean
}

@inject('store')
@observer
class CreditCardCapture extends React.Component<
  ICreditCardCaptureProps,
  ICreditCardCaptureState
> {
  constructor(props: ICreditCardCaptureProps) {
    super(props)
    this.state = { open: false }
  }

  public componentDidMount() {
    const org = this.props.store?.organizations.current
    const url = new URL(window.location.href)
    const ccTrial = url.searchParams.get('ccTrial')

    if (!org) return

    // open if fresh org on step 12 or end of trial
    if (
      ccTrial === 'true' ||
      (org.notifications.overageFreeGrace >= 5 &&
        org.balancesEngagementsTotal < org.plan.conversationLimit)
    ) {
      this.setState({ open: true })
    }
  }

  public render() {
    const { classes, store } = this.props
    const org = this.props.store?.organizations.current
    if (!org) return

    return (
      <Dialog
        open={this.state.open}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        maxWidth={false}
        PaperProps={{
          style: {
            backgroundColor: colors.darkGreyBlue,
            overflowY: 'hidden',
            borderBottom: '5px solid #238ae4',
          },
        }}
      >
        <DialogTitle>
          <div style={{ display: 'flex' }}>
            <div className="onboarding-dialog-title" style={{ flexGrow: 1 }}>
              Provide a card to continue.
            </div>

            <CloseRounded
              style={{ color: '#fff', cursor: 'pointer' }}
              onClick={() => {
                this.setState({ open: false })
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            padding: 0,
            backgroundColor: colors.offWhite,
            overflowY: 'hidden',
            display: 'flex',
            minHeight: '400px',
            minWidth: '1000px',
          }}
        >
          <Grid
            container={true}
            direction="row"
            alignItems="center"
            justify="flex-start"
          >
            <Grid
              container={true}
              direction="column"
              justify="space-between"
              alignItems="center"
              className="onboarding-board-grid"
            >
              <iframe
                width="396"
                height="225"
                allowFullScreen={true}
                src="https://www.youtube.com/embed/lvyWoOs2Ix8"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write;
                     encrypted-media; gyroscope; picture-in-picture; controls;"
              />
            </Grid>
          </Grid>

          <div className="stripe-grid">
            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({ stripe, elements }) => (
                  <CheckoutForm
                    stripe={stripe}
                    elements={elements}
                    classes={classes}
                    store={store}
                    checkoutSuccess={this._checkoutSuccess}
                    plan={PlanType.STANDARD_MONTHLY_V3}
                  />
                )}
              </ElementsConsumer>
            </Elements>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  private _checkoutSuccess = async () => {
    const org = this.props.store?.organizations.current
    if (org) {
      await org.setCreditCardCaptured()
      const dataLayer = {
        event: Events.creditCardCapture.success.type,
        eventName: Events.creditCardCapture.success.eventName,
        eventCode: Events.creditCardCapture.success.eventCode,
      }
      trackUserEvent('Initial CC Swipe', dataLayer)
      window.open(`${process.env.PUBLIC_URL}/account?showPlans=true`, '_self')
    }
    return
  }
}

export default CreditCardCapture
