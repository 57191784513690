import { AppConfig } from 'src/config'
import { getBucket, postToS3 } from '../../../api'
import uuid from 'uuid/v4'
const botAssetsS3BucketBaseUrl = AppConfig.get('/botAssetsS3BucketBaseUrl')

type SnackbarHandler = (type: 'success' | 'error', message: string) => void

export async function handleNewPhoto(
  event: React.ChangeEvent<HTMLInputElement>,
  photoType: string,
  currentBotId: string,
  formElements: any,
  setFormElements: any,
  handleSnackbar: SnackbarHandler,
  retrying: boolean = false,
): Promise<void> {
  const newPhoto = event.target.files && event.target.files[0]
  if (!newPhoto) {
    // User cancelled the file dialog
    return
  }

  const filenameRegexRes = /\.[0-9a-z]+$/i.exec(newPhoto.name)
  const newFileExt = filenameRegexRes ? filenameRegexRes[0] : '.jpg'
  const newFileName = `${uuid()}${newFileExt}`

  const path = `bots/${currentBotId}/${newFileName}`
  const weblink = `${botAssetsS3BucketBaseUrl.key}${path}`

  try {
    let { newFileSignedUrl } = await getBucket(currentBotId, newFileName)

    if (newFileSignedUrl === 'https://s3.amazonaws.com/') {
      const retry = await getBucket(currentBotId, newFileName)
      newFileSignedUrl = retry.newFileSignedUrl
    }

    const uploadResponse = await postToS3(newFileSignedUrl, newPhoto)
    if (uploadResponse.status === 200) {
      setFormElements((prev: any) => ({
        css: prev.css,
        defaultGreeterStyle: prev.defaultGreeterStyle,
        images: {
          ...prev.images,
          [photoType]: weblink,
        },
      }))
    } else {
      throw new Error('Failed to upload photo to S3')
    }
  } catch (error) {
    console.error('Error uploading new photo:', error)
    if (!retrying) {
      // Attempt to retry once
      return handleNewPhoto(
        event,
        photoType,
        currentBotId,
        formElements,
        setFormElements,
        handleSnackbar,
        true,
      )
    }
    handleSnackbar(
      'error',
      'There was an error saving your change. Please try again.',
    )
  }
}
