import { useCallback } from 'react'

import {
  BotMenuModelType,
  EditBotMenuInput,
  EditBotMenuInputTypeName,
  useQuery,
} from '@botcopy/utils-shared'
import { useStore } from 'src/providers'

export const useEditBotMenu = () => {
  const store = useStore()

  const {
    data: editBotMenuData,
    query: editBotMenuQuery,
    setQuery: editBotMenuSetQuery,
    loading: editBotMenuLoading,
    error: editBotMenuError,
  } = useQuery<{
    editBotMenu: BotMenuModelType
  }>()

  const editBotMenuSendData = useCallback(
    (input: Omit<EditBotMenuInput, 'typename'>) => {
      editBotMenuSetQuery(() =>
        store.api.mutateEditBotMenu(
          {
            input: {
              ...input,
              typename: EditBotMenuInputTypeName.EditBotMenuInput,
            },
          },
          (botMenuSelect) =>
            botMenuSelect._id.name.headers((headerSelect) => headerSelect),
        ),
      )
    },
    [editBotMenuSetQuery, store.api],
  )

  return {
    editBotMenuData: editBotMenuData?.editBotMenu,
    editBotMenuQuery,
    editBotMenuSendData,
    editBotMenuLoading,
    editBotMenuError,
  }
}
