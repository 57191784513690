import * as React from 'react'
import { MdOutlinePrivacyTip } from 'react-icons/md'
import { InfoIcon } from '@chakra-ui/icons'

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Tooltip,
  Switch,
  useToast,
  Stack,
  Heading,
} from '@chakra-ui/react'

import { colors } from '../../hocs/withTheme'
import { RootStore } from '../../models/root-store/root.store'
import CreditCardCapture from '../onboarding/CreditCardCapture'
import { RouteProps, withRouter } from 'src/utils/withRouter'
import { useCallback, useEffect, useState } from 'react'
import { useStore } from 'src/providers'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  CardCustom,
  SaveDetectorConfirmation,
  useSaveDetector,
} from '@botcopy/ui-shared'
import { useUpdateBot } from 'src/hooks/useUpdateBot'
import { UpdateBotInputTypeName } from '@botcopy/utils-shared'
import { toJS } from 'mobx'
import LoadingSpinner from 'src/components/LoadingSpinner'

const tooltipText = {
  showPrivacyPolicyToggle:
    'Adding a Privacy Policy will display a consent screen prior to users engaging with your bot',
  customerFeedback:
    'All positive and negative feedback is collected and displayed below with the Dialogflow Session ID and a comment if applicable.',
  sessionId: 'Dialogflow Session ID',
}

interface IPrivacyPolicyProps extends RouteProps {
  store?: RootStore
}

const PrivacyPolicy = ({}: IPrivacyPolicyProps) => {
  const store = useStore()
  const { botId } = useParams()
  const toast = useToast()

  const [formElements, setFormElements] = useState<any>({
    bcShowPrivacyPolicy: false,
    bcPrivacyPolicyTitle: '',
    bcPrivacyPolicyAcceptLabel: '',
    bcPrivacyPolicyDeclineLabel: '',
    bcPrivacyPolicyDeclineMessage: '',
    bcPrivacyPolicyURL: '',
  })

  const org = store?.organizations.current
  const me = store?.users.me
  const currentBot = store?.bots.currentBot

  const initialValue = currentBot
    ? {
        bcShowPrivacyPolicy: toJS(
          currentBot.theme.privacyPolicy.bcShowPrivacyPolicy,
        ),
        bcPrivacyPolicyTitle: toJS(
          currentBot.theme.privacyPolicy.bcPrivacyPolicyTitle,
        ),
        bcPrivacyPolicyAcceptLabel: toJS(
          currentBot.theme.privacyPolicy.bcPrivacyPolicyAcceptLabel,
        ),
        bcPrivacyPolicyDeclineLabel: toJS(
          currentBot.theme.privacyPolicy.bcPrivacyPolicyDeclineLabel,
        ),
        bcPrivacyPolicyDeclineMessage: toJS(
          currentBot.theme.privacyPolicy.bcPrivacyPolicyDeclineMessage,
        ),
        bcPrivacyPolicyURL: toJS(
          currentBot.theme.privacyPolicy.bcPrivacyPolicyURL,
        ),
      }
    : {
        bcShowPrivacyPolicy: false,
        bcPrivacyPolicyTitle: '',
        bcPrivacyPolicyAcceptLabel: '',
        bcPrivacyPolicyDeclineLabel: '',
        bcPrivacyPolicyDeclineMessage: '',
        bcPrivacyPolicyURL: '',
      }

  const { updateBotLoading, updateBotSendData, updateBotQuery } = useUpdateBot()
  const hasUnsavedChanges = useSaveDetector(initialValue, formElements)

  const handleSave = async () => {
    if (!(org && currentBot)) return
    try {
      updateBotSendData({
        organizationId: org._id,
        botId: currentBot._id,
        typename: UpdateBotInputTypeName.UpdateBotInput,
        privacyPolicy: {
          bcShowPrivacyPolicy: formElements.bcShowPrivacyPolicy,
          bcPrivacyPolicyTitle: formElements.bcPrivacyPolicyTitle,
          bcPrivacyPolicyAcceptLabel: formElements.bcPrivacyPolicyAcceptLabel,
          bcPrivacyPolicyDeclineLabel: formElements.bcPrivacyPolicyDeclineLabel,
          bcPrivacyPolicyDeclineMessage:
            formElements.bcPrivacyPolicyDeclineMessage,
          bcPrivacyPolicyURL: formElements.bcPrivacyPolicyURL,
        },
      })
    } catch (error) {
      _handleToast(
        'error',
        'There was an error saving your changes. Please try again.',
      )
    }
  }

  const _handleToast = useCallback(
    (type: string, message: string) => {
      toast({
        title: type === 'success' ? 'Success' : 'Error',
        description: message,
        status: type === 'success' ? 'success' : 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      })
    },
    [toast],
  )

  const _toggleShowPrivacyPolicy = async () => {
    if (currentBot && currentBot.theme.privacyPolicy) {
      setFormElements((prev: any) => ({
        ...prev,
        bcShowPrivacyPolicy: !prev.bcShowPrivacyPolicy,
      }))
    }
  }

  useEffect(() => {
    if (updateBotQuery) {
      updateBotQuery.promise.then(
        () => {
          _handleToast('success', 'Your changes have been saved.')
          store?.bots.fetchBots()
        },
        (error) => {
          _handleToast(
            'error',
            'There was an error saving some of your changes. Please verify again.',
          )
        },
      )
    }
  }, [_handleToast, store?.bots, updateBotQuery])

  useEffect(() => {
    if (!currentBot?.theme) return

    setFormElements({
      bcShowPrivacyPolicy: currentBot.theme.privacyPolicy.bcShowPrivacyPolicy,
      bcPrivacyPolicyTitle: currentBot.theme.privacyPolicy.bcPrivacyPolicyTitle,
      bcPrivacyPolicyAcceptLabel:
        currentBot.theme.privacyPolicy.bcPrivacyPolicyAcceptLabel,
      bcPrivacyPolicyDeclineLabel:
        currentBot.theme.privacyPolicy.bcPrivacyPolicyDeclineLabel,
      bcPrivacyPolicyDeclineMessage:
        currentBot.theme.privacyPolicy.bcPrivacyPolicyDeclineMessage,
      bcPrivacyPolicyURL: currentBot.theme.privacyPolicy.bcPrivacyPolicyURL,
    })
  }, [currentBot])

  useEffect(() => () => store.bots.setCurrentBotId(undefined), [store.bots])

  if (store?.bots.currentBotId !== botId) {
    store?.bots.setCurrentBotId(botId)
    return null
  }

  if (currentBot?.theme.css && org && me) {
    if (!currentBot.theme.privacyPolicy) {
      return null
    }

    return (
      <Flex width="100%" direction="column" align="center">
        <CreditCardCapture classes={{}} store={store} />
        <Box width="100%" maxWidth="container.sm" mx="auto" mt={10}>
          <CardCustom>
            <Flex justify="space-between" align="center" mb={4}>
              <Flex width="100%">
                <MdOutlinePrivacyTip size="24px" />
                <Heading size="md" mx={2}>
                  Privacy Policy
                </Heading>
                <Tooltip
                  label={tooltipText.showPrivacyPolicyToggle}
                  placement="top"
                  hasArrow={true}
                >
                  <Box as="span" ml={1}>
                    <InfoIcon cursor="pointer" color={colors.medGreyBlue} />
                  </Box>
                </Tooltip>
              </Flex>
              <FormControl
                display="flex"
                alignItems="center"
                justifyContent={'flex-end'}
              >
                <FormLabel my={0}>Enabled</FormLabel>
                <Switch
                  isChecked={formElements.bcShowPrivacyPolicy}
                  onChange={_toggleShowPrivacyPolicy}
                />
              </FormControl>
            </Flex>
            <Text fontSize="sm" mb={4} color={colors.darkGreyBlue}>
              By default, the Privacy Policy Component is translated to all
              languages that Dialogflow supports.
              <br />
              <br />
              Any changes made here will display the Privacy Policy component in
              the given language.
            </Text>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel
                  as={Text}
                  textStyle="overline"
                  textTransform="uppercase"
                >
                  Title
                </FormLabel>
                <Input
                  placeholder="To continue, please accept our Privacy Policy."
                  value={formElements.bcPrivacyPolicyTitle}
                  onChange={(e) => {
                    setFormElements((prev: any) => ({
                      ...prev,
                      bcPrivacyPolicyTitle: e.target.value,
                    }))
                  }}
                  color={colors.lightGreyScale1200}
                  borderColor={colors.lightGreyScale800}
                />
              </FormControl>

              <FormControl>
                <FormLabel
                  as={Text}
                  textStyle="overline"
                  textTransform="uppercase"
                >
                  Privacy Policy Link
                </FormLabel>
                <Input
                  placeholder="https://www.botcopy.com/privacy"
                  value={formElements.bcPrivacyPolicyURL}
                  onChange={(e) => {
                    setFormElements((prev: any) => ({
                      ...prev,
                      bcPrivacyPolicyURL: e.target.value,
                    }))
                  }}
                  color={colors.lightGreyScale1200}
                  borderColor={colors.lightGreyScale800}
                />
              </FormControl>

              <FormControl>
                <FormLabel
                  as={Text}
                  textStyle="overline"
                  textTransform="uppercase"
                >
                  Accept Button Label
                </FormLabel>
                <Input
                  placeholder="Accept"
                  value={formElements.bcPrivacyPolicyAcceptLabel}
                  onChange={(e) => {
                    setFormElements((prev: any) => ({
                      ...prev,
                      bcPrivacyPolicyAcceptLabel: e.target.value,
                    }))
                  }}
                  color={colors.lightGreyScale1200}
                  borderColor={colors.lightGreyScale800}
                />
              </FormControl>

              <FormControl>
                <FormLabel
                  as={Text}
                  textStyle="overline"
                  textTransform="uppercase"
                >
                  Decline Button Label
                </FormLabel>
                <Input
                  placeholder="Decline"
                  value={formElements.bcPrivacyPolicyDeclineLabel}
                  onChange={(e) => {
                    setFormElements((prev: any) => ({
                      ...prev,
                      bcPrivacyPolicyDeclineLabel: e.target.value,
                    }))
                  }}
                  color={colors.lightGreyScale1200}
                  borderColor={colors.lightGreyScale800}
                />
              </FormControl>

              <FormControl mb={6}>
                <FormLabel
                  as={Text}
                  textStyle="overline"
                  textTransform="uppercase"
                >
                  Decline Message
                </FormLabel>
                <Input
                  placeholder="To continue chatting, acceptance of our privacy policy is required"
                  value={formElements.bcPrivacyPolicyDeclineMessage}
                  onChange={(e) => {
                    setFormElements((prev: any) => ({
                      ...prev,
                      bcPrivacyPolicyDeclineMessage: e.target.value,
                    }))
                  }}
                  color={colors.lightGreyScale1200}
                  borderColor={colors.lightGreyScale800}
                />
              </FormControl>
            </Stack>
          </CardCustom>
        </Box>

        <SaveDetectorConfirmation
          hasUnsavedChanges={hasUnsavedChanges}
          isSaveLoading={updateBotLoading}
          onSave={handleSave}
          left={store.session.isNavDrawerOpen ? '350px' : '71px'}
          width={
            store.session.isNavDrawerOpen
              ? 'calc(100% - 350px)'
              : 'calc(100% - 71px)'
          }
        />
      </Flex>
    )
  }
  return <LoadingSpinner />
}

export default withRouter(observer(PrivacyPolicy))
