import { colors } from '../../../hocs/withTheme'

export const handleUiByBotcopyChange = async (
  value: string,
  setFormElements: any,
): Promise<void> => {
  const val: any = {
    bcUiByBotcopyCircleColor: '',
    bcUiByBotcopyToggleColor: '',
    bcUiByBotcopyImg: '',
  }

  switch (value) {
    case 'blueWhite':
      val.bcUiByBotcopyCircleColor = colors.pureWhite
      val.bcUiByBotcopyToggleColor = colors.botcopyBlue
      val.bcUiByBotcopyImg = 'blueLogo'
      break

    case 'darkBlueWhite':
      val.bcUiByBotcopyCircleColor = colors.pureWhite
      val.bcUiByBotcopyToggleColor = colors.prussianBlue
      val.bcUiByBotcopyImg = 'darkBlueLogo'
      break

    case 'purpleWhite':
      val.bcUiByBotcopyCircleColor = colors.pureWhite
      val.bcUiByBotcopyToggleColor = colors.purple
      val.bcUiByBotcopyImg = 'purpleLogo'
      break

    case 'blue':
      val.bcUiByBotcopyCircleColor = colors.botcopyBlue
      val.bcUiByBotcopyToggleColor = colors.botcopyBlueTransparent
      val.bcUiByBotcopyImg = 'logo'
      break
    case 'darkBlue':
      val.bcUiByBotcopyCircleColor = colors.prussianBlue
      val.bcUiByBotcopyToggleColor = `${colors.prussianBlue}b3`
      val.bcUiByBotcopyImg = 'logo'
      break

    case 'purple':
      val.bcUiByBotcopyCircleColor = colors.purple
      val.bcUiByBotcopyToggleColor = `${colors.purple}b3`
      val.bcUiByBotcopyImg = 'logo'
      break

    case 'greyBlue':
      val.bcUiByBotcopyCircleColor = colors.botcopyBlue
      val.bcUiByBotcopyToggleColor = colors.grey
      val.bcUiByBotcopyImg = 'logo'
      break

    case 'greyDarkBlue':
      val.bcUiByBotcopyCircleColor = colors.prussianBlue
      val.bcUiByBotcopyToggleColor = colors.grey
      val.bcUiByBotcopyImg = 'logo'
      break

    case 'greyPurple':
      val.bcUiByBotcopyCircleColor = colors.purple
      val.bcUiByBotcopyToggleColor = colors.grey
      val.bcUiByBotcopyImg = 'logo'
      break

    case 'greyscale':
      val.bcUiByBotcopyCircleColor = colors.pureWhite
      val.bcUiByBotcopyToggleColor = colors.grey
      val.bcUiByBotcopyImg = 'greyscaleLogo'
      break
  }

  setFormElements((prev: any) => ({
    css: {
      ...prev.css,
      bcUiByBotcopyCircleColor: val.bcUiByBotcopyCircleColor,
      bcUiByBotcopyToggleColor: val.bcUiByBotcopyToggleColor,
      bcUiByBotcopyImg: val.bcUiByBotcopyImg,
    },
    defaultGreeterStyle: prev.defaultGreeterStyle,
    images: prev.images,
  }))
}
