import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Alert,
  Spinner,
  Center,
  useToast,
} from '@chakra-ui/react'
import {
  CardCustom,
  SaveDetectorConfirmation,
  useSaveDetector,
} from '@botcopy/ui-shared'
import { ConfirmationModal } from '@botcopy/ui-shared'
import { Notification, Panel } from '@botcopy/ui-shared'
import { observer } from 'mobx-react'
import { Link, useNavigate } from 'react-router-dom'
import BotMenuHeader, { LocalizedHeaders } from './BotMenuHeader'
import { useStore } from 'src/providers'
import { useMe } from 'src/hooks/useMe'
import { useBotMenus } from 'src/hooks/useBotMenus'
import { useCreateBotMenu } from 'src/hooks/useCreateBotMenu'
import AddMenuItems from './AddMenuItems'
import { BotMenuName } from './BotMenuName'
import SortBotMenu from './SortBotMenu'
import { BotMenuHeaderInput, BotModelType } from '@botcopy/utils-shared'
import { BotMenuDeleteButton } from './BotMenuDeleteButton'
import { useEditBotMenu } from 'src/hooks/useEditBotMenu'
import { dialogflowLanguages } from 'src/utils/languages'
import { useDeleteBotMenu } from 'src/hooks/useDeleteBotMenu'
import { UseBotPublicDataType } from 'src/hooks/useBotPublic'
import { toJS } from 'mobx'

interface IAgentOneProps {
  botMenuId?: string
}

const AgentOne = ({ botMenuId }: IAgentOneProps) => {
  const store = useStore()
  const toast = useToast()
  useMe()
  const [selectedBotMenuId, setSelectedBotMenuId] = useState<string>()
  const { botMenusData, botMenusLoading, botMenusFetchData } = useBotMenus()
  const { editBotMenuQuery, editBotMenuLoading, editBotMenuSendData } =
    useEditBotMenu()
  const { createBotMenuQuery, createBotMenuSendData, createBotMenuLoading } =
    useCreateBotMenu()
  const { deleteBotMenuQuery, deleteBotMenuSendData, deleteBotMenuLoading } =
    useDeleteBotMenu()
  const selectedBotMenu = useMemo(() => {
    const foundBotMenu = botMenusData?.find(
      ({ _id }) => _id.toString() === selectedBotMenuId,
    )

    if (!foundBotMenu) {
      return {
        _id: '',
        name: '',
        headers: {},
        bots: [],
      }
    }

    // Convert the array of headers into an object
    // easier to manage when editing
    const botMenuLocalizedHeaders =
      foundBotMenu.headers.reduce<LocalizedHeaders>(
        (acc, { languageCode, main }) => ({
          ...acc,
          [languageCode as string]: { languageCode, main },
        }),
        {},
      )

    // Create an object with all available languages
    // Fill with existing bot menu headers
    const localizedHeaders = dialogflowLanguages.reduce<LocalizedHeaders>(
      (acc, { code }) => ({
        ...acc,
        [code]: {
          languageCode: code,
          main: botMenuLocalizedHeaders[code]?.main,
        },
      }),
      {},
    )

    return {
      _id: foundBotMenu._id,
      bots: foundBotMenu.bots.map((bot) => bot._id),
      headers: localizedHeaders,
      name: foundBotMenu.name,
    }
  }, [botMenusData, selectedBotMenuId])

  const [
    showConfirmationForNavWithUnsavedChanges,
    setShowConfirmationForNavWithUnsavedChanges,
  ] = useState(false)
  const navPathAfterConfirmationRef = useRef('')

  const navigate = useNavigate()
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false)

  const [formElements, setFormElements] = useState<typeof selectedBotMenu>(
    // JSON stringify and parse to get rid of nested object references
    JSON.parse(JSON.stringify(selectedBotMenu)),
  )

  const hasUnsavedChanges = useSaveDetector(
    selectedBotMenu._id === formElements._id ? selectedBotMenu : undefined,
    selectedBotMenu._id === formElements._id ? formElements : undefined,
  )

  useEffect(() => {
    setFormElements(JSON.parse(JSON.stringify(selectedBotMenu)))
  }, [selectedBotMenu])

  const [availableExternalBots, setAvailableExternalBots] = useState<
    UseBotPublicDataType[]
  >([])
  const availableBots: BotModelType[] = useMemo(
    () =>
      [
        ...(toJS(store?.bots.entities) || []),
        ...(toJS(botMenusData)
          ?.map((botMenu) => toJS(botMenu.bots))
          .flat() || []),
        ...availableExternalBots,
      ] || [],
    [availableExternalBots, botMenusData, store?.bots.entities],
  )

  useEffect(() => {
    if (createBotMenuQuery) {
      createBotMenuQuery.promise.then(
        () => {
          toast({
            title: 'Bot Menu Created',
            description: 'Your bot menu has been successfully created.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
          })
        },
        (error) => {
          toast({
            title: 'Error creating bot menu',
            description: error.response.errors[0].message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
          })
        },
      )
    }
  }, [createBotMenuQuery, toast])
  useEffect(() => {
    if (deleteBotMenuQuery) {
      deleteBotMenuQuery.promise.then(
        () => {
          toast({
            title: 'Bot Menu Deleted',
            description: 'Your bot menu has been successfully deleted.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
          })
        },
        (error) => {
          toast({
            title: 'Error deleting bot menu',
            description: error.response.errors[0].message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
          })
        },
      )
    }
  }, [deleteBotMenuQuery, toast])

  useEffect(() => {
    if (createBotMenuQuery) {
      createBotMenuQuery.promise.then(() => {
        if (store?.users.me?.organization) {
          botMenusFetchData(store.users.me.organization)
        }
      })
    }
  }, [createBotMenuQuery, botMenusFetchData, store?.users.me?.organization])
  useEffect(() => {
    if (deleteBotMenuQuery) {
      deleteBotMenuQuery.promise.then(() => {
        if (store?.users.me?.organization) {
          botMenusFetchData(store.users.me.organization)
        }
      })
    }
  }, [deleteBotMenuQuery, botMenusFetchData, store?.users.me?.organization])

  useEffect(() => {
    if (editBotMenuQuery) {
      editBotMenuQuery.promise.then(
        () => {
          toast({
            title: 'Bot Menu Saved',
            description: 'Your changes have been successfully saved.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
          })
        },
        (error) => {
          toast({
            title: 'Error saving Bot Menu',
            description: error.response.errors[0].message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
          })
        },
      )
    }
  }, [editBotMenuQuery, toast])

  useEffect(() => {
    if (!(store.users.me?.organization && !editBotMenuLoading)) return
    botMenusFetchData(store.users.me.organization)
  }, [botMenusFetchData, store.users.me?.organization, editBotMenuLoading])

  useEffect(() => {
    if (
      botMenusData &&
      botMenusData.length > 0 &&
      (!botMenuId || !botMenusData.map(({ _id }) => _id).includes(botMenuId))
    ) {
      navigate(`/agent-one/${botMenusData[0]._id}`)
    }
  }, [botMenuId, botMenusData, navigate])

  useEffect(() => {
    if (botMenuId) {
      setSelectedBotMenuId(botMenuId)
    }
  }, [botMenuId])

  const handleBotMenuNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormElements((prev) => ({
      _id: prev._id,
      bots: prev.bots,
      headers: prev.headers,
      name: e.target.value,
    }))
  }

  const handleBotMenuHeaderChange = (localizedHeaders: LocalizedHeaders) => {
    setFormElements((prev) => ({
      _id: prev._id,
      bots: prev.bots,
      headers: localizedHeaders,
      name: prev.name,
    }))
  }

  const handleAddMenuItem = useCallback(
    (
      bot: UseBotPublicDataType,
      options: { external: boolean } = { external: false },
    ) => {
      if (options.external) {
        setAvailableExternalBots((prev) => [...prev, bot])
      }
      setFormElements((prev) => ({
        _id: prev._id,
        bots: [...prev.bots, bot._id],
        headers: prev.headers,
        name: prev.name,
      }))
    },
    [],
  )

  const handleRemoveMenuItem = (itemIndex: string) => {
    setFormElements((prev) => ({
      _id: prev._id,
      bots: [
        ...prev.bots.slice(0, Number(itemIndex)),
        ...prev.bots.slice(Number(itemIndex) + 1),
      ],
      headers: prev.headers,
      name: prev.name,
    }))
  }

  const handleReorderMenuItems = (botIds: string[]) => {
    setFormElements((prev) => ({
      _id: prev._id,
      bots: botIds,
      headers: prev.headers,
      name: prev.name,
    }))
  }

  const handleEditBotMenuSaveChanges = async () => {
    if (!(store.users.me?.organization && selectedBotMenuId)) return
    await editBotMenuSendData({
      organizationId: store.users.me.organization,
      botMenuId: selectedBotMenuId,
      name: formElements.name,
      bots: formElements.bots,
      headers: Object.values(formElements.headers).filter(
        (header: any) => header.main !== undefined,
      ) as BotMenuHeaderInput[],
    })
  }

  const handleConfirmNavigation = () => {
    setShowConfirmationForNavWithUnsavedChanges(false)
    navigate(navPathAfterConfirmationRef.current)
    navPathAfterConfirmationRef.current = ''
  }

  const renderContent = () => {
    if (botMenusLoading) {
      return (
        <Center height="calc(100vh - 86px)">
          <Spinner size="xl" color="blue.500" thickness="4px" />
        </Center>
      )
    }

    if (botMenusData?.length === 0) {
      return (
        <Alert width="100%" textAlign="left" mt={3}>
          It looks like you don't have any bot menus set-up yet! To get started,
          select the "Create Bot Menu" option from the menu on the left.
        </Alert>
      )
    }

    if (!selectedBotMenu) {
      return (
        <Center height="calc(100vh - 86px)">
          <Alert width="100%" textAlign="left">
            Select a bot menu from the left panel to view and edit its details.
          </Alert>
        </Center>
      )
    }

    return (
      <>
        <Heading mb={2} pt={6}>
          {selectedBotMenu.name}
        </Heading>
        <Heading size="sm" fontWeight={400} mb={4}>
          ID #{selectedBotMenu._id}
        </Heading>
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
          gap={6}
          maxWidth="920px"
          mb={20}
        >
          <GridItem>
            <CardCustom>
              <BotMenuName
                name={formElements.name}
                onNameChange={handleBotMenuNameChange}
              />
              <BotMenuDeleteButton
                loading={deleteBotMenuLoading}
                onDeleteButtonClicked={() =>
                  setShowDeleteConfirmationModal(true)
                }
              />
            </CardCustom>
          </GridItem>

          <GridItem>
            <BotMenuHeader
              onHeaderChange={handleBotMenuHeaderChange}
              headers={formElements.headers}
            />
          </GridItem>

          <GridItem>
            <AddMenuItems
              availableBots={store?.bots.entities || []}
              handleAddMenuItem={handleAddMenuItem}
            />
          </GridItem>

          <GridItem width="md">
            <CardCustom>
              <SortBotMenu
                menuBots={formElements.bots}
                availableBots={availableBots}
                handleReorderMenuItems={handleReorderMenuItems}
                handleRemoveMenuItem={handleRemoveMenuItem}
              />
            </CardCustom>
          </GridItem>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Flex flexDirection={{ base: 'column', md: 'row' }}>
        <Box
          width={'30rem'}
          borderRightWidth="1px"
          borderRightStyle="solid"
          borderRightColor="lightMode.greys.800"
          height="calc(100vh - 85px)"
          position={'sticky'}
          top={'85px'}
        >
          <Panel
            items={
              botMenusData?.map((menu) => (
                <Link
                  to={`/agent-one/${menu._id}`}
                  onClick={(e) => {
                    e.preventDefault()
                    if (hasUnsavedChanges) {
                      setShowConfirmationForNavWithUnsavedChanges(true)
                      navPathAfterConfirmationRef.current = `/agent-one/${menu._id}`
                      return
                    }

                    navigate(`/agent-one/${menu._id}`)
                  }}
                  key={menu._id}
                >
                  <Notification
                    body={menu.name || ''}
                    additionalInfo={[
                      {
                        text: `${menu.bots.length || 0} bot${menu.bots.length === 1 ? '' : 's'}`,
                        colorScheme: 'grey',
                      },
                    ]}
                    isSelected={menu._id === selectedBotMenu?._id}
                  />
                </Link>
              )) ?? []
            }
            heading="Bot Menus"
            blockPaddingX={0}
            blockPaddingY={0}
            maxHeight={'calc(100vh - 86px)'}
            stickyHeading={true}
            headingTop={0}
            actionButtonBottom={0}
            stickyActionButton={true}
            actionButton={{
              label: 'Create Bot Menu',
              onClick: () =>
                store?.users.me?.organization &&
                createBotMenuSendData({
                  organizationId: store.users.me.organization,
                  name: 'New Bot Menu',
                  headers: [],
                  bots: [],
                }),

              isLoading: createBotMenuLoading,
            }}
          />
        </Box>
        <Box mx="auto" maxWidth="990px" flex="1">
          {renderContent()}
        </Box>
      </Flex>

      <SaveDetectorConfirmation
        hasUnsavedChanges={hasUnsavedChanges}
        onSave={handleEditBotMenuSaveChanges}
        isSaveLoading={editBotMenuLoading}
        left="30rem"
        width={'calc(100% - 30rem)'}
      />

      <ConfirmationModal
        isOpen={showConfirmationForNavWithUnsavedChanges}
        onClose={() => {
          navPathAfterConfirmationRef.current = ''
          setShowConfirmationForNavWithUnsavedChanges(false)
        }}
        header="Unsaved Changes"
        content="You have unsaved changes. Are you sure you want to navigate away and lose these changes?"
        confirmText="Yes, Navigate"
        cancelButtonText="Cancel"
        onConfirm={handleConfirmNavigation}
      />

      <ConfirmationModal
        isOpen={showDeleteConfirmationModal}
        onClose={() => setShowDeleteConfirmationModal(false)}
        header="Delete Bot Menu"
        content="Are you sure you want to delete this bot menu? This action cannot be undone."
        confirmText="Yes, Delete"
        cancelButtonText="Cancel"
        onConfirm={() => {
          if (store?.users.me?.organization) {
            deleteBotMenuSendData({
              botMenuId: selectedBotMenuId,
              organizationId: store.users.me.organization,
            })
          }
          setShowDeleteConfirmationModal(false)
        }}
      />
    </>
  )
}

export default observer(AgentOne)
