import { playLiveChatEntrySound } from './play-live-chat-entry-sound'

export async function handleThemeCssChange({
  property,
  newValue,
  setFormElements,
}: {
  property: string
  newValue: string
  setFormElements: any
}) {
  if (property === 'bcLiveChatEntrySound') {
    playLiveChatEntrySound(newValue)
  }
  if (property === 'defaultGreeterStyle') {
    setFormElements((prev: any) => ({
      ...prev,
      defaultGreeterStyle: newValue,
    }))
    return
  }

  if (typeof newValue === 'object') {
    for (const [key, value] of Object.entries(newValue)) {
      setFormElements((prev: any) => ({
        css: {
          ...prev.css,
          [key]: value,
        },
        defaultGreeterStyle: prev.defaultGreeterStyle,
        images: prev.images,
      }))
    }
  } else {
    setFormElements((prev: any) => ({
      css: {
        ...prev.css,
        [property]: newValue,
      },
      defaultGreeterStyle: prev.defaultGreeterStyle,
      images: prev.images,
    }))
  }
}
