import React, { ReactElement } from 'react'
import {
  MdInfoOutline as InfoIcon,
  MdOutlineDelete as DeleteOutlineIcon,
} from 'react-icons/md'
import { colors } from 'src/hocs/withTheme'
import { IBotPrompt } from 'src/models/bots'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Input,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react'

// import Logger from 'src/utils/logger'
// const { log } = Logger('PresetSuggestion')

const tooltipText = {
  incompleteSuggestion: `This prompt is missing a label or a command, and will not trigger when selected.`,
  suggestionDevices:
    'Select which devices this prompt suggestion will display on.',
}

interface IPresetSuggestionProps {
  promptFormElements: IBotPrompt
  suggestionIndex: number
  suggestion: any

  onFormElementsChange({ suggestions }: { suggestions?: any[] }): void
}

const PresetSuggestion: React.FC<IPresetSuggestionProps> = ({
  promptFormElements,
  suggestionIndex,
  suggestion,
  onFormElementsChange,
}): ReactElement => {
  const _changeSuggestionType = async (e: any) => {
    onFormElementsChange({
      ...promptFormElements,
      suggestions: [
        ...promptFormElements.suggestions.slice(0, suggestionIndex),
        {
          ...suggestion,
          type: e.target.value,
        },
        ...promptFormElements.suggestions.slice(suggestionIndex + 1),
      ],
    })
  }

  const _toggleMobileSuggestion = async () => {
    onFormElementsChange({
      ...promptFormElements,
      suggestions: [
        ...promptFormElements.suggestions.slice(0, suggestionIndex),
        {
          ...suggestion,
          showMobileSuggestion: !suggestion.showMobileSuggestion,
        },
        ...promptFormElements.suggestions.slice(suggestionIndex + 1),
      ],
    })
  }

  const _toggleDesktopSuggestion = async () => {
    onFormElementsChange({
      ...promptFormElements,
      suggestions: [
        ...promptFormElements.suggestions.slice(0, suggestionIndex),
        {
          ...suggestion,
          showDesktopSuggestion: !suggestion.showDesktopSuggestion,
        },
        ...promptFormElements.suggestions.slice(suggestionIndex + 1),
      ],
    })
  }

  const _deleteSuggestion = async () => {
    onFormElementsChange({
      ...promptFormElements,
      suggestions: [
        ...promptFormElements.suggestions.slice(0, suggestionIndex),
        ...promptFormElements.suggestions.slice(suggestionIndex + 1),
      ],
    })
  }

  return (
    <>
      <Accordion
        as={Card}
        bg={colors.lightGreyScale100}
        allowToggle={true}
        maxW="1214px"
        border="1px solid"
        borderColor={colors.lightGreyScale800}
        borderRadius="16px"
      >
        <AccordionItem borderBottom="none">
          <h2>
            <AccordionButton borderRadius={6} border="none" p={6}>
              <Box as="span" flex="1" textAlign="left">
                <Flex>
                  <Text textStyle="body2">
                    {suggestion.title || 'Edit your suggestion...'}
                  </Text>
                  {!suggestion.title || !suggestion.command ? (
                    <Tooltip
                      placement={'top'}
                      label={tooltipText.incompleteSuggestion}
                      padding={5}
                    >
                      {/* needed for tooltip to display  */}
                      <div>
                        <InfoIcon
                          color={colors.lightGreyScale1100}
                          size="20px"
                        />
                      </div>
                    </Tooltip>
                  ) : null}
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p={8} pt={0}>
            <HStack>
              <FormControl>
                <FormLabel as={Text} textStyle="overline" casing="uppercase">
                  Suggestion Label
                </FormLabel>
                <Input
                  value={suggestion.title}
                  onChange={(e) => {
                    onFormElementsChange({
                      ...promptFormElements,
                      suggestions: [
                        ...promptFormElements.suggestions.slice(
                          0,
                          suggestionIndex,
                        ),
                        {
                          ...suggestion,
                          title: e.target.value,
                        },
                        ...promptFormElements.suggestions.slice(
                          suggestionIndex + 1,
                        ),
                      ],
                    })
                  }}
                  color={colors.lightGreyScale1200}
                  border="1px solid"
                  borderColor={colors.lightGreyScale800}
                  h="48px"
                />
              </FormControl>

              <FormControl>
                <FormLabel as={Text} textStyle="outline" casing="uppercase">
                  Trigger
                </FormLabel>
                <Select
                  value={suggestion.type}
                  placeholder="Select a type"
                  onChange={_changeSuggestionType}
                  h="48px"
                >
                  <option value="event">Event Name</option>
                  <option value="training">Training Phrase</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel as={Text} textStyle="overline" casing="uppercase">
                  {suggestion.type === 'event' ? 'Event' : 'Training Phrase'}
                </FormLabel>
                <Input
                  value={suggestion.command}
                  variant="outline"
                  onChange={(e) => {
                    onFormElementsChange({
                      ...promptFormElements,
                      suggestions: [
                        ...promptFormElements.suggestions.slice(
                          0,
                          suggestionIndex,
                        ),
                        {
                          ...suggestion,
                          command: e.target.value,
                        },
                        ...promptFormElements.suggestions.slice(
                          suggestionIndex + 1,
                        ),
                      ],
                    })
                  }}
                  color={colors.lightGreyScale1200}
                  border="1px solid"
                  borderColor={colors.lightGreyScale800}
                  h="48px"
                />
              </FormControl>
              <Box mt={5}>
                <DeleteOutlineIcon
                  onClick={_deleteSuggestion}
                  cursor="pointer"
                  size="20px"
                  width="fit-content"
                  color={colors.lightGreyScale1200}
                />
              </Box>
            </HStack>
            <Grid>
              <HStack my={5}>
                <Text textStyle="body2">Devices</Text>
                <Tooltip
                  label={tooltipText.suggestionDevices}
                  placement={'top'}
                  padding={5}
                >
                  <span>
                    <InfoIcon color={colors.lightGreyScale1100} size="20px" />
                  </span>
                </Tooltip>
              </HStack>
              <HStack spacing={6} justify="start">
                <FormControl w="auto">
                  <Checkbox
                    size="lg"
                    iconSize="10px"
                    isChecked={suggestion.showDesktopSuggestion}
                    onChange={_toggleDesktopSuggestion}
                  >
                    <Text textStyle="overline">Desktop</Text>
                  </Checkbox>
                </FormControl>

                <FormControl w="auto">
                  <Checkbox
                    size="lg"
                    iconSize="10px"
                    isChecked={suggestion.showMobileSuggestion}
                    onChange={_toggleMobileSuggestion}
                  >
                    <Text textStyle="overline">Mobile</Text>
                  </Checkbox>
                </FormControl>
              </HStack>
            </Grid>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  )
}

export default PresetSuggestion
