import React from 'react'
import { MdAdd } from 'react-icons/md'

import { CardCustom } from '@botcopy/ui-shared'
import { Button, Flex, Grid, Heading } from '@chakra-ui/react'

import { BotPlatform, IBot } from '../../../../models/bots'
import { BotCard } from '../BotCard'

interface IBotsGridProps {
  filteredBots: IBot[]
  creatingBot: boolean
  onboardingStep: number
  createBotPlatformDialog: () => void
  gotoBot: (bot: IBot) => void
}

const BotsGrid: React.FC<IBotsGridProps> = ({
  filteredBots,
  creatingBot,
  onboardingStep,
  createBotPlatformDialog,
  gotoBot,
}) => {
  return (
    <Grid
      templateColumns={{
        base: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        lg: 'repeat(2, 1fr)',
        xl: 'repeat(4, 1fr)',
      }}
      gap={8}
    >
      <Button
        disabled={creatingBot || onboardingStep === 1}
        key="btn-add-a-bot"
        border="none"
        variant="unstyled"
        height="auto"
        width="100%"
        onClick={createBotPlatformDialog}
      >
        <CardCustom>
          <Flex
            justifyContent="center"
            alignItems="center"
            direction="column"
            height="15rem"
            backgroundColor="lightMode.greys.200"
            width="100%"
            borderRadius="0.5rem"
          >
            <MdAdd fontSize="2.5rem" />
            <Heading as="h6" fontSize="1.5rem" mt="1.5rem" fontWeight="400">
              Add a Bot
            </Heading>
          </Flex>
        </CardCustom>
      </Button>
      {filteredBots.map((bot, index) => (
        <Button
          variant="unstyled"
          height="auto"
          onClick={() => gotoBot(bot)}
          key={`btn-bot-${index}-${bot.name}`}
        >
          <BotCard
            title={bot.name}
            imageUrl={bot.theme.images.logo}
            dialogflowEdition={
              bot.platform === BotPlatform.DIALOGFLOW_ES ? 'es' : 'cx'
            }
            isActive={bot.active ? true : false}
            region={bot.dialogflow.location}
            label={bot.dashboardLabel}
            isFavoriteEnabled={false}
            isFavorite={false}
            bot={bot}
          />
        </Button>
      ))}
    </Grid>
  )
}

export default BotsGrid
