import { useCallback } from 'react'
import {
  BotMenuModelType,
  DeleteBotMenuInput,
  DeleteBotMenuInputTypeName,
  useQuery,
} from '@botcopy/utils-shared'
import { useStore } from 'src/providers'

export const useDeleteBotMenu = () => {
  const store = useStore()

  const {
    data: deleteBotMenuData,
    setQuery: deleteBotMenuSetQuery,
    query: deleteBotMenuQuery,
    loading: deleteBotMenuLoading,
    error: deleteBotMenuError,
  } = useQuery<{
    deleteBotMenu: BotMenuModelType
  }>()

  const deleteBotMenuSendData = useCallback(
    (input: Omit<DeleteBotMenuInput, 'typename'>) => {
      deleteBotMenuSetQuery(() =>
        store.api.mutateDeleteBotMenu(
          {
            input: {
              ...input,
              typename: DeleteBotMenuInputTypeName.DeleteBotMenuInput,
            },
          },
          (botMenuSelect) => botMenuSelect._id,
        ),
      )
    },
    [deleteBotMenuSetQuery, store.api],
  )

  return {
    deleteBotMenuData: deleteBotMenuData?.deleteBotMenu,
    deleteBotMenuQuery,
    deleteBotMenuSendData,
    deleteBotMenuLoading,
    deleteBotMenuError,
  }
}
