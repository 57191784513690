import { configure } from 'mobx'
import { flow, Instance, SnapshotOut, types } from 'mobx-state-tree'

import { createHttpClient } from '@maxletou/mst-gql'
import { RootStore as APIRootStore } from '@botcopy/utils-shared'

import { UserModel } from '../user-store'
import { Session } from 'src/models/session'
import { Bots } from 'src/models/bots'
import { Organizations } from 'src/models/organizations'
import { Users } from 'src/models/users'
import { AppConfig } from 'src/config'

const nxApiBaseUrl = AppConfig.get('/NxApiHostname')

configure({
  useProxies: 'never',
  enforceActions: 'never',
  isolateGlobalState: true,
})

export enum STATE {
  INITIAL = 'initial',
  PENDING = 'pending',
  DONE = 'done',
  ERROR = 'error',
}

const defaultUserStore = (accessToken: string | null = null) => ({
  accessToken,
  livechatAgentSession: undefined,
  me: undefined,
  currentConversationId: undefined,
  analytics: undefined,
  monitoring: {},
})
const defaultSessionStore = (accessToken: string | null = null) => ({
  isNavDrawerOpen: false,
  onboarding: {},
  token: accessToken,
})

const initialRootStore = (accessToken: string | null = null) => ({
  api: {},
  user: defaultUserStore(accessToken),
  channels: {},
  messages: {},
  bots: {},
  organizations: {},
  session: defaultSessionStore(accessToken),
  users: {},
})

export const RootStoreModel = types
  .model('RootStore')
  .props({
    api: types.optional(APIRootStore, {}),
    // channels: ChannelStoreModel,
    // messages: MessagesModel,
    user: types.optional(UserModel, defaultUserStore()),
    bots: Bots,
    organizations: Organizations,
    session: Session,
    users: Users,
  })
  .actions((self) => ({
    fetchBaseData: flow(function* () {
      yield self.users.fetchMe()
      self.bots.clearBots() // clear all bots, to get a fresh copy from the server
      yield self.bots.fetchBots()
      const user = self.users.me
      if (user) {
        const orgId = user.organization
        if (orgId) {
          yield self.organizations.getUserOrgById(orgId)
        }
      }
    }),
  }))
  .actions((self) => ({
    logout: () => {
      self.session.logout()
      // reset all values to defaults
      self.bots = Bots.create()
      self.organizations = Organizations.create()
      self.session = Session.create()
      self.users = Users.create()
    },
  }))

/**
 * The RootStore instance.
 */
export type RootStore = Instance<typeof RootStoreModel>

/**
 * The data of a RootStore.
 */
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>

export const getDefaultStore = (accessToken?: string): RootStore => {
  const clientHeaders = accessToken
    ? {
        headers: { authorization: `bearer ${accessToken}` },
      }
    : undefined

  // const subscriptionClientOptions: ClientOptions = {
  //   connectionParams: clientHeaders,
  //   on: {
  //     connected: (event: unknown) => {
  //       console.log('root.store connected', event)
  //     },
  //     closed: (event) => {
  //       console.log('root.store closed', event)
  //     },
  //     error: (event) => {
  //       console.log('root.store error', event)
  //     },
  //   },

  //   lazy: true,
  //   keepAlive: 480000, // 8 mins < 10mins aws idle timeout
  //   shouldRetry: () => true,
  //   url: `${environment.nxWsBaseUrl}`,
  // }

  const env = {
    gqlHttpClient: createHttpClient(`${nxApiBaseUrl}/graphql`, clientHeaders),
    // gqlWsClient: new SubscriptionClient(subscriptionClientOptions),
    socket: WebSocket,
  }

  const rootStore = RootStoreModel.create(initialRootStore(accessToken), env)

  return rootStore
}
