// import path from 'path'
import debug, { IDebugger } from 'debug'

import { AppConfig } from '../config'

// import stackTrace from 'stack-trace'

debug.enable(AppConfig.get('/logging'))

interface ILogger {
  log: IDebugger
  logError: IDebugger
}

const logger = (target: any): ILogger => {
  const echo = () => {
    // @TODO: Map the sourcemap js and output the file and line number of the log
    // const trace = new Error().stack
    // // const trace = stackTrace.get()
    // let final = ' '
    // if (trace.length > 1) {
    //   const deep = 2
    //   const frame = trace[deep]
    //   const basename = path.basename(frame.getFileName()).replace('.js', '')
    //   const method = frame.getFunctionName()
    //   const functionName = method ? method : 'anonymous'
    //   final = ` ${basename}:${functionName}:${frame.getLineNumber()}`
    // }
    // return final
    return ''
  }
  const loggerObj = {
    log: debug(`botcopy:${target}${echo()}`),
    logError: debug(`botcopy:${target}${echo()}`),
    // emitter: ErrorPlugin.emitter
  }

  // tslint:disable-next-line no-console
  loggerObj.log.log = console.log.bind(console)
  loggerObj.logError.log = console.error.bind(console)

  return loggerObj
}

export default logger
