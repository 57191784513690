import React from 'react'
import {
  NavigateFunction,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

export interface RouteProps {
  location: Location
  navigate: NavigateFunction
  params: Readonly<Params<string>>
}

export const withRouter = (Component: React.ComponentType<any>) => {
  return (props: any) => {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        params={params}
      />
    )
  }
}
