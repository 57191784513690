import { CopyToClipboardButton, customTheme } from '@botcopy/ui-shared'
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import * as React from 'react'
import {
  MdLabelOutline,
  MdOutlineAccountCircle,
  MdOutlineContentCopy,
} from 'react-icons/md'
import { useStore } from 'src/providers'
import { ConnectFormElements, tooltipText } from '.'

type BotDetailsFormElements = Pick<
  ConnectFormElements,
  'botName' | 'dashboardLabel'
>

interface BotDetailsProps {
  formElements: BotDetailsFormElements
  onFormElementsChange: (
    updatedFormElements: Partial<BotDetailsFormElements>,
  ) => void
}

export const BotDetails = ({
  formElements,
  onFormElementsChange,
}: BotDetailsProps) => {
  const store = useStore()
  const bot = store.bots.currentBot

  const handleFormElementsChange =
    (formElement: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      onFormElementsChange({
        [formElement]: value,
      })
    }

  return (
    <Grid>
      <Text textStyle="h5" fontSize="21px">
        Bot Details
      </Text>
      <GridItem>
        <FormControl my={2}>
          <FormLabel as={Text} textStyle="overline" casing="uppercase">
            Bot Name
          </FormLabel>

          <InputGroup>
            <InputLeftElement
              color={customTheme.colors.lightGreyScale1100}
              m="4px 0 0 5px"
            >
              <Tooltip
                label={tooltipText.botName}
                placement={'top'}
                padding={5}
              >
                <Box>
                  <MdOutlineAccountCircle size="24px" />
                </Box>
              </Tooltip>
            </InputLeftElement>
            <Input
              placeholder="Jarvis"
              variant="outline"
              value={formElements.botName}
              onChange={handleFormElementsChange('botName')}
              minW="300px"
              size="lg"
              color={customTheme.colors.lightGreyScale1200}
              border="1px solid"
              borderColor={customTheme.colors.lightGreyScale800}
              autoFocus={!bot.name}
            />
          </InputGroup>
        </FormControl>

        <FormControl mt={4} mb={4}>
          <FormLabel as={Text} textStyle="overline" casing="uppercase">
            Dashboard Label (Optional)
          </FormLabel>

          <InputGroup>
            <InputLeftElement
              color={customTheme.colors.lightGreyScale1100}
              m="4px 0 0 5px"
            >
              <Tooltip
                label={tooltipText.dashboardLabel}
                placement={'top'}
                padding={5}
              >
                <Box>
                  <MdLabelOutline size="24px" />
                </Box>
              </Tooltip>
            </InputLeftElement>
            <Input
              placeholder="Sales Bot"
              variant="outline"
              value={formElements.dashboardLabel}
              onChange={handleFormElementsChange('dashboardLabel')}
              minW="300px"
              size="lg"
              color={customTheme.colors.lightGreyScale1200}
              border="1px solid"
              borderColor={customTheme.colors.lightGreyScale800}
              autoFocus={!bot.name}
            />
          </InputGroup>
        </FormControl>
        <CopyToClipboardButton
          label="Copy Bot ID to Clipboard"
          icon={MdOutlineContentCopy}
          toastTitle="Copied"
          toastDescription={() => bot._id}
          newClipboardValue={bot._id}
        />
      </GridItem>
    </Grid>
  )
}
