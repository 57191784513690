import React, { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { getDefaultStore, RootStore } from '../models/root-store/root.store'
import { IUserAuthInfo } from '../models/user-store'
import { MobXProviderContext, Provider } from 'mobx-react'

export const StoreContext = React.createContext<RootStore>({} as RootStore)

export const useStore = () =>
  (React.useContext(MobXProviderContext) as { store: RootStore }).store

interface StoreProviderProps {
  children?: React.ReactNode
}

export const StoreProvider = ({ children }: StoreProviderProps) => {
  const [defaultStore, setDefaultStore] = React.useState<RootStore>()
  const { user, isLoading, isAuthenticated, getAccessTokenSilently, logout } =
    useAuth0<IUserAuthInfo>()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    (async () => {
      if (isLoading) return

      let accessToken
      if (isAuthenticated) {
        try {
          accessToken = await getAccessTokenSilently()
        } catch (error) {
          logout({ logoutParams: { returnTo: window.location.origin } })
        }
      }

      const store = getDefaultStore(accessToken)
      setDefaultStore(store)

      if (accessToken) {
        store?.session.setToken(accessToken)
        await store?.fetchBaseData()
      }
    })()
  }, [getAccessTokenSilently, isAuthenticated, isLoading, logout, user])

  if (defaultStore) {
    return <Provider store={defaultStore}>{children}</Provider>
  }
  return null
}

export default StoreProvider
