import {
  BotModelType,
  PrivacyPolicyInput,
  UpdateBotInputTypeName,
  useQuery,
} from '@botcopy/utils-shared'

import { useCallback } from 'react'
import { useStore } from 'src/providers'

export const useUpdateBot = () => {
  const store = useStore()

  const {
    data: updateBotData,
    setQuery: updateBotSetQuery,
    query: updateBotQuery,
    loading: updateBotLoading,
    error: updateBotError,
  } = useQuery<{
    updateBot: BotModelType
  }>()

  const updateBotSendData = useCallback(
    ({
      organizationId,
      botMenu,
      botId,
      typename,
      defaultTransitionRouteGroup,
      privacyPolicy,
    }: {
      organizationId: string
      botId: string
      typename: UpdateBotInputTypeName
      botMenu?: string
      defaultTransitionRouteGroup?: string
      privacyPolicy?: PrivacyPolicyInput
    }) => {
      updateBotSetQuery(() =>
        store.api.mutateUpdateBot(
          {
            input: {
              botId,
              organizationId,
              typename,
              botMenu,
              defaultTransitionRouteGroup,
              privacyPolicy,
            },
          },
          (botSelect) =>
            botSelect._id
              .theme((themeSelect) =>
                themeSelect.privacyPolicy(
                  (privacyPolicySelect) =>
                    privacyPolicySelect.bcPrivacyPolicyURL
                      .bcPrivacyPolicyAcceptLabel.bcPrivacyPolicyDeclineLabel
                      .bcPrivacyPolicyDeclineMessage.bcPrivacyPolicyTitle
                      .bcShowPrivacyPolicy,
                ),
              )
              .botMenu((botMenuSelect) => botMenuSelect._id.name),
        ),
      )
    },
    [store.api, updateBotSetQuery],
  )
  return {
    updateBotSendData,
    updateBotData: updateBotData?.updateBot,
    updateBotQuery,
    updateBotSetQuery,
    updateBotLoading,
    updateBotError,
  }
}
