import { find } from 'lodash-es'
import { toJS } from 'mobx'
import { applyPatch, applySnapshot, flow, types } from 'mobx-state-tree'

import {
  cloneBot,
  deleteBot,
  getBots,
  patchBot,
  patchBotCredentials,
  postBots,
} from '../api'
// import v4 from 'uuid'
import generateObjectId from '../utils/generateObjectId'
import Logger from '../utils/logger'

const log = Logger('Bots').log

export enum GreeterStyle {
  CLASSIC = 'classic',
  MODERN = 'modern',
}

export enum PromptGreeterStyle {
  DEFAULT = 'default',
  CLASSIC = 'classic',
  MODERN = 'modern',
}

export enum BotPlatform {
  DIALOGFLOW_ES = 'dialogflowES',
  DIALOGFLOW_CX = 'dialogflowCX',
}

export const BotCSS = types
  .model({
    bcAvatarBackgroundColor: types.string,
    bcBotResponseBorderColor: types.string,
    bcBotResponseFillColor: types.string,
    bcBotResponseFontColor: types.string,
    bcButtonFillColor: types.string,
    bcButtonFontColor: types.string,
    bcCardButtonTextAlignment: types.string,
    bcChatBackground: types.string,
    bcChatroomFillColor: types.string,
    bcChatWindowType: types.string,
    bcDimensionsWindowHeight: types.string,
    bcDimensionsWindowWidth: types.string,
    bcEnableFocusTrap: types.boolean,
    bcFeedbackAddCommentBackground: types.string,
    bcFeedbackAddCommentFontColor: types.string,
    bcFeedbackSubmitBackground: types.string,
    bcFeedbackSubmitFontColor: types.string,
    bcFocusFillColor: types.string,
    bcFontType: types.string,
    bcFontTypeSitePriority: types.boolean,
    bcGreeterBackground: types.string,
    bcGreeterBorder: types.string,
    bcGreeterBorderHoverColor: types.string,
    bcGreeterFontColor: types.string,
    bcGreeterHoverFillColor: types.string,
    bcGreeterHoverFontColor: types.string,
    bcGreeterImageHeight: types.string,
    bcGreeterImageWidth: types.string,
    bcGreeterAnimationName: types.string,
    bcGreeterAnimationSpeed: types.string,
    bcGreeterAnimationTimingFunction: types.string,
    bcGreeterPromptBackground: types.string,
    bcHeaderFillColor: types.string,
    bcHeaderFontColor: types.string,
    bcHeaderType: types.string,
    bcHeaderSubtitle: types.string,
    bcHoverFillColor: types.string,
    bcHoverFontColor: types.string,
    bcInputBarStyle: types.string,
    bcInputBarBorderColor: types.string,
    bcInputBarContainerFillColor: types.string,
    bcInputBarFillColor: types.string,
    bcInputBarFontColor: types.string,
    bcInputBarLabelColor: types.string,
    bcInputBarPlaceholderColor: types.string,
    bcLiveChatEntrySound: types.string,
    bcMenuButtonColor: types.string,
    bcPrivacyPolicyAcceptFontColor: types.string,
    bcPrivacyPolicyAcceptFillColor: types.string,
    bcPrivacyPolicyAcceptBorderColor: types.string,
    bcPrivacyPolicyDeclineFontColor: types.string,
    bcPrivacyPolicyDeclineFillColor: types.string,
    bcPrivacyPolicyDeclineBorderColor: types.string,
    bcPrivacyPolicyDescriptionFontColor: types.string,
    bcPromptCloseButtonColor: types.string,
    bcPromptSuggestionFillColor: types.string,
    bcPromptSuggestionFontColor: types.string,
    bcPromptSuggestionHoverFillColor: types.string,
    bcPromptSuggestionHoverFontColor: types.string,
    bcSendActiveColor: types.string,
    bcShowAvatar: types.boolean,
    bcShowClearConversationMenuItem: types.boolean,
    bcShowDefaultPromptOnPagesWithoutPrompt: types.boolean,
    bcShowMaximizeButton: types.boolean,
    bcShowMenu: types.boolean,
    bcShowMicrophone: types.boolean,
    bcShowMoreOptionsMenu: types.boolean,
    bcShowPoweredByBotcopy: types.boolean,
    bcShowPrivacyPolicyMenuItem: types.boolean,
    bcShowPromptSuggestions: types.boolean,
    bcShowPrompts: types.boolean,
    bcShowGreeter: types.boolean,
    bcSidebarMarginTop: types.string,
    bcSidebarBorderType: types.string,
    bcSidebarBorderImageLinearGradient: types.string,
    bcTypingAnimationDots: types.string,
    bcTypingAnimationFill: types.string,
    bcUiByBotcopyCircleColor: types.string,
    bcUiByBotcopyToggleColor: types.string,
    bcUiByBotcopyImg: types.string,
    bcUserBoxColor: types.string,
    bcUserResponseColor: types.string,
    bcWidgetShape: types.string,
    bcZIndex: types.number,
    v2: types.boolean,
  })
  .actions((self) => ({
    changeVal(name: string, newValue: any) {
      return applyPatch(self, {
        op: 'replace',
        path: `/${name}`,
        value: newValue,
      })
    },
    useDefaultStyle(styleObj: any) {
      self = styleObj
      return log('useDefaultStyles SELF>', self)
    },
  }))

export type IBotCSS = typeof BotCSS.Type

export const BotCSSFeedback = types
  .model({
    bcAskFeedback: types.boolean,
    bcFeedbackTitle: types.string,
    bcFeedbackThankYou: types.string,
    bcShowCommentButton: types.boolean,
  })
  .actions((self) => ({
    changeFeedbackThankYou(feedbackThankYou: string) {
      self.bcFeedbackThankYou = feedbackThankYou
    },
    changeFeedbackTitle(feedbackTitle: string) {
      self.bcFeedbackTitle = feedbackTitle
    },
    setAskFeedback(showFeedback: boolean) {
      self.bcAskFeedback = showFeedback
    },
    setShowCommentButton(showCommentButton: boolean) {
      self.bcShowCommentButton = showCommentButton
    },
  }))

export type IBotCSSFeedback = typeof BotCSSFeedback

export const BotCSSPrivacyPolicy = types
  .model({
    bcShowPrivacyPolicy: types.maybe(types.boolean),
    bcPrivacyPolicyTitle: types.maybe(types.string),
    bcPrivacyPolicyAcceptLabel: types.maybe(types.string),
    bcPrivacyPolicyDeclineLabel: types.maybe(types.string),
    bcPrivacyPolicyDeclineMessage: types.maybe(types.string),
    bcPrivacyPolicyURL: types.maybe(types.string),
  })
  .actions((self) => ({
    changePrivacyPolicyTitle(title: string) {
      self.bcPrivacyPolicyTitle = title
    },
    changePrivacyPolicyAcceptLabel(label: string) {
      self.bcPrivacyPolicyAcceptLabel = label
    },
    changePrivacyPolicyDeclineLabel(label: string) {
      self.bcPrivacyPolicyDeclineLabel = label
    },
    changePrivacyPolicyDeclineMessage(message: string) {
      self.bcPrivacyPolicyDeclineMessage = message
    },
    setShowPrivacyPolicy(show: boolean) {
      self.bcShowPrivacyPolicy = show
    },
    changePrivacyPolicyURL(url: string) {
      self.bcPrivacyPolicyURL = url
    },
  }))

export type IBotCSSPrivacyPolicy = typeof BotCSSPrivacyPolicy

export const BotProfileCustomField = types
  .model({
    _id: types.string,
    bcCustomFieldLabel: types.maybe(types.string),
    bcCustomFieldValue: types.maybe(types.string),
  })
  .actions((self) => ({
    setCustomLabel(label: string) {
      self.bcCustomFieldLabel = label
    },
    setCustomValue(value: string) {
      self.bcCustomFieldValue = value
    },
  }))

export type IBotProfileCustomField = typeof BotProfileCustomField.Type

export const BotCSSBotProfile = types
  .model({
    bcShowBotProfile: types.maybe(types.boolean),
    bcBotProfilePhone: types.maybe(types.string),
    bcBotProfileEmail: types.maybe(types.string),
    bcBotProfileCustomFields: types.optional(
      types.array(BotProfileCustomField),
      [],
    ),
  })
  .actions((self) => ({
    changeBotProfilePhone(phone: string) {
      self.bcBotProfilePhone = phone
    },
    changeBotProfileEmail(email: string) {
      self.bcBotProfileEmail = email
    },
    setShowBotProfile(show: boolean) {
      self.bcShowBotProfile = show
    },
    addCustomField() {
      if (self.bcBotProfileCustomFields.length >= 5) {
        return
      }
      self.bcBotProfileCustomFields.push({
        _id: generateObjectId(12),
        bcCustomFieldLabel: '',
        bcCustomFieldValue: '',
      })
    },
    deleteCustomField(id: number | string) {
      const index = self.bcBotProfileCustomFields.findIndex((e) => e._id === id)
      self.bcBotProfileCustomFields.splice(index, 1)
    },
  }))

export type IBotCSSBotProfile = typeof BotCSSBotProfile

export const BotImages = types
  .model({
    avatar: types.optional(
      types.string,
      'https://via.placeholder.com/300.png/09f/fff',
    ),
    typingAvatar: types.optional(
      types.string,
      'https://via.placeholder.com/300.png/09f/fff',
    ),
    headerImg: types.optional(
      types.string,
      'https://via.placeholder.com/300.png/09f/fff',
    ),
    logo: types.optional(
      types.string,
      'https://via.placeholder.com/300.png/09f/fff',
    ),
  })
  .actions((self) => ({
    changeImage(type: string, url: string) {
      // @ts-ignore
      self[type] = url
    },
  }))

export const BotIntegrations = types
  .model({
    dashbotApiKey: types.maybe(types.string),
    dashbotPlatform: types.maybe(types.string),
    janisApiKey: types.maybe(types.string),
    janisClientKey: types.maybe(types.string),
    ttsApiKey: types.maybe(types.string),
    ttsGender: types.maybe(types.string),
  })
  .actions((self) => ({
    setIntegration(value: string, service: string): boolean {
      let changed = false
      switch (service) {
        case 'dashbotApiKey':
          changed = self.dashbotApiKey !== value
          self.dashbotApiKey = value
          break

        case 'dashbotPlatform':
          changed = self.dashbotPlatform !== value
          self.dashbotPlatform = value
          break

        case 'janisApi':
          changed = self.janisApiKey !== value
          self.janisApiKey = value
          break

        case 'janisClient':
          changed = self.janisClientKey !== value
          self.janisClientKey = value
          break

        case 'ttsApi':
          changed = self.ttsApiKey !== value
          self.ttsApiKey = value
          break

        case 'ttsGender':
          changed = self.ttsGender !== value
          self.ttsGender = value
          break
      }
      return changed
    },
  }))

export const BotTheme = types
  .model({
    images: BotImages,
    css: BotCSS,
    feedback: BotCSSFeedback,
    privacyPolicy: BotCSSPrivacyPolicy,
    botProfile: BotCSSBotProfile,
    defaultGreeterStyle: types.enumeration(Object.values(GreeterStyle)),
  })
  .actions((self) => ({
    updateDefaultGreeterStyle(newDefaultGreeterStyle: GreeterStyle) {
      return applyPatch(self, {
        op: 'replace',
        path: `/defaultGreeterStyle`,
        value: newDefaultGreeterStyle,
      })
    },
  }))

export const BotPromptPresetSuggestion = types.model({
  _id: types.string,
  command: types.string,
  showDesktopSuggestion: types.optional(types.boolean, true),
  showMobileSuggestion: types.optional(types.boolean, true),
  title: types.string,
  type: types.string,
})

export type IBotPromptPresetSuggestion = typeof BotPromptPresetSuggestion.Type

export const BotPromptESOutputContexts = types
  .model({
    _id: types.string,
    name: types.string,
    lifespan: types.number,
  })
  .actions((self) => ({
    setName(name: string) {
      self.name = name
    },
    setLifespan(lifespan: number) {
      self.lifespan = lifespan
    },
  }))

export type IBotPromptESOutputContexts = typeof BotPromptESOutputContexts.Type

export const BotPromptCXSessionParams = types
  .model({
    _id: types.string,
    key: types.string,
    value: types.string,
  })
  .actions((self) => ({
    setKey(key: string) {
      self.key = key
    },
    setValue(value: string) {
      self.value = value
    },
  }))

export type IBotPromptCXSessionParams = typeof BotPromptCXSessionParams.Type

export const BotPromptMetrics = types
  .model({
    timesClicked: types.number,
    timesLoaded: types.number,
  })
  .views((self) => ({
    get getPromptConversionRate() {
      if (!self.timesLoaded) return '0 %'
      return `${Math.round((self.timesClicked / self.timesLoaded) * 100)} %`
    },
    get getPromptConverstionRateDetails() {
      const clicksString = `${self.timesClicked} click${
        self.timesClicked > 1 || !self.timesLoaded ? 's' : ''
      }`
      const loadsString = `${self.timesClicked} load${
        self.timesLoaded > 1 || !self.timesLoaded ? 's' : ''
      }`
      return 'Prompt conversion rate: ' + clicksString + ' / ' + loadsString
    },
  }))

export const ExperimentResultPM = types.model({
  viewCount: types.maybe(types.number),
  engagementCount: types.maybe(types.number),
})

export type IExperimentResultPM = typeof ExperimentResultPM.Type

export const ExperimentCasePM = types.model({
  _id: types.maybe(types.string),
  message: types.string,
  result: types.maybe(ExperimentResultPM),
})

export type ICasePMExperiment = typeof ExperimentCasePM.Type

export const Experiment = types.model({
  casesPM: types.optional(types.array(ExperimentCasePM), []),
  createdAt: types.maybe(types.string),
  type: types.maybe(types.string),
  updatedAt: types.maybe(types.string),
  _id: types.maybe(types.string),
  bot: types.maybe(types.string),
  startedAt: types.maybe(types.string),
  endedAt: types.maybe(types.string),
  results: types.maybe(types.frozen()),
})

export type IExperiment = typeof Experiment.Type

export const BotPrompt = types.model({
  _id: types.string,
  command: types.maybe(types.string),
  esOutputContexts: types.optional(types.array(BotPromptESOutputContexts), []),
  lastExperiment: types.maybe(Experiment),
  cxSessionParams: types.optional(types.array(BotPromptCXSessionParams), []),
  cuiMode: types.boolean,
  default: types.boolean,
  metrics: BotPromptMetrics,
  showDesktop: types.optional(types.boolean, true),
  showMobile: types.optional(types.boolean, true),
  message: types.maybe(types.string),
  suggestions: types.optional(types.array(BotPromptPresetSuggestion), []),
  style: types.maybe(types.enumeration(Object.values(PromptGreeterStyle))),
  subtitle: types.optional(types.string, 'How can I help you?'),
  title: types.optional(types.string, 'Hello'),
  type: types.string,
  url: types.string,
})

export type IBotPrompt = typeof BotPrompt.Type

export const ESAgent = types.model({
  apiVersion: types.maybe(types.string),
  classificationThreshold: types.maybe(types.number),
  defaultLanguageCode: types.maybe(types.string),
  displayName: types.maybe(types.string),
  enableLogging: types.maybe(types.boolean),
  matchMode: types.maybe(types.string),
  parent: types.string,
  tier: types.maybe(types.string),
  timeZone: types.maybe(types.string),
})

export type IESAgent = typeof ESAgent

export const FetchAgentsResult = types.model({
  access: types.boolean,
  agent: ESAgent,
})

export type IFetchAgentsResult = typeof FetchAgentsResult.Type

export const LanguageObject = types.model({
  name: types.string,
  code: types.string,
  default: types.boolean,
})

export type ILanguageObject = typeof LanguageObject.Type

export const CXAgent = types.model({
  defaultLanguageCode: types.string,
  displayName: types.string,
  name: types.string,
  enableStackdriverLogging: types.maybe(types.boolean),
  startFlow: types.maybe(types.string),
  timeZone: types.string,
  speechToTextSettings: types.maybe(types.frozen()),
})

export type ICXAgent = typeof CXAgent.Type

export const LiveChatEndpoint = types.model({
  active: types.maybe(types.boolean),
  webhook: types.maybe(types.string),
  accessToken: types.maybe(types.string),
  headerName: types.maybe(types.string),
  headerValue: types.maybe(types.string),
  triggerContexts: types.optional(types.array(types.string), []),
})

export type ILiveChatEndpoint = typeof LiveChatEndpoint.Type

export const BotMenuHeader = types.model({
  languageCode: types.string,
  main: types.string,
})

export type IBotMenuHeader = typeof BotMenuHeader.Type

export const BotMenu = types.model({
  _id: types.maybe(types.string),
  name: types.maybe(types.string),
  organization: types.maybe(types.string),
  headers: types.maybe(types.array(BotMenuHeader)),
  bots: types.maybe(types.array(types.string)),
  createdAt: types.maybe(types.string),
  updatedAt: types.maybe(types.string),
})

export type IBotMenu = typeof BotMenu.Type

export const LanguageSelection = types
  .model({
    active: types.optional(types.boolean, false),
    languages: types.optional(types.array(LanguageObject), []),
  })
  .actions((self) => ({
    toggle(active: boolean) {
      self.active = active
    },
    clearLanguages() {
      self.languages.clear()
    },
    pushNewLanguage(language: ILanguageObject) {
      self.languages.push(language)
    },
    removeLanguage(language: ILanguageObject) {
      const index = self.languages.findIndex((e) => e.code === language.code)
      if (index > -1) {
        self.languages.splice(index, 1)
      }
    },
    changeDefault(language: ILanguageObject, isDefault: boolean) {
      // find index of language code
      const index = self.languages.findIndex((e) => e.code === language.code)
      // set value to isDefault only if there is more than one item in the array
      // (don't remove a default if there are no other options)
      if (index > -1 && self.languages.length > 1) {
        self.languages[index].default = isDefault
      }
    },
  }))

const Dialogflow = types
  .model('Dialogflow', {
    location: types.string,
    environment: types.optional(types.string, ''),
  })
  .actions((self) => ({
    changeLocation(location: string) {
      self.location = location
    },
    changeEnvironment(environment: string) {
      self.environment = environment
    },
  }))

export const Bot = types
  .model({
    _id: types.identifier,
    active: types.maybe(types.boolean),
    agent: types.maybe(ESAgent),
    botMenu: types.maybe(types.maybeNull(BotMenu)),
    cxAgent: types.maybe(CXAgent),
    countSessions: types.optional(types.number, 0),
    countDailyUsage: types.optional(types.number, 0),
    countEvents: types.optional(types.number, 0),
    createdAt: types.optional(types.string, ''),
    dashboardLabel: types.optional(types.string, ''),
    dialogflow: Dialogflow,
    installedOnRestricted: types.optional(types.boolean, false),
    handoverIntegration: types.optional(LiveChatEndpoint, {}),
    installedOn: types.optional(types.array(types.string), []),
    installedOnExceptions: types.optional(types.array(types.string), []),
    integrations: types.optional(BotIntegrations, {}),
    name: types.optional(types.string, ''),
    organization: types.optional(types.string, ''),
    prompts: types.optional(types.array(BotPrompt), []),
    theme: BotTheme,
    projectId: types.maybe(types.string),
    serviceEmail: types.maybe(types.string),
    newZeroEvents: types.optional(types.number, 0),
    newBot: types.optional(types.boolean, false),
    refKeyName: types.optional(types.string, ''), // do not set a default, will be defaulted in chat-widget
    storeHistory: types.optional(types.boolean, false),
    languageSelection: LanguageSelection,
    platform: types.enumeration(Object.values(BotPlatform)),
  })
  .actions((self) => ({
    patchAgent: flow(function* (agent: any) {
      const response = yield patchBotCredentials(self._id, { agent })
      if (Bot.is(response)) {
        applySnapshot(self, response)
      }
    }),
    patchCXAgent: flow(function* (cxAgent: any) {
      const response = yield patchBotCredentials(self._id, { cxAgent })
      if (Bot.is(response)) {
        applySnapshot(self, response)
      }
    }),

    patchDialogflow: flow(function* () {
      const { dialogflow } = self
      const response = yield patchBot(self._id, { dialogflow })
      if (Bot.is(response)) {
        applySnapshot(self, response)
      }
    }),
    useDefaultStyle: flow(function* (styleObj: any) {
      self.theme.css = styleObj
      const theme = self.theme
      const response = yield patchBot(self._id, { theme })
      const isBot = Bot.is(response)
      if (isBot) {
        applySnapshot(self, response)
      }
    }),
    createPrompt: flow(function* (platform: string) {
      const prompts: any = toJS(self).prompts
      if (platform === BotPlatform.DIALOGFLOW_ES) {
        prompts.push({
          _id: generateObjectId(12),
          command: 'New_Command',
          message: '',
          type: 'event',
          url: '/',
          default: false,
          cuiMode: false,
          style: GreeterStyle.CLASSIC,
        })
      }
      if (platform === BotPlatform.DIALOGFLOW_CX) {
        prompts.push({
          _id: generateObjectId(12),
          command: 'New_Command',
          message: '',
          type: 'training',
          url: '/',
          default: false,
          cuiMode: false,
          style: GreeterStyle.CLASSIC,
        })
      }
      const response = yield patchBot(self._id, { prompts })
      const isBot = Bot.is(response)
      log('createPrompt isBot', isBot)
      if (isBot) {
        applySnapshot(self, response)
      }
    }),
    patchTheme: flow(function* (theme) {
      const response = yield patchBot(self._id, { theme })
      if (Bot.is(response)) {
        applySnapshot(self, response)
      }
    }),
    patchIntegrations: flow(function* (integrations) {
      const response = yield patchBot(self._id, { integrations })
      if (Bot.is(response)) {
        applySnapshot(self, response)
      }
    }),
    patchLanguageSelection: flow(function* () {
      const languageSelection = self.languageSelection
      const response = yield patchBot(self._id, { languageSelection })
      if (Bot.is(response)) {
        applySnapshot(self, response)
      }
    }),
    patchHandoverIntegration: flow(function* (handoverIntegration) {
      const response = yield patchBot(self._id, { handoverIntegration })
      if (Bot.is(response)) {
        applySnapshot(self, response)
      }
    }),
    setProjectId: flow(function* (projectId: string | undefined) {
      const response = yield patchBot(self._id, { projectId })
      const isBot = Bot.is(response)
      if (isBot) {
        applySnapshot(self, response)
      }
    }),
    setServiceEmail: flow(function* (serviceEmail: string) {
      const response = yield patchBot(self._id, { serviceEmail })
      const isBot = Bot.is(response)
      if (isBot) {
        applySnapshot(self, response)
      }
    }),
    deletePrompt: flow(function* (id: string) {
      const index = self.prompts.findIndex((e) => e._id === id)
      self.prompts.splice(index, 1)
      const prompts = self.prompts
      const response = yield patchBot(self._id, { prompts })
      const isBot = Bot.is(response)
      if (isBot) {
        applySnapshot(self, response)
      }
    }),
    patchBot: flow(function* (updates: {
      name?: string
      dashboardLabel?: string
      installedOn?: string[]
      installedOnExceptions?: string[]
      dialogflow?: {
        location?: string
        environment?: string
      }
      installedOnRestricted?: boolean
    }) {
      const response = yield patchBot(self._id, updates)
      const isBot = Bot.is(response)

      if (isBot) {
        applySnapshot(self, response)
      } else {
        console.log('Bot.validate failed')
        console.log(Bot.validate(response, []))
      }
    }),
    changeWebsite: flow(function* (website: string) {
      const response = yield patchBot(self._id, { website })
      const isBot = Bot.is(response)
      if (isBot) {
        applySnapshot(self, response)
      }
    }),
    patchBotPrompts: flow(function* (updates: { refKeyName?: string }) {
      const response = yield patchBot(self._id, updates)
      const isBot = Bot.is(response)
      if (isBot) {
        applySnapshot(self, response)
      }
    }),
    changeRefKeyName: flow(function* (refKeyName: string) {
      const response = yield patchBot(self._id, { refKeyName })
      const isBot = Bot.is(response)
      if (isBot) {
        applySnapshot(self, response)
      }
    }),
    setOldBot: flow(function* () {
      const response = yield patchBot(self._id, { newBot: false })
      const isBot = Bot.is(response)
      if (isBot) {
        applySnapshot(self, response)
      }
    }),
    toggleActive: flow(function* (active: boolean) {
      const response = yield patchBot(self._id, { active })
      const isBot = Bot.is(response)
      if (isBot) {
        applySnapshot(self, response)
      }
    }),
    patchStoreHistory: flow(function* (storeHistory: boolean) {
      const response = yield patchBot(self._id, { storeHistory })
      const isBot = Bot.is(response)
      if (isBot) {
        applySnapshot(self, response)
      }
    }),
    /**
     * disconnect a bot from a platform by clearing the projectId and agent / cxAgent fields
     */
    disconnectBot: flow(function* () {
      if (self.platform === BotPlatform.DIALOGFLOW_ES) {
        const projectId = ''
        const agent = {}
        const response = yield patchBot(self._id, { projectId, agent })
        const isBot = Bot.is(response)
        if (isBot) {
          applySnapshot(self, response)
        }
      }
      if (self.platform === BotPlatform.DIALOGFLOW_CX) {
        const projectId = ''
        const cxAgent = {}
        const response = yield patchBot(self._id, { projectId, cxAgent })
        const isBot = Bot.is(response)
        if (isBot) {
          applySnapshot(self, response)
        }
      }
    }),
  }))
  .views((self) => ({
    get getCurrentBranding() {
      return self.theme.css
    },
    get getPrompts() {
      return self.prompts
    },
  }))

export type IBot = typeof Bot.Type

export const Bots = types
  .model({
    currentBotId: types.maybe(types.string),
    count: types.optional(types.number, 0),
    entities: types.optional(types.array(Bot), []),
  })
  .actions((self) => ({
    setCurrentBotId(id: string) {
      self.currentBotId = id
    },
    pushUnique(item: IBot) {
      const includedBot: IBot | undefined = find(self.entities, {
        _id: item._id,
      })
      if (!includedBot) {
        const isBot = Bot.is(item)
        if (!isBot) {
          log('pushUnique isBot=false', item)
          return
        }
        self.entities.push(Bot.create(item))
      } else {
        const rawItem = toJS(item)
        // replace existing bot
        Object.keys(rawItem).forEach((key: string) => {
          // @ts-ignore
          if (rawItem[key] !== undefined) {
            // @ts-ignore
            includedBot[key] = rawItem[key]
          }
        })
      }
    },
    clearBots() {
      self.entities.clear()
      self.count = 0
    },
  }))
  .views((self) => ({
    get currentBot(): IBot | undefined {
      return find(self.entities, { _id: self.currentBotId })
    },
  }))
  .actions((self) => {
    const funcs: any = {
      create: flow(function* (
        name: string,
        platform: string,
        dashboardLabel: string,
        dialogflowDataRegion: string,
      ) {
        const response = yield postBots({
          name,
          platform,
          dashboardLabel,
          dialogflowDataRegion,
        })
        self.pushUnique(response)
      }),
      cloneBot: flow(function* (botId: string) {
        try {
          const response = yield cloneBot(botId)
          self.pushUnique(response)
          return response
        } catch (error) {
          throw error
        }
      }),
      getBotByIdSync(id: string): IBot | undefined {
        return find(self.entities, { _id: id })
      },
      getBotById: flow(function* (id: string) {
        const bot = find(self.entities, { _id: id })
        if (bot) {
          self.pushUnique(bot)
          return bot
        } else {
          yield funcs.fetchBots()
          return find(self.entities, { _id: id })
        }
      }),
      deleteBot: flow(function* (botId) {
        yield deleteBot(botId)
        self.entities.splice(
          self.entities.findIndex((e) => e._id === botId),
          1,
        )
        self.count = self.entities.length
      }),
      fetchBots: flow(function* fetchBots(sort: string = '_id:-1') {
        try {
          const response = yield getBots(sort)
          // add all bots from server response
          for (const item of response.bots) {
            self.pushUnique(item)
          }
          // remove all bots, that are stored locally but not returned from server
          // needed for organization wide deletions:
          // e.g. user 1 deletes a bot
          // user 2 reloads, but we only push() bots coming from the server
          // and frontend doesnt know about the deleted bot (persistent store)
          for (const localBot of self.entities) {
            if (
              !response.bots.find(
                (serverBot: IBot) => serverBot._id === localBot._id,
              )
            ) {
              self.entities.splice(
                self.entities.findIndex((e) => e._id === localBot._id),
                1,
              )
            }
          }
          self.count = response.count
        } catch (error) {
          return error
        }
      }),
    }
    return funcs
  })

export type IBots = typeof Bots.Type
