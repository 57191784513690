import { observer } from 'mobx-react'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Flex, useToast } from '@chakra-ui/react'

import LoadingSpinner from '../../components/LoadingSpinner'
import { getFeedbackRecords } from '../../api/feedback'
import { SAVE_DETECTOR_BAR_HEIGHT } from '../../components/saveDetector'
import { useStore } from 'src/providers'
import { IFeedback } from './interfaces'
import { RouteProps, withRouter } from 'src/utils/withRouter'
import FeedbackForm from './FeedbackForm'
import CreditCardCapture from '../onboarding/CreditCardCapture'
import { SaveDetectorConfirmation, useSaveDetector } from '@botcopy/ui-shared'
import { useUpdateBot } from 'src/hooks/useUpdateBot'
import UserFeedback from './UserFeedback'

const tooltipText = {
  askFeedbackToggle:
    'When enabled, a feedback box will appear after responses marked as the end of a conversation in Dialogflow, or when a context named "botcopy-feedback" is attached to a Dialogflow intent or through a window trigger.',
  customerFeedback:
    'All positive and negative feedback is collected and displayed below with the Dialogflow Session ID and a comment if applicable.',
  sessionId: 'Dialogflow Session ID',
}

interface IFeedbackProps extends RouteProps {
  classes: any
}

const Feedback = ({ classes }: IFeedbackProps) => {
  const store = useStore()
  const { botId } = useParams()
  const [feedbackArray, setFeedbackArray] = useState<IFeedback[]>([])
  const [thumbsUpCount, setThumbsUpCount] = useState(0)
  const [thumbsDownCount, setThumbsDownCount] = useState(0)
  const [showPositiveFeedback, setShowPositiveFeedback] = useState(true)
  const [showNegativeFeedback, setShowNegativeFeedback] = useState(true)
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const toast = useToast()

  const org = store?.organizations.current
  const me = store?.users.me
  const currentBot = store?.bots.currentBot

  const initialValue =
    currentBot && currentBot.theme.feedback
      ? {
          bcAskFeedback: currentBot.theme.feedback.bcAskFeedback,
          bcShowCommentButton: currentBot.theme.feedback.bcShowCommentButton,
          bcFeedbackTitle: currentBot.theme.feedback.bcFeedbackTitle,
          bcFeedbackThankYou: currentBot.theme.feedback.bcFeedbackThankYou,
        }
      : {
          bcAskFeedback: false,
          bcShowCommentButton: false,
          bcFeedbackTitle: '',
          bcFeedbackThankYou: '',
        }

  const [formElements, setFormElements] = useState(initialValue)

  const { updateBotQuery } = useUpdateBot()
  const hasUnsavedChanges = useSaveDetector(initialValue, formElements)

  const handleSave = async () => {
    if (!(org && currentBot)) return
    setIsSaveLoading(true)
    try {
      currentBot.theme.feedback.setAskFeedback(formElements.bcAskFeedback)
      currentBot.theme.feedback.setShowCommentButton(
        formElements.bcShowCommentButton,
      )
      currentBot.theme.feedback.changeFeedbackTitle(
        formElements.bcFeedbackTitle,
      )
      currentBot.theme.feedback.changeFeedbackThankYou(
        formElements.bcFeedbackThankYou,
      )

      await currentBot.patchTheme(currentBot.theme)

      _handleToast('success', 'Your changes have been saved.')
    } catch (error) {
      _handleToast(
        'error',
        'There was an error saving your changes. Please try again.',
      )
    } finally {
      setIsSaveLoading(false)
    }
  }

  const _handleToast = useCallback(
    (status: 'success' | 'error', message: string) => {
      toast({
        title: status === 'success' ? 'Success' : 'Error',
        description: message,
        status,
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      })
    },
    [toast],
  )

  const _toggleAskFeedback = useCallback(async () => {
    if (currentBot && currentBot.theme.feedback) {
      setFormElements((prev) => ({
        ...prev,
        bcAskFeedback: !prev.bcAskFeedback,
      }))
    }
  }, [currentBot])

  const _toggleCollectComments = useCallback(async () => {
    if (currentBot && currentBot.theme.feedback) {
      setFormElements((prev) => ({
        ...prev,
        bcShowCommentButton: !prev.bcShowCommentButton,
      }))
    }
  }, [currentBot])

  const _sortPositive = useCallback(() => {
    setShowPositiveFeedback((prev) => !prev)
  }, [])

  const _sortNegative = useCallback(() => {
    setShowNegativeFeedback((prev) => !prev)
  }, [])

  const handleTitleChange = useCallback((value: string) => {
    setFormElements((prev) => ({
      ...prev,
      bcFeedbackTitle: value,
    }))
  }, [])

  const handleThankYouChange = useCallback((value: string) => {
    setFormElements((prev) => ({
      ...prev,
      bcFeedbackThankYou: value,
    }))
  }, [])

  useEffect(() => {
    if (updateBotQuery) {
      updateBotQuery.promise.then(
        () => {
          _handleToast('success', 'Your changes have been saved.')
          store?.bots.fetchBots()
        },
        () => {
          _handleToast(
            'error',
            'There was an error saving some of your changes. Please verify again.',
          )
        },
      )
    }
  }, [updateBotQuery, _handleToast, store?.bots])

  useEffect(() => {
    if (!currentBot?.theme?.feedback) return

    setFormElements({
      bcAskFeedback: currentBot.theme.feedback.bcAskFeedback,
      bcShowCommentButton: currentBot.theme.feedback.bcShowCommentButton,
      bcFeedbackTitle: currentBot.theme.feedback.bcFeedbackTitle,
      bcFeedbackThankYou: currentBot.theme.feedback.bcFeedbackThankYou,
    })
  }, [currentBot])

  useEffect(() => {
    const fetchFeedbackRecords = async () => {
      if (botId) {
        const records = await getFeedbackRecords(botId)
        setFeedbackArray(records)
        setThumbsUpCount(
          records.filter((item: IFeedback) => item.feedback).length,
        )
        setThumbsDownCount(
          records.filter((item: IFeedback) => !item.feedback).length,
        )
      }
    }

    fetchFeedbackRecords()
  }, [botId])

  useEffect(() => () => store.bots.setCurrentBotId(undefined), [store.bots])

  if (store?.bots.currentBotId !== botId) {
    store?.bots.setCurrentBotId(botId)
    return null
  }

  if (currentBot?.theme.css && org && me) {
    if (!currentBot.theme.feedback) {
      return null
    }

    return (
      <Flex direction="column" width="100%" justifyContent="center">
        <CreditCardCapture store={store} classes={{}} />
        <Flex direction="column" width="100%">
          <Flex
            className="section-grid"
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Flex justifyContent="center" width="100%">
              <FeedbackForm
                bot={currentBot}
                tooltipText={tooltipText}
                toggleAskFeedback={_toggleAskFeedback}
                toggleCollectComments={_toggleCollectComments}
                formValues={formElements}
                onTitleChange={handleTitleChange}
                onThankYouChange={handleThankYouChange}
              />
            </Flex>
          </Flex>

          <Flex
            className="feedback-main-container"
            direction="column"
            justifyContent="center"
            alignItems="center"
            pb={`${SAVE_DETECTOR_BAR_HEIGHT}px`}
            width="100%"
          >
            <UserFeedback
              feedbackArray={feedbackArray}
              thumbsUpCount={thumbsUpCount}
              thumbsDownCount={thumbsDownCount}
              showPositiveFeedback={showPositiveFeedback}
              showNegativeFeedback={showNegativeFeedback}
              onTogglePositive={_sortPositive}
              onToggleNegative={_sortNegative}
              tooltipText={tooltipText}
            />
          </Flex>
        </Flex>

        <SaveDetectorConfirmation
          hasUnsavedChanges={hasUnsavedChanges}
          isSaveLoading={isSaveLoading}
          onSave={handleSave}
          left={store.session.isNavDrawerOpen ? '350px' : '71px'}
          width={
            store.session.isNavDrawerOpen
              ? 'calc(100% - 350px)'
              : 'calc(100% - 71px)'
          }
        />
      </Flex>
    )
  }
  return <LoadingSpinner />
}

export default withRouter(observer(Feedback))
