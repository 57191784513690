import React from 'react'
import { FormControl, FormLabel, Flex, Button, Tooltip } from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons'

interface BotMenuTitleProps {
  onDeleteButtonClicked: () => void
  loading?: boolean
}

export const BotMenuDeleteButton: React.FC<BotMenuTitleProps> = ({
  onDeleteButtonClicked,
  loading = false,
}) => {
  return (
    <FormControl mt={4}>
      <Flex>
        <FormLabel mr={2}>Delete Menu</FormLabel>
        <Tooltip label="Your bots will be unaffected but the bot menu will no longer display on any linked bots">
          <InfoIcon color="gray.500" />
        </Tooltip>
      </Flex>
      <Button
        isLoading={loading}
        variant="outline"
        onClick={onDeleteButtonClicked}
      >
        Delete Menu
      </Button>
    </FormControl>
  )
}
