import { map } from 'lodash-es'
import { parseISO, format } from 'date-fns'
import * as React from 'react'
import {
  Box,
  Flex,
  Heading,
  Text,
  Card,
  CardBody,
  CardFooter,
  Stack,
  Badge,
  Tooltip,
  Link,
  Tag,
  HStack,
  Button,
  useColorModeValue,
  SimpleGrid,
  Divider,
} from '@chakra-ui/react'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa'
import { IFeedback } from './interfaces'

interface UserFeedbackProps {
  feedbackArray: IFeedback[]
  thumbsUpCount: number
  thumbsDownCount: number
  showPositiveFeedback: boolean
  showNegativeFeedback: boolean
  onTogglePositive: () => void
  onToggleNegative: () => void
  tooltipText: {
    customerFeedback: string
    sessionId: string
  }
}

const UserFeedback: React.FC<UserFeedbackProps> = ({
  feedbackArray,
  thumbsUpCount,
  thumbsDownCount,
  showPositiveFeedback,
  showNegativeFeedback,
  onTogglePositive,
  onToggleNegative,
  tooltipText,
}) => {
  const positiveBg = useColorModeValue('green.50', 'green.900')
  const negativeBg = useColorModeValue('red.50', 'red.900')
  const positiveColor = useColorModeValue('green.500', 'green.200')
  const negativeColor = useColorModeValue('red.500', 'red.200')
  const cardBg = useColorModeValue('white', 'gray.700')
  const borderColor = useColorModeValue('gray.200', 'gray.600')

  // Filter the feedback based on active filters
  const filteredFeedback = feedbackArray.filter(
    (item) =>
      (item.feedback && showPositiveFeedback) ||
      (!item.feedback && showNegativeFeedback),
  )

  return (
    <Box w="100%" mx="auto" maxWidth="container.xl" px={4}>
      <Flex justify="space-between" align="center" mb={4}>
        <HStack spacing={2}>
          <Heading size="md">User Feedback</Heading>
          <Tooltip label={tooltipText.customerFeedback} placement="top">
            <Link
              href="https://docs.botcopy.com/#/basics/components?id=feedback"
              isExternal={true}
              p={0}
            >
              <InfoOutlineIcon />
            </Link>
          </Tooltip>
        </HStack>

        <HStack spacing={2}>
          <Button
            leftIcon={<FaThumbsUp />}
            colorScheme={showPositiveFeedback ? 'green' : 'gray'}
            variant={showPositiveFeedback ? 'solid' : 'outline'}
            onClick={onTogglePositive}
            size="sm"
          >
            {thumbsUpCount}
          </Button>
          <Button
            leftIcon={<FaThumbsDown />}
            colorScheme={showNegativeFeedback ? 'red' : 'gray'}
            variant={showNegativeFeedback ? 'solid' : 'outline'}
            onClick={onToggleNegative}
            size="sm"
          >
            {thumbsDownCount}
          </Button>
        </HStack>
      </Flex>

      {filteredFeedback.length > 0 ? (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
          {map(filteredFeedback, (item: IFeedback, index: number) => {
            const date = parseISO(item.createdAt)
            const isPositive = item.feedback

            return (
              <Card
                key={index}
                borderLeft="4px solid"
                borderLeftColor={isPositive ? positiveColor : negativeColor}
                bg={cardBg}
                boxShadow="sm"
                borderRadius="md"
              >
                <CardBody>
                  <Flex mb={2} align="center">
                    <Box
                      p={1}
                      borderRadius="full"
                      bg={isPositive ? positiveBg : negativeBg}
                      mr={2}
                    >
                      {isPositive ? (
                        <FaThumbsUp color={positiveColor} size="1em" />
                      ) : (
                        <FaThumbsDown color={negativeColor} size="1em" />
                      )}
                    </Box>
                    <Badge colorScheme={isPositive ? 'green' : 'red'}>
                      {isPositive ? 'Positive' : 'Negative'}
                    </Badge>
                  </Flex>

                  <Text fontSize="md" mt={2}>
                    {item.comment}
                  </Text>
                </CardBody>

                <Divider borderColor={borderColor} />

                <CardFooter pt={2} pb={2}>
                  <Stack spacing={2} w="100%">
                    <Flex justify="space-between" align="center">
                      <Text fontSize="xs" color="gray.500">
                        {format(date, 'MMMM d, yyyy h:mm a')}
                      </Text>
                    </Flex>

                    {item.dialogflowSessionId && (
                      <Flex>
                        <Text fontSize="xs" fontWeight="bold" mr={1}>
                          Session ID:
                        </Text>
                        <Text
                          fontSize="xs"
                          fontFamily="mono"
                          isTruncated={true}
                        >
                          {item.dialogflowSessionId}
                        </Text>
                      </Flex>
                    )}

                    {item.feedbackIntentTrigger && (
                      <Tooltip
                        label={`Dialogflow Intent Display Name: ${item.feedbackIntentTrigger}`}
                        placement="top"
                      >
                        <Tag size="sm" colorScheme="blue" w="fit-content">
                          {item.feedbackIntentTrigger.length > 36
                            ? item.feedbackIntentTrigger.substring(0, 33) +
                              '...'
                            : item.feedbackIntentTrigger}
                        </Tag>
                      </Tooltip>
                    )}
                  </Stack>
                </CardFooter>
              </Card>
            )
          })}
        </SimpleGrid>
      ) : (
        <Box textAlign="center" py={10}>
          <Text color="gray.500">No feedback items</Text>
        </Box>
      )}
    </Box>
  )
}

export default UserFeedback
