import React from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  Text,
  IconButton,
} from '@chakra-ui/react'
import { AddIcon, DeleteIcon } from '@chakra-ui/icons'

interface CustomField {
  _id: string
  bcCustomFieldLabel?: string
  bcCustomFieldValue?: string
}

interface CustomFieldsProps {
  customFields: CustomField[]
  handleCustomFieldChange: (
    index: string,
    field: 'label' | 'value',
    value: string,
  ) => void
  addCustomField: () => void
  deleteCustomField: (index: string) => void
  showToast: (type: string, message: string) => void
}

const CustomFields: React.FC<CustomFieldsProps> = ({
  customFields,
  handleCustomFieldChange,
  addCustomField,
  deleteCustomField,
  showToast,
}) => {
  const handleAddCustomField = () => {
    if (customFields.length >= 5) {
      showToast('error', 'Maximum of 5 custom fields allowed')
      return
    }
    addCustomField()
  }

  return (
    <VStack spacing={6} align="stretch" mt={6}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontWeight="bold">Custom Fields</Text>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="blue"
          size="sm"
          onClick={handleAddCustomField}
          isDisabled={customFields.length >= 5}
          variant="secondary"
        >
          Add Field
        </Button>
      </Flex>

      {customFields.map((field) => (
        <HStack key={field._id} spacing={4} alignItems="flex-end">
          <FormControl flex="1">
            <FormLabel mr={1}>Label</FormLabel>
            <Input
              value={field.bcCustomFieldLabel || ''}
              onChange={(e) =>
                handleCustomFieldChange(field._id, 'label', e.target.value)
              }
              placeholder="Label"
            />
          </FormControl>

          <FormControl flex="1">
            <FormLabel mr={1}>Value</FormLabel>
            <Input
              value={field.bcCustomFieldValue || ''}
              onChange={(e) =>
                handleCustomFieldChange(field._id, 'value', e.target.value)
              }
              placeholder="Value"
            />
          </FormControl>

          <IconButton
            icon={<DeleteIcon />}
            aria-label="Delete field"
            colorScheme="red"
            size="sm"
            variant="secondary"
            onClick={() => deleteCustomField(field._id)}
          />
        </HStack>
      ))}

      {customFields.length === 0 && (
        <Text fontSize="sm" color="gray.500" textAlign="center" py={4}>
          No custom fields. Add one to get started.
        </Text>
      )}
    </VStack>
  )
}

export default CustomFields
