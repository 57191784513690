import { inject, observer } from 'mobx-react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { Box, Spinner, Center } from '@chakra-ui/react'
import { RootStore } from '../../models/root-store/root.store'
import Account from '../account/index'
import BotProfile from '../bot-profile'
import BotPrompts from '../bot-prompts'
import Branding from '../branding/'
import Connect from '../connect'
import Dashboard from '../dashboard'
import Engagements from '../engagements'
import Feedback from '../feedback'
import Integrations from '../integrations/integrations'
import Login from '../login'
import Invite from '../onboarding/invite'
import OnboardingStep1 from '../onboarding/step1'
import AgentOne from '../agent-one'
import NotFound from '../not-found'
import PrivacyPolicy from '../privacy-policy'
import Support from '../support'
import MainNavigation from './main-navigation'
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import { useStore } from '../../providers/StoreProvider'
import { FeatureFlags } from '../../utils/featureFlags'
import { RouteProps, withRouter } from 'src/utils/withRouter'

interface IRouterProps extends WithAuth0Props, RouteProps {
  store?: RootStore
}

interface IRouteProviderState {
  isPersistentDrawerMaximized: boolean
  searchQuery: string
  viewMode: 'grid' | 'table'
}

const AgentOneComponent = observer(() => {
  const store = useStore()
  const { botMenuId } = useParams<{ botMenuId: string }>()
  const [isLoading, setIsLoading] = React.useState(true)

  const org = store?.organizations?.current

  useEffect(() => {
    // Check if organization data is available
    if (org) {
      setIsLoading(false)
    }
  }, [org, setIsLoading])

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    )
  }

  // Only check features after we confirm organization data is loaded
  // Otherwise there will be a race condition where AgentOne will not render
  const hasAgentOneFeature = store?.organizations?.current?.featuresEnabled([
    FeatureFlags.AGENT_ONE,
  ])

  return hasAgentOneFeature ? (
    <AgentOne botMenuId={botMenuId} />
  ) : (
    <Navigate to="/not-found" />
  )
})

@inject('store')
@observer
class RouteProvider extends React.Component<IRouterProps, IRouteProviderState> {
  private unlistenHistory!: () => void

  constructor(props: IRouterProps) {
    super(props)

    this.state = {
      isPersistentDrawerMaximized: true,
      searchQuery: '',
      viewMode: localStorage.getItem('viewMode') === 'table' ? 'table' : 'grid',
    }
  }

  handleSearchChange = (query: string) => {
    this.setState({ searchQuery: query })
  }

  public componentDidMount() {
    if (!this.state.isPersistentDrawerMaximized) {
      this.toggleDrawer()
    }
  }

  componentWillUnmount() {
    // Remove the history listener
    if (this.unlistenHistory) {
      this.unlistenHistory()
    }
  }

  public toggleDrawer = () => {
    const { store } = this.props
    this.setState((prevState) => ({
      isPersistentDrawerMaximized: !prevState.isPersistentDrawerMaximized,
    }))
    // set session nav drawer to newly set state
    store?.session.setNavDrawerOpen(!this.state.isPersistentDrawerMaximized)
  }

  public toggleViewMode = (mode: 'grid' | 'table') => {
    this.setState({ viewMode: mode })
    localStorage.setItem('viewMode', mode)
  }

  public getMainContentMaxWidth = () => {
    if (!this.props.auth0.isAuthenticated) {
      return '100%'
    }
    return this.state.isPersistentDrawerMaximized
      ? 'calc(100vw - 350px)'
      : 'calc(100vw - 72px)'
  }

  public render() {
    const { store } = this.props

    if (!store) {
      return null
    }

    const isAgentOnePage = this.props.location.pathname.includes('/agent-one')

    return (
      <>
        {this.props.auth0.isAuthenticated ? (
          <Box position="relative" top="85px">
            <MainNavigation
              isPersistentDrawerMaximized={
                this.state.isPersistentDrawerMaximized
              }
              toggleDrawer={this.toggleDrawer}
              searchQuery={this.state.searchQuery}
              onSearchChange={this.handleSearchChange}
              toggleViewMode={this.toggleViewMode}
              viewMode={this.state.viewMode}
            />
            <Box
              as="main"
              ml="auto"
              mr="0"
              maxWidth={isAgentOnePage ? '100%' : this.getMainContentMaxWidth()}
              transition={
                isAgentOnePage ? 'none' : 'max-width 0.3s ease-in-out'
              }
            >
              <Routes>
                <Route
                  path="/"
                  element={
                    <Dashboard
                      searchQuery={this.state.searchQuery}
                      viewMode={this.state.viewMode}
                      toggleViewMode={this.toggleViewMode}
                    />
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <Dashboard
                      searchQuery={this.state.searchQuery}
                      viewMode={this.state.viewMode}
                      toggleViewMode={this.toggleViewMode}
                    />
                  }
                />
                <Route path="/agent-one" element={<AgentOneComponent />} />
                <Route
                  path="/bot/:botId/bot-prompts"
                  element={<BotPrompts />}
                />
                <Route
                  path="/agent-one/:botMenuId"
                  element={<AgentOneComponent />}
                />
                <Route path="/bot/:botId/branding" element={<Branding />} />
                <Route path="/bot/:botId/connect" element={<Connect />} />
                <Route
                  path="/bot/:botId/integrations"
                  element={<Integrations />}
                />
                <Route
                  path="/bot/:botId/component/feedback"
                  element={<Feedback />}
                />
                <Route
                  path="/bot/:botId/component/profile"
                  element={<BotProfile />}
                />
                <Route
                  path="/bot/:botId/component/privacypolicy"
                  element={<PrivacyPolicy />}
                />
                <Route path="/support" element={<Support classes={{}} />} />
                <Route path="/account" element={<Account classes={{}} />} />
                <Route
                  path="/engagements"
                  element={<Engagements classes={{}} />}
                />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Box>
          </Box>
        ) : (
          <Routes>
            <Route
              path="/signup/step1"
              element={<OnboardingStep1 classes={{}} />}
            />
            <Route path="/signup/invite" element={<Invite classes={{}} />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to={'/login'} />} />
          </Routes>
        )}
      </>
    )
  }
}

export default withRouter(withAuth0(RouteProvider))
